import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, TextInput, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, PillMenu, ImagesUploadInput, AddNewAssetButtons, AttachmentUploadInput } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, getColorByID, getOptionsByLabelGroup, getPropertiesByGroup, truncate, now, date_DMJYYYY, sort_by, getUnreadNotificationsCount, processImagesInput, uploadImages, canIhazIcon, processAttachmentInput, uploadAttachment } from '../helpers';
import RegExHelper from '../regex';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtEngineView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );    
    useDeviceContext(tw);
    const {width, height} = useWindowDimensions();
    const formHeight = height - 100;
    const toast = useToast();
    
    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {        
        if ( ! loggedIn ) { navigation.navigate('loading'); }
    },[loggedIn, navigation]);

    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('ENGINES'),
                _ASSET: getLabel('ENGINE'),
                _404_TEXT: getLabel('ENGINES_404_TEXT'),

                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),
                YEAR: getLabel('YEAR'),
                MANUFACTURER: getLabel('MANUFACTURER'),
                PART: getLabel('PART'),
                LOCATION: getLabel('LOCATION'),
                TYPE: getLabel('TYPE'),
                FUEL_TYPE: getLabel('FUEL_TYPE'),
                HORSEPOWER: getLabel('HORSEPOWER'),
                MODEL: getLabel('MODEL'),
                SERIAL: getLabel('SERIAL'),
                SELECTED: getLabel('SELECTED'),
                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                VIEW: getLabel('VIEW'),
                SORT: getLabel('SORT'),
                ALL: getLabel('ALL'),
                SELECT: getLabel('SELECT'),
                REQUIRED: getLabel('REQUIRED'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),

                DETAILS: getLabel('DETAILS'),
                PHOTOS: getLabel('PHOTOS'),
                PHOTO: getLabel('PHOTO'),
                _404_PHOTOS_TEXT: getLabel('PHOTOS_404_TEXT'),

                ATTACHMENTS: getLabel('ATTACHMENTS'),
                ATTACHMENT: getLabel('ATTACHMENT'),
                _404_ATTACHMENTS_TEXT: getLabel('ATTACHMENT_404_TEXT'),
                ATTACHMENT_FILE: getLabel('ATTACHMENT_FILE'),
                ATTACHMENT_TOO_LARGE_BODY: getLabel('ATTACHMENT_TOO_LARGE_BODY'),
                // TYPE: getLabel('TYPE'),
                DESCRIPTION: getLabel('DESCRIPTION'),
            };
        }
    },[]);

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);
    
    const { yachtID } = route.params;
    const [ yacht, setYacht ] = React.useState(0);

    // const [getFilter, setFilter] = React.useState(labels.current?.ALL);
    // const [filterVisible, setFilterVisible] = React.useState(false);
    
    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);
    
    const [addingNew, setAddingNew] = React.useState(false);
    const [updateID, setUpdateID] = React.useState(0);

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // asset specific input parameters
    const [newYear, setNewYear] = React.useState('');
    const [newModel, setNewModel] = React.useState('');
    const [newSerial, setNewSerial] = React.useState('');
    const [newManufacturer, setNewManufacturer] = React.useState('');
    const [newLocation, setNewLocation] = React.useState('');
    const [newPart, setNewPart] = React.useState('');
    const [newEngineType, setNewEngineType] = React.useState('');
    const [newFuelTypeVisible, setNewFuelTypeVisible] = React.useState(true);
    const [newFuelType, setNewFuelType] = React.useState('');
    const [newHorsePowerVisible, setNewHorsePowerVisible ] = React.useState(true);
    const [newHorsePower, setNewHorsePower] = React.useState('');

    const [manufacturerSelectVisible, setManufacturerSelectVisible] = React.useState(false);
    const [locationSelectVisible, setLocationSelectVisible] = React.useState(false);
    const [fuelTypeSelectVisible, setFuelTypeSelectVisible] = React.useState(false);
    const [engineTypeSelectVisible, setEngineTypeSelectVisible] = React.useState(false);

    // input refs for asset specific inputs
    const newYearInput = React.useRef(null);
    const newModelInput = React.useRef(null);
    const newSerialInput = React.useRef(null);
    const newPartInput = React.useRef(null);
    const newHorsePowerInput = React.useRef(null);

    // select menu options
    const manufacturerOptions = getOptionsByLabelGroup('data.engine.manufacturers', true);
    const locationOptions = getOptionsByLabelGroup('types.vessel.locations', true);
    const fuelTypeOptions = getOptionsByLabelGroup('types.engine.fuel.types', true);
    const engineTypeOptions = getOptionsByLabelGroup('types.engine.types', true);
    const engineTypeColor = getPropertiesByGroup('value', 'types.engine.types', 'color_id', false, true);
    const engineTypeUseFuel = getPropertiesByGroup('value', 'types.engine.types', 'use_fuel', false, true);
    const engineTypeUseHorsepower = getPropertiesByGroup('value', 'types.engine.types', 'use_horsepower', false, true);

    // refs and states for photos
    const photoObjectType = React.useRef('obj_engine');
    const newPhotosInput = React.useRef(null);
    const [contentType, setContentType] = React.useState(getLabel('DETAILS'));
    const [photos, setPhotos] = React.useState([]);
    const [viewingPhoto, setViewingPhoto] = React.useState(false);
    const [viewingPhotoObj, setViewingPhotoObj] = React.useState({});
    const [deleteFileModalActive, setDeleteFileModalActive] = React.useState(false);
    const [deleteFileModalAffectID, setDeleteFileModalAffectID] = React.useState('');
    const [isImageUploading, setIsImageLoading] = React.useState(false);
    const iDsContainingFiles = React.useRef([]);
    const prepareAssetPhotos = React.useCallback((asset)=>
    {
        let photoAssets = [];

        if ( asset?.images?.length )
        {
            let images = [...asset.images].sort(sort_by('modified_date', true));
            let imageWidth = (width - 75)/3;
            images.forEach((photo, index) => {
                photoAssets.push(
                    <Pressable
                        key={index}
                        style={tw`h-[${imageWidth}px] w-[${imageWidth}px] md:h-32 md:w-32`}
                        onPress={()=>{
                            // recalculate optimal image display width and height given the max height should be 80vh for md devices and height - 142 for sm devices, and a max width for md devices being 80vw and 100vw for sm devices
                            let displayWidth = width;
                            let displayHeight = height - 142;
                            if ( tw.prefixMatch('md') ) { displayWidth = Math.floor(width * 0.8); displayHeight = Math.floor(height * 0.8); }
                            if ( photo.width > displayWidth ) {
                                photo.height = Math.ceil((displayWidth / photo.width) * photo.height);
                                photo.width = displayWidth;
                            }
                            if ( photo.height > displayHeight ) {
                                photo.width = Math.floor((displayHeight / photo.height) * photo.width);
                                photo.height = displayHeight;
                            }
                            attachmentMimeType.current = '';
                            setViewingPhotoObj(photo);
                            setViewingPhoto(true);
                        }}
                    >
                        <Image
                            source={{ uri: photo.uri }}
                            resizeMode="cover"
                            style={[tw`bg-gray1 w-full h-full border-b border-white`, (index + 1) % 3 === 0 ? tw`border-r-0 md:border-r` : tw`border-r`]}
                        />
                    </Pressable>
                )
            });
        }

        return photoAssets;
    },[width, height, labels, setViewingPhoto, setViewingPhotoObj]);
    let viewPhotoAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingPhoto ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let viewPhotoAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
        },
        to: {
            opacity: viewingPhoto ? 1 : 0,
            pointerEvents: viewingPhoto ? 'auto' : 'none',
        },
        config: viewingPhoto ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    viewPhotoAnimation = tw.prefixMatch('md') ? viewPhotoAnimationDesktop : viewPhotoAnimation;

    // attachment specific inputs
    const [attachmentDesc, setAttachmentDesc] = React.useState('');
    const [attachmentType, setAttachmentType] = React.useState(0);
    const [attachmentTypeSelectVisible, setAttachmentTypeSelectVisible] = React.useState(false);
    const [updateAttachment, setUpdateAttachment] = React.useState(false);
    const [updateAttachmentID, setUpdateAttachmentID] = React.useState(0);
    const [attachments, setAttachments] = React.useState([]);
    const newAttachmentInput = React.useRef(null);
    const attachmentData = React.useRef('');
    const attachmentMimeType = React.useRef('');
    const attachmentFileNameInput = React.useRef(null);
    const newAttachmentDescInput = React.useRef(null);
    const attachmentTypeOptions = getOptionsByLabelGroup('types.attachment.types', true);
    const attachmentTypeIcons = getPropertiesByGroup( 'value', 'types.attachment.types', 'icon', false, true );
    const prepareAssetAttachments = React.useCallback((asset, editable)=>
    {
        let attachmentAssets = [];

        if ( asset?.attachments?.length )
        {
            let attachments = [...asset.attachments].sort(sort_by('modified_date', true));
            attachments.forEach((file, index) => {
                let icon = file.type_id ? attachmentTypeIcons.find(obj => {return obj.value == file.type_id})?.icon : 'miscellaneous';
                let icon_variant = '_white';
                icon = icon + icon_variant;
                icon = canIhazIcon( icon ) ? icon : 'miscellaneous' + icon_variant;
                attachmentAssets.push(
                    <AssetItem
                        key={index}
                        style={[editable === true && {width:'calc(100% - 18px)'}, tw`md:w-76`]}
                        minHeight={73}
                        color='blue'
                        icon={{ uri: require('../svg/'+icon+'.svg') }}
                        title={ file.type_id ? attachmentTypeOptions.find(obj => {return obj.value == file.type_id})?.label : '' }
                        description={ file.description }
                        // description2={ file.mime_type }
                        menu={editable === true && [
                            { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                setUpdateAttachmentID(file.key);
                                setAttachmentDesc(file.description); newAttachmentDescInput.current.value = file.description;
                                setAttachmentType(file.type_id);
                                setUpdateAttachment(true);
                            }},
                            { name:labels.current?.DELETE, color:'red', onPress: ()=>{
                                setDeleteFileModalAffectID(file.key);
                                attachmentMimeType.current = file.mime_type;
                                setDeleteFileModalActive(true);
                            }}
                        ]}
                        onPress={() => {
                            window.open(file.uri, '_blank');
                        }}
                        viewMore={labels.current?.VIEW}
                    />
                )
            });
        }

        return attachmentAssets;
    },[labels, setUpdateAttachmentID, setAttachmentDesc, setAttachmentType, setUpdateAttachment, newAttachmentDescInput, setDeleteFileModalAffectID, setDeleteFileModalActive]);
    let addNewAttachmentPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: updateAttachment ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: updateAttachment ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewAttachmentPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: updateAttachment ? 1 : 0,
            pointerEvents: updateAttachment ? 'auto' : 'none',
        },
        config: updateAttachment ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewAttachmentPaneAnimation = tw.prefixMatch('md') ? addNewAttachmentPaneAnimationDesktop : addNewAttachmentPaneAnimation;

    React.useEffect(()=>
    {
        if ( ! newEngineType ) { return; }

        const useFuel = newEngineType ? ( engineTypeUseFuel.find(obj => {return parseInt(obj.value) === parseInt(newEngineType)})?.use_fuel === 1 ? true : false ) : true;
        const useHorsepower = newEngineType ? ( engineTypeUseHorsepower.find(obj => {return parseInt(obj.value) === parseInt(newEngineType)})?.use_horsepower === 1 ? true : false ) : true;

        setNewFuelTypeVisible( useFuel );
        setNewHorsePowerVisible( useHorsepower );

    },[newEngineType, setNewFuelTypeVisible, setNewHorsePowerVisible]);

    const engineTypeColors = {
        motor: {
            id: Array(1, 3, 4),
            gas: {
                id: 1,
                color: 'greenLight',
                text: 'green',
            },
            diesel: {
                id: 2,
                color: 'orangeLight',
                text: 'orange',
            },
            electric: {
                id: 3,
                color: 'redLight',
                text: 'red',
            },
        },
        generator: {
            id: Array(2),
            gas: {
                id: 1,
                color: 'purpleLight',
                text: 'purple',
            },
            diesel: {
                id: 2,
                color: 'brand_tertiary',
                text: 'brand_primary',
            },
            electric: {
                id: 3,
                color: 'redLight',
                text: 'red',
            },
        }
    };

    const getEngineTypeColor = React.useCallback((type_id, fuel_type_id) => {
        // console.log('EngineTile getEngineTypeColor', type_id + '|' + fuel_type_id);

        let color = 'black';

        if (engineTypeColors.motor.id.includes(type_id)) {
            // 1 = Gas
            if (fuel_type_id == engineTypeColors.motor.gas.id) {
                color = engineTypeColors.motor.gas.color;
            }
            // 2 = Diesel
            if (fuel_type_id == engineTypeColors.motor.diesel.id) {
                color = engineTypeColors.motor.diesel.color;
            }
            // 3 = Electric
            if (fuel_type_id == engineTypeColors.motor.electric.id) {
                color = engineTypeColors.motor.electric.color;
            }
        }

        if (engineTypeColors.generator.id.includes(type_id)) {
            if (fuel_type_id == engineTypeColors.generator.gas.id) {
                color = engineTypeColors.generator.gas.color;
            }
            // 2 = Diesel
            if (fuel_type_id == engineTypeColors.generator.diesel.id) {
                color = engineTypeColors.generator.diesel.color;
            }
            // 3 = Electric
            if (fuel_type_id == engineTypeColors.generator.electric.id) {
                color = engineTypeColors.generator.electric.color;
            }
        }

        return color;
    }, [engineTypeColors]);

    const getEngineTypeTextColor = React.useCallback((type_id, fuel_type_id) => {
        // console.log('EngineTile getEngineTypeTextColor', type_id + '|' + fuel_type_id);

        let color = 'white';

        if (engineTypeColors.motor.id.includes(type_id)) {
            if (fuel_type_id == engineTypeColors.motor.gas.id) {
                color = engineTypeColors.motor.gas.text;
            }
            if (fuel_type_id == engineTypeColors.motor.diesel.id) {
                color = engineTypeColors.motor.diesel.text;
            }
            if (fuel_type_id == engineTypeColors.motor.electric.id) {
                color = engineTypeColors.motor.electric.text;
            }
        }

        if (engineTypeColors.generator.id.includes(type_id)) {
            if (fuel_type_id == engineTypeColors.generator.gas.id) {
                color = engineTypeColors.generator.gas.text;
            }
            if (fuel_type_id == engineTypeColors.generator.diesel.id) {
                color = engineTypeColors.generator.diesel.text;
            }
            if (fuel_type_id == engineTypeColors.generator.electric.id) {
                color = engineTypeColors.generator.electric.text;
            }
        }

        return color;
    }, [engineTypeColors]);

    React.useEffect(() => {
        let assets = [];
        
        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            if ( yachtObj.engines )
            {                    
                yachtObj.engines.forEach((asset, index) => {
                    let title = '';
                    title += asset.year || '';
                    if ( asset.manufacturer_id )
                    {
                        title += ' ' + manufacturerOptions.find(obj => {return obj.value == asset.manufacturer_id})?.label;
                    }

                    const color_id = asset.type_id ? engineTypeColor.find(obj => {return obj.value == asset.type_id})?.color_id : 'blue';

                    photos[asset.id] = prepareAssetPhotos(asset);
                    if ( asset.images?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }
                    attachments[asset.id] = prepareAssetAttachments(asset, 0 !== updateID);
                    if ( asset.attachments?.length > 0 && ! iDsContainingFiles.current.includes(asset.id) ) { iDsContainingFiles.current.push(asset.id); }

                    assets.push(
                        <AssetItem
                            key={asset.id}
                            minHeight={124}
                            color={getColorByID(color_id)}
                            label={asset.location_id ? locationOptions.find(obj => {return obj.value == asset.location_id})?.label : ''}
                            labelColor={'white'}
                            title={asset.type_id ? engineTypeOptions.find(obj => {return obj.value == asset.type_id})?.label.replace("/", "/ ") : ''}
                            subtitle={ ( asset.images?.length > 0 || asset.attachments?.length > 0 ) &&
                                <View style={tw`flex-row`}>
                                    { asset.images?.length > 0 &&
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/photos_lightgray.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mt-1 ml-2`}
                                        />
                                    }
                                    { asset.attachments?.length > 0 &&
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 mt-1 ml-2`}
                                        />
                                    }
                                </View>
                            }
                            description={title || ' '}
                            model={asset.model || ' '}
                            serial={asset.serial || ' '}
                            // part={asset.part_no || ' '}
                            menu={[
                                { name:labels.current?.EDIT, color:'green', onPress: ()=>{
                                    setUpdateID(asset.id);
                                    attachments[asset.id] = prepareAssetAttachments(asset, true);
                                    setNewEngineType(asset.type_id);
                                    setNewFuelType(asset.fuel_type_id);
                                    setNewYear(asset.year || ''); newYearInput.current.value = asset.year || '';
                                    setNewManufacturer(asset.manufacturer_id);
                                    setNewLocation(asset.location_id);
                                    setNewModel(asset.model || ''); newModelInput.current.value = asset.model || '';
                                    setNewSerial(asset.serial || ''); newSerialInput.current.value = asset.serial || '';
                                    setNewPart(asset.part_no || ''); newPartInput.current.value = asset.part_no || '';
                                    setNewHorsePower(asset.horsepower || ''); newHorsePowerInput.current.value = asset.horsepower || '';
                                    setAddingNew(true);
                                    // doNewAssetValidation(true);
                                    // setTimeout(()=>{
                                    //     doNewAssetValidation(false);
                                    //     setAddingNew(true);
                                    // },250);
                                }},
                                { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setModalAffectID(asset.id); setModalActive(true) } }
                            ]}
                            onPress={() => {
                                setUpdateID(asset.id);
                                attachments[asset.id] = prepareAssetAttachments(asset, true);
                                setNewEngineType(asset.type_id);
                                setNewFuelType(asset.fuel_type_id);
                                setNewYear(asset.year || ''); newYearInput.current.value = asset.year || '';
                                setNewManufacturer(asset.manufacturer_id);
                                setNewLocation(asset.location_id);
                                setNewModel(asset.model || ''); newModelInput.current.value = asset.model || '';
                                setNewSerial(asset.serial || ''); newSerialInput.current.value = asset.serial || '';
                                setNewPart(asset.part_no || ''); newPartInput.current.value = asset.part_no || '';
                                setNewHorsePower(asset.horsepower || ''); newHorsePowerInput.current.value = asset.horsepower || '';
                                setAddingNew(true);
                            }}
                            viewMore={labels.current?.EDIT}
                        />
                    )
                });
            }
        }

        setAssets(assets);
    },[yachtID, userYachts, setAssets, labels]);

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: addingNew ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: addingNew ? 1 : 0,
            pointerEvents: addingNew ? 'auto' : 'none',
        },
        config: addingNew ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('md') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    return (
        <View label="yachtEngines" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-sm font-semibold shrink-0 w-8 h-8 mr-3 text-center leading-[31px] -mt-[2px]`}>{yacht.initials}</Span>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>
                
                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }
                        
                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />
                        
                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>
                        
                    </Pressable>
                </View>
            </View>
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                { /* getAssets.length > 0 &&
                    <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-33 flex-row md:items-center md:hidden z-10`}>
                        
                        <TextInput
                            style={tw`shrink-1 mr-5 p-1`}
                            styleInput={tw`text-sm`}
                            styleImage={tw`ml-1 my-0.5 mr-2 w-4 h-4`}
                            image={{ uri: require('../svg/search_gray.svg') }}
                            placeholder={ labels.current?.SEARCH }
                            // value={filterText}
                            // onChangeText={(value)=>{setFilterText(value)}}
                        />
    
                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />
                            
                            <Span style={tw`text-sm md:text-base leading-4`}>Sort by: <Span style={tw`text-sm md:text-base font-medium`}>{getLabel('VIEW')}: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>
                            
                        </Pressable>
                        
                    </View>
                */ }

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        <View style={tw`hidden md:flex`}>
                                <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                    <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                    {yacht.name}
                                </Heading>

                                { /* <View style={tw`px-5 md:py-0 md:px-0 flex-row md:items-center z-10`}>

                                    <TextInput
                                        style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                                        image={{ uri: require('../svg/search_gray.svg') }}
                                        placeholder="Search"
                                        // value={filterText}
                                        // onChangeText={(value)=>{setFilterText(value)}}
                                    />

                                    <Pressable
                                        style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                                        onPress={()=>{setFilterVisible(true)}}
                                    >
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/sort.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                                        />

                                        <Span style={tw`text-sm md:text-base leading-4`}>Sort by: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>

                                    </Pressable>

                                </View> */ }

                            </View>
                        </View>

                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }
                        { getAssets.length < 1 &&
                            <View style={tw`justify-center grow items-center`}>
                                
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('../svg/enginePower_lightGray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-32 w-32 md:h-48 md:w-48`}
                                />                            
                                <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ yacht?.engines?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT }</Heading>
                                
                                <View style={tw`fixed bottom-9 md:bottom-18 right-18 pointer-events-none`}>
                                    
                                    <Span style={tw`text-sm md:text-base font-medium font-sans mb-2 mr-6`}>{ labels.current?.CONTENT_ADD }</Span>
                                    
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/arrow_organic.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-9 w-18 left-14 md:left-16`}
                                    />
                                </View>
                            </View>
                        }
                        
    
                        { getAssets.length > 0 &&
                            <View>
                                <Heading hlevel={2} styleHlevel={4} style={tw`hidden md:flex`}>{ labels.current?._ASSETS }</Heading>
                                
                                <View style={tw`md:pl-1 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            </View>
                        }
                        
                    </ScrollView>
                    
                    <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                        setUpdateID(0);
                        setNewAssetMsg('');

                        newYearInput.current.value = '';
                        newModelInput.current.value = '';
                        newSerialInput.current.value = '';
                        newPartInput.current.value = '';
                        newHorsePowerInput.current.value = '';
                        setNewYear('');
                        setNewEngineType('');
                        setNewFuelType('');
                        setNewModel('');
                        setNewSerial('');
                        setNewManufacturer('');
                        setNewLocation('');
                        setNewPart('');
                        setNewHorsePower('');

                        // doNewAssetValidation(true);
                        setTimeout(()=>{
                            // doNewAssetValidation(false);
                            setAddingNew(true);
                        }, 250);
                    }} />
                </View>
            </View>

            <AnimatedView style={addNewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>
                
                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setAddingNew(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?._ASSET }</Heading>
                    </View>
                
                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>
        
                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>

                            { 0 !== updateID &&
                                <PillMenu
                                    style={tw`w-auto mx-5.5 mb-3 md:mb-4 md:-mt-2`}
                                    selected={ contentType }
                                    menu={[
                                        { name:labels.current?.DETAILS, icon:{ uri: require('../svg/notes_white.svg') }, inactiveIcon:{ uri: require('../svg/notes.svg') }, onPress:()=>{ setContentType(labels.current?.DETAILS); } },
                                        { name:labels.current?.PHOTOS, icon:{ uri: require('../svg/photos_white.svg') }, inactiveIcon:{ uri: require('../svg/photos.svg') }, onPress:()=>{ setContentType(labels.current?.PHOTOS); } },
                                        { name:labels.current?.ATTACHMENTS, icon:{ uri: require('../svg/attachment_white.svg') }, inactiveIcon:{ uri: require('../svg/attachment.svg') }, onPress:()=>{ setContentType(labels.current?.ATTACHMENTS); } },
                                    ]}
                                />
                            }

                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }
                                
                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, labels?.current?.DETAILS !== contentType && tw`hidden`]}>

                                    <Pressable
                                        style={tw`mt-2 md:w-48.5/100`}
                                        onPress={()=>{setEngineTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ newEngineType && '' !== newEngineType ? { uri: require('../svg/vesseltype.svg') } : { uri: require('../svg/vesseltype_gray.svg') }}
                                            // defaultValue={newEngineType}
                                            value={newEngineType ? engineTypeOptions.find(obj => {return obj.value == newEngineType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />
    
                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Pressable
                                        style={tw`mt-2 md:w-48.5/100`}
                                        onPress={()=>{setLocationSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.LOCATION +'*' }
                                            image={ newLocation && '' !== newLocation ? { uri: require('../svg/location_blue.svg') } : { uri: require('../svg/location_gray.svg') }}
                                            // defaultValue={newLocation}
                                            value={newLocation ? locationOptions.find(obj => {return obj.value == newLocation}).label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <Pressable
                                        style={[tw`mt-2 md:mt-4 md:w-full`, true !== newFuelTypeVisible ? tw`hidden` : {}]}
                                        onPress={()=>{setFuelTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.FUEL_TYPE }
                                            image={ newFuelType && '' !== newFuelType ? { uri: require('../svg/fuel.svg') } : { uri: require('../svg/fuel_gray.svg') }}
                                            // defaultValue={newFuelType}
                                            value={newFuelType ? fuelTypeOptions.find(obj => {return obj.value == newFuelType}).label : ''}
                                            validateGroup={newAssetValidation}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <TextInput
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.YEAR}
                                        image={ newYear && '' !== newYear ? { uri: require('../svg/year.svg') } : { uri: require('../svg/year_gray.svg') }}
                                        pattern="^(19|20)[0-9]{2}$"
                                        keyboardType="numeric"
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewYear(value)}}
                                        validateOnChange={true}
                                        passedRef={newYearInput}
                                        stateValue={newYear}
                                    />

                                    <Pressable
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        onPress={()=>{setManufacturerSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.MANUFACTURER }
                                            image={ newManufacturer && '' !== newManufacturer ? { uri: require('../svg/manufacturer.svg') } : { uri: require('../svg/manufacturer_gray.svg') }}
                                            // defaultValue={newManufacturer}
                                            value={newManufacturer ? truncate(manufacturerOptions.find(obj => {return obj.value == newManufacturer}).label, 26) : ''}
                                            validateGroup={newAssetValidation}
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>
                                    
                                    <TextInput
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.MODEL }
                                        image={ newModel && '' !== newModelInput.current.value ? { uri: require('../svg/model.svg') } : { uri: require('../svg/model_gray.svg') }}
                                        pattern={RegExHelper.validators.model_no}
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewModel(value)}}
                                        validateOnChange={true}
                                        passedRef={newModelInput}
                                        stateValue={newModel}
                                    />

                                    <TextInput
                                        style={[tw`mt-2 md:mt-4 md:w-48.5/100`, true !== newHorsePowerVisible ? tw`hidden` : {}]}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.HORSEPOWER }
                                        image={ newHorsePower && '' !== newHorsePowerInput.current.value ? { uri: require('../svg/enginePower_blue.svg') } : { uri: require('../svg/enginePower_gray.svg') }}
                                        pattern={'^([0-9]*)$'}
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewHorsePower(value)}}
                                        validateOnChange={true}
                                        passedRef={newHorsePowerInput}
                                        stateValue={newHorsePower}
                                    />
                                    
                                    <TextInput
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.SERIAL }
                                        image={ newSerial && '' !== newSerialInput.current.value ? { uri: require('../svg/serial.svg') } : { uri: require('../svg/serial_gray.svg') }}
                                        pattern={RegExHelper.validators.serial_no}
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewSerial(value)}}
                                        validateOnChange={true}
                                        passedRef={newSerialInput}
                                        stateValue={newSerial}
                                    />
                                    
                                    <TextInput
                                        style={tw`mt-2 md:mt-4 md:w-48.5/100`}
                                        autoCapitalize="off"
                                        placeholder={ labels.current?.PART }
                                        image={ newPart && '' !== newPartInput.current.value ? { uri: require('../svg/part.svg') } : { uri: require('../svg/part_gray.svg') }}
                                        // defaultValue={newPart}
                                        pattern={RegExHelper.validators.part_no}
                                        validateGroup={newAssetValidation}
                                        validatedValue={(value) => {setNewPart(value)}}
                                        validateOnChange={true}
                                        passedRef={newPartInput}
                                        stateValue={newPart}
                                    />
                                    
                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, ( labels?.current?.PHOTOS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, photos[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px]`}
                                    >
                                        { photos[updateID]?.length ? <View style={tw`flex-wrap flex-row justify-start items-start`}>{ photos[updateID] }</View> : <View></View> }
                                        { ! photos[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/photos_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_PHOTOS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <ImagesUploadInput
                                        passedRef={newPhotosInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }

                                            let imagesArray = processImagesInput(e.target.files).then((imagesArray)=>{
                                                if ( 'string' === typeof imagesArray )
                                                {
                                                    toast.show(imagesArray, {type: 'danger', duration:10000});
                                                    return;
                                                }
                                                setIsImageLoading(true);
                                                uploadImages(
                                                    imagesArray,
                                                    photoObjectType.current,
                                                    updateID,
                                                    yachtID,
                                                    toast,
                                                    labels.current?.TOAST_ADD.replace('{object}',labels.current?.PHOTOS),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    function (status) {
                                                        setIsImageLoading(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());
                                                        }
                                                    }
                                                );
                                            })
                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:bottom-5 md:right-10`} hideRefresh={true} hideTooltips={true} isLoading={isImageUploading} addNewLabel={ labels.current?.ADD +' '+ labels.current?.PHOTOS } onAddNew={()=>{
                                        /* trigger click on #image-picker */
                                        newPhotosInput.current.click();
                                    }} />

                                </View>

                                <View style={[tw`md:flex-row md:flex-wrap md:justify-between`, ( labels?.current?.ATTACHMENTS !== contentType || ! updateID ) && tw`hidden`]}>

                                    <ScrollView
                                        style={[{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}, tw`pt-4 rounded bg-white border border-gray4 mt-2`, attachments[updateID]?.length && tw`pl-4`]}
                                        contentContainerStyle={tw`w-full justify-start h-[${height - 290}px] md:h-[272px]`}
                                    >
                                        { attachments[updateID]?.length ? <View style={tw`md:flex-wrap md:flex-row pb-16 md:justify-start md:items-start`}>{ attachments[updateID] }</View> : <View></View> }
                                        { ! attachments[updateID]?.length &&
                                            <View style={tw`justify-center grow items-center mt-4`}>
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/attachment_lightgray.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-28 w-28 md:h-40 md:w-40`}
                                                />
                                                <Heading style={tw`text-gray5 text-center p-5`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ labels.current?._404_ATTACHMENTS_TEXT }</Heading>
                                            </View>
                                        }
                                    </ScrollView>

                                    <AttachmentUploadInput
                                        passedRef={newAttachmentInput}
                                        onChange={(e)=>{
                                            if ( ! e.target.files[0] ) { return; }
                                            // if size is > 30 MB
                                            if ( e.target.files[0].size > 31457280 ) { toast.show(labels.current?.ATTACHMENT_TOO_LARGE_BODY.replace('%size%', '30 MB'), {type: 'danger', duration:10000}); return; }

                                            attachmentFileNameInput.current.value = e.target.files[0].name;
                                            attachmentMimeType.current = e.target.files[0].type;
                                            processAttachmentInput(e.target.files[0]).then((result)=>{
                                                attachmentData.current = result;
                                            }).catch((error)=>{
                                                toast.show(error, {type: 'danger', duration:10000});
                                            });

                                        }}
                                    />

                                    <AddNewAssetButtons style={tw`bottom-10 right-9 md:bottom-5 md:right-10`} hideRefresh={true} hideTooltips={true} addNewLabel={ labels.current?.ADD +' '+ labels.current?.ATTACHMENTS } onAddNew={()=>{
                                        setUpdateAttachmentID(0);

                                        attachmentData.current = '';
                                        if ( attachmentFileNameInput.current?.value ) { attachmentFileNameInput.current.value = ''; }
                                        attachmentMimeType.current = '';
                                        setAttachmentType(0);
                                        setAttachmentDesc('');
                                        if ( newAttachmentDescInput.current?.value ) { newAttachmentDescInput.current.value = ''; }

                                        setUpdateAttachment(true);
                                    }} />

                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />
                                
                            <Button
                                style={tw`m-5`}
                                title={0 !== updateID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={newEngineType
                                    && newLocation ? false : true
                                }
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Added/Updated engine:');
                                    console.log({
                                        vessel_id: yachtID,
                                        manufacturer_id: newManufacturer,
                                        part_no: newPart,
                                        location_id: newLocation,
                                        year: newYear,
                                        model: newModel,
                                        serial: newSerial,
                                        type_id: newEngineType,
                                        fuel_type_id: newFuelType,
                                        horsepower: newHorsePower,
                                        is_active: 1, // not sure what this is
                                    });

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        // only required fields should be:
                                        // name (thus initials)
                                        // note

                                        if ( ! newEngineType
                                            || ! newLocation
                                            || ( newYearInput.current.value && newYearInput.current.value != newYear )
                                            || ( newModelInput.current.value && newModelInput.current.value != newModel )
                                            || ( newSerialInput.current.value && newSerialInput.current.value != newSerial )
                                            || ( newPartInput.current.value && newPartInput.current.value != newPart )
                                            || ( newHorsePowerVisible && newHorsePowerInput.current.value && newHorsePowerInput.current.value != newHorsePower )
                                        )
                                        {
                                            console.log({
                                                newYearInput: newYearInput.current.value,
                                                newYear: newYear,
                                                newModelInput: newModelInput.current.value,
                                                newModel: newModel,
                                                newSerialInput: newSerialInput.current.value,
                                                newSerial: newSerial,
                                                newPartInput: newPartInput.current.value,
                                                newPart: newPart,
                                                newHorsePowerInput: newHorsePowerInput.current.value,
                                                newHorsePower: newHorsePower,
                                            });

                                            let errorMsg = [];

                                            if ( ! newEngineType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.TYPE); }
                                            if ( ! newLocation )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?.LOCATION); }
                                            if ( newYearInput.current.value != newYear )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.YEAR); }
                                            if ( newModelInput.current.value != newModel )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.MODEL); }
                                            if ( newSerialInput.current.value != newSerial )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.SERIAL); }
                                            if ( newPartInput.current.value != newPart )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.PART); }
                                            if ( newHorsePowerVisible && newHorsePowerInput.current.value && newHorsePowerInput.current.value != newHorsePower )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.HORSEPOWER); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            let payload = {
                                                vessel_id: yachtID,
                                                manufacturer_id: newManufacturer,
                                                part_no: newPart,
                                                location_id: newLocation,
                                                year: newYear,
                                                model: newModel,
                                                serial: newSerial,
                                                type_id: newEngineType,
                                                fuel_type_id: newFuelType || 0,
                                                horsepower: newHorsePower,
                                                is_active: 1, // not sure what this is
                                            };
                                            if ( 0 !== updateID ) { payload.id = updateID; }
                                            
                                            fetch(apiUrl, {
                                                method: 'POST',
                                                cache: 'no-cache',
                                                headers: {
                                                    'Content-Type': 'application/json'
                                                },
                                                body: JSON.stringify({
                                                    endpoint: 0 !== updateID ? 'engines/update' : 'engines/add', v: apiVersion,
                                                    device_id: localStorage.getItem('deviceID'),
                                                    client_key: localStorage.getItem('clientKey'),
                                                    payload: payload
                                                }),
                                            })
                                            .then((response) => {
                                                return response.text().then(function(text) {
                                                    const result = JSON.parse(text);
                                                    if ( result.error )
                                                    {
                                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                        setNewAssetMsgType('warning');
                                                        setNewAssetMsg(result.error);
                                                        doNewAssetValidation(false);
                                                    }
                                                    else
                                                    {
                                                        setAddingNew(false);  
                                                        doNewAssetValidation(false);
                                                        toast.show((0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                        
                                                        setTimeout(()=>{
                                                            setLastUpdate(now()); // forces app to refetch all account data
                                                        }, 500);

                                                        setTimeout(()=>{
                                                            newYearInput.current.value = '';
                                                            newModelInput.current.value = '';
                                                            newSerialInput.current.value = '';
                                                            newPartInput.current.value = '';
                                                            newHorsePowerInput.current.value = '';
                                                            setNewYear('');
                                                            setNewEngineType('');
                                                            setNewFuelType('');
                                                            setNewModel('');
                                                            setNewSerial('');
                                                            setNewManufacturer('');
                                                            setNewLocation('');
                                                            setNewPart('');
                                                            setNewHorsePower('');
                                                        }, 1500);
                                                    }
                                                });
                                            })
                                            .catch(error => {
                                                console.warn(error);
                                                setNewAssetMsgType('warning');
                                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                else { setNewAssetMsg('An error ocurred. See console log.');}
                                                doNewAssetValidation(false);
                                            });
                                        }
                                    }, 250);
                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={viewPhotoAnimation}>

                <Pressable style={tw`hidden md:flex w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingPhoto(false)}}
                ></Pressable>

                <View style={tw`w-full md:w-[${viewingPhotoObj.width}px] md:max-w-[80vw] md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:pl-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingPhoto(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET +' '+ labels.current?.PHOTOS }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center`}>
                                <Heading style={tw`leading-4 mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={4}>{ viewingPhotoObj.description }</Heading>
                            </View>

                            <View style={tw`self-center`}>
                                <View style={tw`flex-row items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/date.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-4 w-4 mr-2`}
                                    />
                                    <Span style={tw`text-gray6 text-sm leading-6 text-right`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>

                                    { 0 !== updateID && true === addingNew &&
                                        <Pressable
                                            style={(state) => [tw`p-2 rounded bg-gray2 ml-5`, state.hovered && tw`bg-red`]}
                                            onPress={() => {
                                                setDeleteFileModalAffectID(viewingPhotoObj.key);
                                                setDeleteFileModalActive(true);
                                            }}
                                        >
                                            {( state ) => (
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={ state.hovered ? { uri: require('../svg/delete_white.svg') } : { uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            )}
                                        </Pressable>
                                    }
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-0 md:pb-0 md:pt-0 md:pl-0 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100vh - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <View style={tw`w-full`}>
                                    <Span style={tw`font-bold`}>{ viewingPhotoObj.description }</Span>
                                    <View style={tw`flex-row items-center w-full justify-between`}>
                                        <View style={tw`flex-row items-center`}>
                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/date.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-4 w-4 mr-2`}
                                            />
                                            <Span style={tw`text-gray6 text-sm leading-6`}>{ date_DMJYYYY( viewingPhotoObj.modified_date ) }</Span>
                                        </View>

                                        { 0 !== updateID && true === addingNew &&
                                            <Pressable
                                                style={tw`p-2 rounded bg-gray2 ml-5`}
                                                onPress={() => {
                                                    setDeleteFileModalAffectID(viewingPhotoObj.key);
                                                    setDeleteFileModalActive(true);
                                                }}
                                            >
                                                <Image
                                                    accessibilityLabel={labels.current?.DELETE}
                                                    source={{ uri: require('../svg/delete.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-6 w-6`}
                                                />
                                            </Pressable>
                                        }
                                    </View>
                                </View>
                            </View>
                            <HorizontalRule style={tw`md:hidden`} />
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-0`}
                            >
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between h-[${viewingPhotoObj.height}px] max-h-[${height - 142}px] md:max-h-[80vh]`}>
                                    { 'undefined' !== typeof viewingPhotoObj?.uri &&
                                        <Image
                                            source={{ uri: viewingPhotoObj.uri }}
                                            resizeMode="contain"
                                            style={tw`w-full max-w-full h-auto min-h-full md:rounded-b-md`}
                                        />
                                    }
                                </View>
                            </ScrollView>
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <AnimatedView style={addNewAttachmentPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setNewAssetMsg(''); setUpdateAttachment(false)}}
                ></Pressable>

                <View style={tw`md:w-108 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setNewAssetMsg(''); setUpdateAttachment(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 md:mt-0 leading-4 mb-0 text-white md:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ ( 0 !== updateAttachmentID ? labels.current?.EDIT : labels.current?.ADD ) +' '+ labels.current?.ATTACHMENT }</Heading>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-8 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : formHeight}]}>
                        <View style={tw`h-full md:h-max`}>
                            <ScrollView
                                style={{width:'100%',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start px-5 pb-5 md:pb-0`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }

                                <View style={tw`md:flex-row md:flex-wrap md:justify-between`}>
                                    { 0 === updateAttachmentID &&
                                        <Pressable
                                            style={tw`mt-2 w-full`}
                                            onPress={()=>{newAttachmentInput.current.click();}}
                                        >
                                            <TextInput
                                                style={tw`pointer-events-none`}
                                                placeholder={ labels.current?.SELECT +' '+ labels.current?.ATTACHMENT_FILE +'*' }
                                                image={ attachmentData.current ? { uri: require('../svg/upload.svg') } : { uri: require('../svg/upload_gray.svg') }}
                                                // defaultValue={attachmentType}
                                                passedRef={attachmentFileNameInput}
                                                validateGroup={newAssetValidation}
                                                required
                                            />

                                            <Image
                                                accessibilityHidden={true}
                                                source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                                resizeMode="contain"
                                                style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                            />
                                        </Pressable>
                                    }

                                    <Pressable
                                        style={tw`mt-2 w-full`}
                                        onPress={()=>{setAttachmentTypeSelectVisible(true)}}
                                    >
                                        <TextInput
                                            style={tw`pointer-events-none`}
                                            placeholder={ labels.current?.TYPE +'*' }
                                            image={ attachmentType ? { uri: require('../svg/attachment.svg') } : { uri: require('../svg/attachment_gray.svg') }}
                                            // defaultValue={attachmentType}
                                            value={attachmentType ? attachmentTypeOptions.find(obj => {return obj.value === attachmentType})?.label : ''}
                                            validateGroup={newAssetValidation}
                                            required
                                        />

                                        <Image
                                            accessibilityHidden={true}
                                            source={{ uri: require('../svg/chevron_down_blue.svg') }}
                                            resizeMode="contain"
                                            style={tw`h-3 w-3 absolute top-1/2 -translate-y-1/2 right-4`}
                                        />
                                    </Pressable>

                                    <Span style={tw`text-sm text-gray6 my-2 w-full`}>{ '* ' + labels.current?.REQUIRED }</Span>

                                    <TextInput
                                        style={tw`mt-2 w-full`}
                                        autoCapitalize="on"
                                        placeholder={ labels.current?.DESCRIPTION }
                                        image={ attachmentDesc ? { uri: require('../svg/description.svg') } : { uri: require('../svg/description_gray.svg') }}
                                        pattern="^.{2,}$"
                                        validatedValue={(value) => {setAttachmentDesc(value)}}
                                        validateOnChange={true}
                                        validateGroup={newAssetValidation}
                                        stateValue={attachmentDesc}
                                        passedRef={newAttachmentDescInput}
                                    />
                                </View>

                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                style={tw`m-5`}
                                title={0 !== updateAttachmentID ? labels.current?.UPDATE : labels.current?.ADD}
                                disabled={0 !== updateAttachmentID && attachmentType ? false : ( attachmentData.current && attachmentType ? false : true )}
                                validateGroup={newAssetValidation}
                                onPress={()=>{

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        // check if fields are valid

                                        if ( 0 === updateAttachmentID && ! attachmentData.current
                                            || ! attachmentType
                                        ){
                                            let errorMsg = [];

                                            if ( 0 === updateAttachmentID && ! attachmentData.current )
                                                { errorMsg.push(labels.current?.REQUEST_VALID +' '+labels.current?.ATTACHMENT_FILE); }
                                            if ( ! attachmentType )
                                                { errorMsg.push(labels.current?.REQUEST +' '+labels.current?._ASSET +' '+labels.current?.TYPE); }

                                            setNewAssetMsgType('warning');
                                            setNewAssetMsg(errorMsg.join('\n'));
                                            doNewAssetValidation(false);
                                        }
                                        else
                                        {
                                            if ( 0 === updateAttachmentID )
                                            {
                                                uploadAttachment(
                                                    attachmentData.current,
                                                    attachmentType,
                                                    attachmentDesc,
                                                    photoObjectType.current,
                                                    updateID,
                                                    yachtID,
                                                    toast,
                                                    0 === updateAttachmentID ? labels.current?.TOAST_ADD.replace('{object}',labels.current?.ATTACHMENT) : labels.current?.TOAST_UPDATE.replace('{object}',labels.current?.ATTACHMENT),
                                                    {apiUrl:apiUrl, apiVersion:apiVersion},
                                                    (status)=>{
                                                        doNewAssetValidation(false);
                                                        if ( 'success' === status )
                                                        {
                                                            setLastUpdate(now());

                                                            setUpdateAttachment(false);
                                                            setTimeout(()=>{
                                                                setUpdateAttachmentID(0);
                                                                attachmentData.current = '';
                                                                if ( attachmentFileNameInput.current?.value ) { attachmentFileNameInput.current.value = ''; }
                                                                attachmentMimeType.current = '';
                                                                setAttachmentType(0);
                                                                setAttachmentDesc('');
                                                                if ( newAttachmentDescInput.current?.value ) { newAttachmentDescInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    }
                                                );
                                            }
                                            else {
                                                let payload = {
                                                    object_type: photoObjectType.current,
                                                    object_id: updateID,
                                                    type_id: attachmentType,
                                                    description: attachmentDesc,
                                                    key: updateAttachmentID,
                                                };

                                                fetch(apiUrl, {
                                                    method: 'POST',
                                                    cache: 'no-cache',
                                                    headers: {
                                                        'Content-Type': 'application/json'
                                                    },
                                                    body: JSON.stringify({
                                                        endpoint: 'attachments/update', v: apiVersion,
                                                        device_id: localStorage.getItem('deviceID'),
                                                        client_key: localStorage.getItem('clientKey'),
                                                        payload: payload
                                                    }),
                                                })
                                                .then((response) => {
                                                    return response.text().then(function(text) {
                                                        const result = JSON.parse(text);
                                                        if ( result.error )
                                                        {
                                                            if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                            setNewAssetMsgType('warning');
                                                            setNewAssetMsg(result.error);
                                                            doNewAssetValidation(false);
                                                        }
                                                        else
                                                        {
                                                            setUpdateAttachment(false);
                                                            toast.show((0 !== updateAttachmentID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?.ATTACHMENT), {type: 'success', duration:2000});

                                                            setTimeout(()=>{
                                                                setLastUpdate(now()); // forces app to refetch all account data
                                                            }, 500);

                                                            setTimeout(()=>{
                                                                doNewAssetValidation(false);
                                                                setUpdateAttachmentID(0);
                                                                attachmentData.current = '';
                                                                if ( attachmentFileNameInput.current?.value ) { attachmentFileNameInput.current.value = ''; }
                                                                attachmentMimeType.current = '';
                                                                setAttachmentType(0);
                                                                setAttachmentDesc('');
                                                                if ( newAttachmentDescInput.current?.value ) { newAttachmentDescInput.current.value = ''; }
                                                            }, 1500);
                                                        }
                                                    });
                                                })
                                                .catch(error => {
                                                    console.warn(error);
                                                    setNewAssetMsgType('warning');
                                                    if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                                    else { setNewAssetMsg('An error ocurred. See console log.'); }
                                                    doNewAssetValidation(false);
                                                });
                                            }
                                        }
                                    }, 250);


                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={attachmentTypeSelectVisible}
                setActive={setAttachmentTypeSelectVisible}
                selectedValue={attachmentType}
                options={attachmentTypeOptions}
                onValueChange={(value) => {setAttachmentType(value)}}
                scrollable={attachmentTypeOptions.length > 8}
                searchable={attachmentTypeOptions.length > 8}
            />
                    
            { /* <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={typeOptions}
                onValueChange={(value) => {setFilter(value)}}
            /> */ }

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.MANUFACTURER }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={manufacturerSelectVisible}
                setActive={setManufacturerSelectVisible}
                selectedValue={newManufacturer}
                options={manufacturerOptions}
                onValueChange={(value) => {setNewManufacturer(value)}}
                scrollable={true}
                searchable={true}
                requireSearch={true}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.LOCATION }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={locationSelectVisible}
                setActive={setLocationSelectVisible}
                selectedValue={newLocation}
                options={locationOptions}
                onValueChange={(value) => {setNewLocation(value)}}
                scrollable={locationOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={engineTypeSelectVisible}
                setActive={setEngineTypeSelectVisible}
                selectedValue={newEngineType}
                options={engineTypeOptions}
                onValueChange={(value) => {setNewEngineType(value)}}
                scrollable={engineTypeOptions.length > 8}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.FUEL_TYPE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={fuelTypeSelectVisible}
                setActive={setFuelTypeSelectVisible}
                selectedValue={newFuelType}
                options={fuelTypeOptions}
                onValueChange={(value) => {setNewFuelType(value)}}
                scrollable={fuelTypeOptions.length > 8}
            />

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'engines/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsgType('warning');
                                        setPrimaryMsg(result.error);
                                        setModalAffectID(0);
                                        setModalActive(false);
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});
                                        
                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>

            <Modal
                active={deleteFileModalActive}
                setActive={setDeleteFileModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setDeleteFileModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');
                            let payload = {
                                object_type: photoObjectType.current,
                                object_id: updateID,
                                vessel_id: yachtID,
                                key: deleteFileModalAffectID,
                            };
                            if ( '' !== attachmentMimeType.current )
                            {
                                payload.mime_type = attachmentMimeType.current;
                            }

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: ('' !== attachmentMimeType.current ? 'attachments/delete' : 'images/delete'), v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        toast.show(result.error, {type: 'danger', duration:10000});
                                    }
                                    else
                                    {
                                        setDeleteFileModalAffectID('');
                                        setDeleteFileModalActive(false);
                                        setViewingPhoto(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels._ASSET+' '+( '' !== attachmentMimeType.current ? labels.ATTACHMENT : labels.PHOTO)), {type: 'success', duration:2000});
                                        attachmentMimeType.current = '';

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
                                else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtEngineView;
