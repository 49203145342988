import React from 'react';
import { View, ScrollView, Pressable, Image, useWindowDimensions } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, PillMenu, Span, CheckList, Button, AssetItem, SelectMenu, Modal, Notification, HorizontalRule, AddNewAssetButtons } from '../elements';
import { useSpring, animated } from 'react-spring';
import { goPrevScreen, getLabel, now, date_DMJYYYY_TIME, getUnreadNotificationsCount, sort_by, getRouteFilter, updateRouteFilter } from '../helpers';
import { GlobalContext } from '../global';
import { useToast } from 'react-native-toast-notifications';

function YachtChecklistsView ({route, navigation})
{
    const AnimatedView = animated(View);
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    const toast = useToast();

    let {
        apiUrl,
        apiVersion,
        setLastUpdate,
        loggedIn, setLoggedIn,
        userYachts,
        userNotifications,
        enabledFeatures
    } = React.useContext(GlobalContext);

    React.useEffect(()=>
    {
        if ( ! loggedIn ) { navigation.navigate('loading'); }
        if ( ! enabledFeatures.checklists ) { navigation.navigate('yachts'); }
    },[loggedIn, navigation, enabledFeatures]);

    const { yachtID } = route.params;

    const [getFilter, setFilter] = React.useState(null);
    const [filterVisible, setFilterVisible] = React.useState(false);

    const labels = React.useRef(null);
    // const labels = React.useMemo(() => React.useRef(null), []); ????
    React.useEffect(()=>
    {
        if ( ! labels.current )
        {
            labels.current = {
                _ASSETS: getLabel('CHECKLISTS'),
                _ASSET: getLabel('CHECKLIST'),
                _404_TEXT: getLabel('CHECKLISTS_404_TEXT'),
                _404_TEMPLATES: getLabel('TEMPLATES_404_TEXT'),

                EDIT: getLabel('EDIT'),
                DELETE: getLabel('DELETE'),
                CANCEL: getLabel('CANCEL'),
                NOTIFICATIONS: getLabel('NOTIFICATIONS'),
                CONTENT_ADD: getLabel('CONTENT_ADD'),
                ADD: getLabel('ADD'),
                UPDATE: getLabel('UPDATE'),

                NAME: getLabel('NAME'),
                DESCRIPTION: ( getLabel('CHECKLIST') ) +' '+ getLabel('DESCRIPTION'),
                TEMPLATE: getLabel('TEMPLATE'),
                STEPS: getLabel('STEPS'),
                STEP: getLabel('STEP'),
                STEP_NAME: getLabel('STEP') +' '+ getLabel('NAME'),
                STEP_DESCRIPTION: getLabel('STEP') +' '+ getLabel('DESCRIPTION'),
                COMPLETED: getLabel('COMPLETED'),
                PUBLISHED: getLabel('PUBLISHED'),
                UNPUBLISHED: getLabel('UNPUBLISHED'),
                REMAINING: getLabel('REMAINING'),
                TODO: getLabel('TODO'),
                INCOMPLETE: getLabel('INCOMPLETE'),
                STARTED: getLabel('STARTED'),
                ON: getLabel('ON'),
                OK: getLabel('OK'),
                CHECKLIST_DUPLICATE_TITLE: getLabel('CHECKLIST_DUPLICATE_TITLE'),
                CHECKLIST_DUPLICATE_BODY: getLabel('CHECKLIST_DUPLICATE_BODY'),

                REQUEST: getLabel('REQUEST'),
                REQUEST_VALID: getLabel('REQUEST_VALID'),
                DELETE_CONFIRM_TITLE: getLabel('DELETE_CONFIRM_TITLE'),
                DELETE_CONFIRM_BODY: getLabel('DELETE_CONFIRM_BODY'),
                PERMANENT_ACTION: getLabel('PERMANENT_ACTION'),
                VIEW: getLabel('VIEW'),
                SORT: getLabel('SORT'),
                ALL: getLabel('ALL'),
                SELECT: getLabel('SELECT'),
                SELECTED: getLabel('SELECTED'),

                TOAST_UPDATE: getLabel('TOAST_UPDATE'),
                TOAST_ADD: getLabel('TOAST_ADD'),
                TOAST_DELETE: getLabel('TOAST_DELETE'),

                // Labels for Checklist Element:
                // ADD: getLabel('ADD'),
                // STEP: getLabel('STEP'),
                // NAME: getLabel('NAME'),
                ASSIGNED_TO: getLabel('ASSIGNED_TO'),
                ME: getLabel('ME'),
                CHECK: getLabel('CHECK'),
                EXCLUDE: getLabel('EXCLUDE'),
                BY: getLabel('BY'),
                EXCLUDED: getLabel('EXCLUDED'),
            };
        }
        setFilter(getRouteFilter('checklists') || getLabel('ALL'));
    },[]);

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;

    const [notificationCount, setNotificationCount] = React.useState(0);
    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);

    const [ yacht, setYacht ] = React.useState(0);

    const [primaryMsg, setPrimaryMsg] = React.useState('');
    const [primaryMsgType, setPrimaryMsgType] = React.useState('warning');
    const [modalActive, setModalActive] = React.useState(false);
    const [modalAffectID, setModalAffectID] = React.useState(0);

    const [duplicateModalActive, setDuplicateModalActive] = React.useState(false);

    const [updateID, setUpdateID] = React.useState(0);

    const [viewingAsset, setViewingAsset] = React.useState(false);
    const [viewingAssetObj, setViewingAssetObj] = React.useState({});

    const [newAssetMsg, setNewAssetMsg] = React.useState('');
    const [newAssetMsgType, setNewAssetMsgType] = React.useState('warning');
    const [newAssetValidation, doNewAssetValidation] = React.useState(false);
    const [getAssets, setAssets] = React.useState([]);

    // asset specific input parameters
    const [newChecklistTemplate, setNewChecklistTemplate] = React.useState(0);

    const prepareStepsForAPI = React.useCallback((steps)=>
    {
        // loop through newChecklistSteps and add properties "id" and "key" that equal it's index
        // on mobile the id and key are simply the index of the object in the array, api expects to receive this redundant data
        steps.forEach((step, index) => {
            step.id = index;
            step.key = index;
            step.is_dirty = false;
        });
        return btoa(JSON.stringify(steps));
    }, []);

    // select menus
    const [checklistOptions, setChecklistOptions] = React.useState([]);
    const [templateSelectVisible, setTemplateSelectVisible] = React.useState(false);

    const getTemplateObjByID = React.useCallback((id) => {

        let yachtObj = false;
        userYachts.every((value, index) => {
            if ( parseInt(yachtID) === parseInt(value.id) ) { setYacht(value); yachtObj = value; return false; }
            return true;
        });

        let template = {label:'none found', is_complete:false};

        if ( yachtObj.checklist )
        {
            template = yachtObj.checklist.find(function (el)
            {
                return 1 === el.is_template && id === el.id;
            });
        }

        return template;

    }, [yachtID, userYachts]);

    const hasInProgressChecklist = React.useCallback((name) => {
        let hasInProgress = false;

        if ( yacht?.checklist )
        {
            hasInProgress = yacht.checklist.find(function (el)
            {
                return 0 === el.is_template && name === el.name && ! el.is_complete;
            });
        }

        return hasInProgress;
    }, [yacht]);

    React.useEffect(() => {
        if ( ! yacht ) { return; }
        if ( !yacht?.checklist ) { return; }

        let templates = yacht.checklist.filter(function (el)
        {
            return 1 === el.is_template;
        });

        let templateOptions = [];
        templates.forEach((asset, index) => {
            if ( ! asset.is_published ) { return; }
            templateOptions.push({value:asset.id, label:asset.name, description:asset.description});
        });
        setChecklistOptions(templateOptions);
    }, [yacht]);

    React.useEffect(() => {
        let assets = [];

        if ( yachtID )
        {
            let yachtObj = false;
            userYachts.every((value, index) => {
                if ( parseInt(yachtID) === parseInt(value.id) ) { setYacht(value); yachtObj = value; return false; }
                return true;
            });

            if ( yachtObj.checklist )
            {
                let checklists = [];
                let templates = yachtObj.checklist.filter(function (el)
                {
                    return 1 === el.is_template;
                });


                if ( ! checklistOptions.length )
                {
                    let templateOptions = [];
                    templates.forEach((asset, index) => {
                        // console.log(asset);
                        if ( ! asset.is_published ) { return; }
                        templateOptions.push({value:asset.id, label:asset.name, description:asset.description});
                    });
                    setChecklistOptions(templateOptions);
                }

                switch(getFilter)
                {
                    case labels.current?.INCOMPLETE:
                        updateRouteFilter('checklists', labels.current?.INCOMPLETE);
                        checklists = yachtObj.checklist.filter(function (el)
                        {
                            return ! el.is_complete && 0 === el.is_template;
                        });
                        checklists = [...checklists].sort(sort_by('create_date', true));
                        break;

                    case labels.current?.COMPLETED:
                        updateRouteFilter('checklists', labels.current?.COMPLETED);
                        checklists = yachtObj.checklist.filter(function (el)
                        {
                            return el.is_complete && 0 === el.is_template;
                        });
                        checklists = [...checklists].sort(sort_by('create_date', true));
                        break;

                    case labels.current?.ALL:
                    default:
                        updateRouteFilter('checklists', labels.current?.ALL);
                        checklists = yachtObj.checklist.filter(function (el)
                        {
                            return 0 === el.is_template;
                        });
                        checklists = [...checklists].sort(sort_by('!is_complete, create_date', true));
                        break;
                }

                checklists.forEach((asset, index) => {

                    // if asset.steps is a string that's not JSON, base64 decode it into JSON
                    if ( 'string' === typeof asset.steps && 0 !== asset.steps.length && '{' !== asset.steps[0] )
                    {
                        asset.steps = JSON.parse(atob(asset.steps));
                    }

                    asset.stepsCompletedCount = asset.steps.filter((el)=>{return el.is_complete || el.is_excluded}).length;
                    asset.stepsRemainingCount = asset.steps.length - asset.stepsCompletedCount;

                    if ( asset.stepsRemainingCount < 1 ) { asset.is_complete = true; }

                    asset.menu = [
                        { name:labels.current?.EDIT, color:'green', onPress: ()=>{ setViewingAssetObj(asset); setTimeout(()=>{setViewingAsset(true)},250) } },
                        { name:labels.current?.DELETE, color:'red', onPress: ()=>{ setModalAffectID(asset.id); setModalActive(true) } }
                    ]

                    assets.push(
                        <AssetItem
                            key={asset.id}
                            title={asset.name}
                            description={asset.description}
                            tag={ asset.is_complete ? labels.current?.COMPLETED : labels.current?.INCOMPLETE }
                            tagColor={ asset.is_complete ? 'blue' : 'green' }
                            date={(asset.is_complete ? labels.current?.COMPLETED : labels.current?.STARTED) +' '+ labels.current?.ON +' '+ date_DMJYYYY_TIME(asset.create_date, true)}
                            menu={asset.menu}
                            onPress={() => {
                                /* prepare steps */
                                // console.log(asset);
                                setViewingAssetObj(asset);
                                setTimeout(()=>{setViewingAsset(true)},250);
                            }}
                            viewMore={labels.current?.EDIT}
                        />
                    )
                });
            }
            else {
                navigation.navigate('templates', {yachtID:yachtID});
            }
        }

        setAssets(assets);
    },[getFilter, yachtID, navigation, userYachts, labels, checklistOptions]);

    let viewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: viewingAsset ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let viewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: viewingAsset ? 1 : 0,
            pointerEvents: viewingAsset ? 'auto' : 'none',
        },
        config: viewingAsset ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    viewPaneAnimation = tw.prefixMatch('md') ? viewPaneAnimationDesktop : viewPaneAnimation;

    return (
        <View label="yachtChecklists" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} hideOnMobile={true} yachtID={yachtID} />

            <View style={tw`bg-blue h-20 flex-row md:hidden`}>
                <View style={tw`bg-blue h-20 p-5 pt-6.5 flex-row`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => { goPrevScreen(navigation, 'yacht', {yachtID: yachtID}) }}
                    >
                        <Image
                            accessibilityLabel={ labels.current?.CANCEL }
                            source={{ uri: require('../svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px mr-1`}
                        />
                    </Pressable>
                    <Heading style={tw`mt-1.5 leading-4 mb-0 text-white`} hlevel={1} styleHlevel={4}>
                        { labels.current?._ASSETS }
                    </Heading>
                </View>

                <View style={tw`grow`}></View>

                <View style={tw`w-18 justify-center md:order-2 md:w-full`}>
                    <Pressable
                        onPress={()=>{navigation.navigate('notifications')}}
                        style={tw`p-5 relative md:flex-row md:pl-15 md:py-3 md:my-2`}
                    >
                        { notificationCount > 0 &&
                            <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-18 z-10 pointer-events-none`}>
                                { notificationCount }
                            </Span>
                        }

                        <Image
                            accessibilityLabel={ labels.current?.NOTIFICATIONS }
                            source={{ uri: require('../svg/notifications.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                        />

                        <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ labels.current?.NOTIFICATIONS }</Span>

                    </Pressable>
                </View>
            </View>

            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 grow md:pr-8 md:pl-4 z-20`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>

                { ! tw.prefixMatch('md') && 'object' === typeof yacht &&
                    <View style={tw`px-5 md:py-0 md:px-0 flex-row justify-between md:items-center z-10`}>

                        <View style={tw`w-6/12`}>
                            <PillMenu
                                selected={ 'Checklists' }
                                menuSpacer={0}
                                menu={[
                                    { name: 'Checklists', onPress:()=>{} },
                                    { name: 'Templates', onPress:()=>{ navigation.navigate('templates', {yachtID:yachtID}); } },
                                ]}
                            />
                        </View>

                        <Pressable
                            style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                            onPress={()=>{setFilterVisible(true)}}
                        >
                            <Image
                                accessibilityHidden={true}
                                source={{ uri: require('../svg/sort.svg') }}
                                resizeMode="contain"
                                style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                            />

                            <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>

                        </Pressable>

                    </View>
                }

                <View style={! getAssets.length ? tw`grow` : tw`shrink`}>
                    <ScrollView
                        centerContent={getAssets.length ? false : true}
                        style={{width:'100%',overflowX:'visible'}}
                        contentContainerStyle={tw`w-full min-h-full justify-start`}
                    >
                        { '' !== primaryMsg &&
                            <View style={tw`w-full px-4 md:px-1`}>
                                <Notification
                                    style={tw`my-4`}
                                    type={primaryMsgType}
                                >{primaryMsg}</Notification>
                            </View>
                        }

                        <View style={tw`grow`}>
                            <View style={tw`hidden md:flex`}>
                                <View style={tw`flex-row w-full items-center justify-between lg:justify-start mt-2 md:mt-6 lg:mt-10 mb-0 md:mb-1 lg:mb-5 md:px-1`}>
                                    <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                    <Heading style={tw`leading-4 hidden md:flex`} hlevel={1} styleHlevel={2}>
                                        {yacht.name}
                                    </Heading>

                                    <View style={tw`px-5 md:py-0 md:px-0 flex-row md:items-center z-10`}>

                                        { /* <TextInput
                                            style={tw`shrink-1 mr-5 p-1.5 max-w-60`}
                                            image={{ uri: require('../svg/search_gray.svg') }}
                                            placeholder="Search"
                                            // value={filterText}
                                            // onChangeText={(value)=>{setFilterText(value)}}
                                        /> */ }

                                        { yacht?.checklist?.filter(function (el){return 0 === el.is_template;})?.length > 0 &&

                                            <Pressable
                                                style={tw`border border-gray3 py-1 px-2 md:py-1.5 md:px-3 rounded flex-row md:mr-4 md:min-w-60`}
                                                onPress={()=>{setFilterVisible(true)}}
                                            >
                                                <Image
                                                    accessibilityHidden={true}
                                                    source={{ uri: require('../svg/sort.svg') }}
                                                    resizeMode="contain"
                                                    style={tw`h-4 w-4 top-0.5 md:top-1 mr-2`}
                                                />

                                                <Span style={tw`text-sm md:text-base leading-4`}>{ labels.current?.VIEW }: <Span style={tw`text-sm md:text-base font-medium`}>{getFilter}</Span></Span>

                                            </Pressable>
                                        }

                                    </View>

                                </View>
                            </View>
                            <View style={tw`hidden md:flex w-6/12 mt-2 mb-6 ml-1`}>
                                <PillMenu
                                    selected={ 'Checklists' }
                                    menu={[
                                        { name: 'Checklists', onPress:()=>{} },
                                        { name: 'Templates', onPress:()=>{ navigation.navigate('templates', {yachtID:yachtID}); } },
                                    ]}
                                />
                            </View>

                            { getAssets.length > 0 &&
                                <View style={tw`md:pl-1 pt-4 md:pt-0 md:flex-wrap md:flex-row mb-20 md:justify-start md:items-start`}>
                                    {getAssets}
                                </View>
                            }

                            { getAssets.length < 1 &&
                                <View style={tw`justify-center grow items-center`}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={{ uri: require('../svg/checklist_lightGray.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-32 w-32 md:h-48 md:w-48`}
                                    />
                                    <Heading style={tw`text-gray5 text-center p-5 mb-26`} hlevel={tw.prefixMatch('md') ? 2 : 3}>{ yacht?.checklist?.filter(function (el){return 1 === el.is_template;})?.length < 1 ? labels.current?._404_TEMPLATES : ( yacht?.checklist?.length > 0 ? getLabel('SEARCH_NOTFOUND_TITLE') : labels.current?._404_TEXT ) }</Heading>
                                </View>
                            }
                        </View>

                    </ScrollView>

                    { checklistOptions.length > 0 &&
                        <AddNewAssetButtons addNewLabel={ labels.current?.ADD +' '+ labels.current?._ASSET } onAddNew={()=>{
                            setUpdateID(0);
                            setNewAssetMsg('');

                            setNewChecklistTemplate(0);

                            setTimeout(()=>{
                                // setAddingNew(true);
                                setTemplateSelectVisible(true);
                            }, 250);
                        }} />
                    }
                </View>
            </View>

            <AnimatedView style={viewPaneAnimation}>

                <Pressable style={tw`hidden md:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                    onPress={() => {setViewingAsset(false)}}
                ></Pressable>

                <View style={tw`md:w-188 md:rounded-md md:shadow-black md:shadow-opacity-25 md:shadow-radius-8`}>

                    <View style={tw`bg-blue md:bg-gray0 md:border-b-2 md:border-gray1 md:items-center h-20 p-5 md:px-9 pt-6.5 flex-row md:rounded-t-md`}>
                        <Pressable
                            style={tw`p-2 -ml-2 -mt-2`}
                            onPress={() => {setViewingAsset(false)}}
                        >
                            <Image
                                accessibilityLabel={ labels.current?.CANCEL }
                                source={{ uri: tw.prefixMatch('md') ? require('../svg/back.svg') : require('../svg/back_white.svg') }}
                                resizeMode="contain"
                                style={tw`h-6 w-6 top-px md:top-1 md:mr-4`}
                            />
                        </Pressable>
                        <Heading style={tw`mt-1.5 leading-4 mb-0 text-white md:hidden`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 4}>{ labels.current?._ASSET }</Heading>
                        <View style={tw`justify-between hidden md:flex md:flex-row grow mt-0 mb-0 items-start h-full`}>
                            <View style={tw`self-center flex-row justify-between md:w-full`}>
                                <Heading style={tw`mt-0 mb-0 text-ink`} hlevel={1} styleHlevel={2} numberOfLines={1}>{ viewingAssetObj.name }</Heading>
                                <Span style={tw`font-bold text-2xl shrink-0`} numberOfLines={1}>{ viewingAssetObj.stepsCompletedCount } / { viewingAssetObj.steps?.length }</Span>
                            </View>
                        </View>
                    </View>

                    <View style={tw`bg-blue h-8 z-0 -mb-3 md:hidden`}>
                        <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                    </View>

                    <View style={[tw`bg-gray0 grow md:pr-4 md:pb-5 md:pt-0 md:pl-4 z-20 md:rounded-b-md w-full`, { height: tw.prefixMatch('md') ? 'max-content' : 'calc(100% - 100px)'}]}>
                        <View style={tw`h-full md:h-max`}>
                            <View style={tw`w-full justify-between px-5 mb-4 flex-row md:hidden`}>
                                <Span style={tw`font-bold`} numberOfLines={1}>{ viewingAssetObj.name }</Span>
                                <Span style={tw`font-bold`} numberOfLines={1}>{ viewingAssetObj.stepsCompletedCount } / { viewingAssetObj.steps?.length }</Span>
                            </View>
                            <HorizontalRule style={tw`mb-0 md:hidden`} />
                            <ScrollView
                                style={{width:'100%',maxHeight: tw.prefixMatch('md') ? 'calc(100vh - 216px)' : 'calc(100vh - 234px)',overflowX:tw.prefixMatch('md') ? 'visible' : 'hidden'}}
                                contentContainerStyle={tw`w-full justify-start pt-5 px-5`}
                            >
                                { '' !== newAssetMsg &&
                                    <View style={tw`w-full md:pr-2`}>
                                        <Notification
                                            style={tw`my-2`}
                                            type={newAssetMsgType}
                                        >{newAssetMsg}</Notification>
                                    </View>
                                }
                                <View style={tw`md:flex-row md:flex-wrap md:justify-between md:min-h-[300px]`}>
                                    <View style={tw`w-full px-5`}>
                                        <Heading hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 5} style={tw`mt-0`}>{ labels.current?.DESCRIPTION }</Heading>
                                        <Span>{ viewingAssetObj.description }</Span>
                                        <HorizontalRule style={tw`mt-6 mb-1`}  />
                                        <CheckList
                                            labels={{
                                                ADD: labels.current?.ADD,
                                                STEP: labels.current?.STEP,
                                                NAME: labels.current?.NAME,
                                                ASSIGNED_TO: labels.current?.ASSIGNED_TO,
                                                ME: labels.current?.ME,
                                                CHECK: labels.current?.CHECK,
                                                EXCLUDE: labels.current?.EXCLUDE,
                                                BY: labels.current?.BY,
                                                ON: labels.current?.ON,
                                                COMPLETED: labels.current?.COMPLETED,
                                                EXCLUDED: labels.current?.EXCLUDED,
                                            }}
                                            data={viewingAssetObj.steps || []}
                                            editable={false}
                                            userID={user.id}
                                            onValueChange={(data)=>{viewingAssetObj.steps = data; viewingAssetObj.stepsCompletedCount = data.filter((el)=>{return el.is_complete || el.is_excluded}).length; setViewingAssetObj(viewingAssetObj); /*console.log(viewingAssetObj.stepsCompletedCount); console.log(viewingAssetObj.steps);*/ }}
                                        />
                                    </View>
                                </View>
                            </ScrollView>

                            <HorizontalRule style={tw`md:hidden`} />

                            <Button
                                title={ labels.current?.UPDATE }
                                style={tw`m-5 mb-3`}
                                validateGroup={newAssetValidation}
                                onPress={()=>{
                                    console.log('Updating checklist:');
                                    console.log({
                                        id: viewingAssetObj.id,
                                        vessel_id: viewingAssetObj.vessel_id,
                                        name : viewingAssetObj.name,
                                        description : viewingAssetObj.description,
                                        is_template: viewingAssetObj.is_template,
                                        is_published : viewingAssetObj.is_published,
                                        steps : viewingAssetObj.steps,
                                    });

                                    if ( newAssetValidation ) { return; }

                                    doNewAssetValidation(true);
                                    setNewAssetMsg('');

                                    setTimeout(()=>
                                    {
                                        let is_complete = 0;

                                        let stepsCompletedCount = viewingAssetObj.steps.filter((el)=>{return el.is_complete || el.is_excluded}).length;
                                        let stepsRemainingCount = viewingAssetObj.steps.length - stepsCompletedCount;

                                        if ( stepsRemainingCount < 1 ) { is_complete = 1; }

                                        console.log(viewingAssetObj.steps);
                                        let preparedSteps = prepareStepsForAPI(viewingAssetObj.steps);

                                        let payload = {
                                            id: viewingAssetObj.id,
                                            vessel_id: viewingAssetObj.vessel_id,
                                            name : viewingAssetObj.name,
                                            description : viewingAssetObj.description,
                                            is_template: viewingAssetObj.is_template,
                                            is_published : viewingAssetObj.is_published,
                                            steps : preparedSteps,
                                            display_order: 0,
                                            is_complete: is_complete,
                                            // create_user_id: user.id,
                                            // color_id: null,
                                        };

                                        fetch(apiUrl, {
                                            method: 'POST',
                                            cache: 'no-cache',
                                            headers: {
                                                'Content-Type': 'application/json'
                                            },
                                            body: JSON.stringify({
                                                endpoint: 'checklists/update', v: apiVersion,
                                                client_key: localStorage.getItem('clientKey'),
                                                device_id: localStorage.getItem('deviceID'),
                                                payload: payload
                                            }),
                                        })
                                        .then((response) => {
                                            return response.text().then(function(text) {
                                                const result = JSON.parse(text);
                                                if ( result.error )
                                                {
                                                    if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                                    setNewAssetMsgType('warning');
                                                    setNewAssetMsg(result.error);
                                                    doNewAssetValidation(false);
                                                }
                                                else
                                                {
                                                    setViewingAsset(false);
                                                    doNewAssetValidation(false);
                                                    toast.show((0 !== viewingAssetObj.id ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                                    setTimeout(()=>{
                                                        setLastUpdate(now()); // forces app to refetch all account data
                                                    }, 250);
                                                }
                                            });
                                        })
                                        .catch(error => {
                                            console.warn(error);
                                            setNewAssetMsgType('warning');
                                            if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                            else { setNewAssetMsg('An error ocurred. See console log.'); }
                                            doNewAssetValidation(false);
                                        });
                                    }, 250);
                                }}
                            />
                        </View>
                    </View>
                </View>
            </AnimatedView>

            <SelectMenu
                badge={false}
                title={ labels.current?.VIEW }
                optionSet="radio"
                buttonTitle={ labels.current?.SORT }
                active={filterVisible}
                setActive={setFilterVisible}
                selectedValue={getFilter}
                options={[
                    labels.current?.ALL,
                    labels.current?.INCOMPLETE,
                    labels.current?.COMPLETED,
                ]}
                onValueChange={(value) => {setFilter(value)}}
                scrollable={false}
            />

            <SelectMenu
                badge={false}
                title={ labels.current?.SELECT +' '+ labels.current?.TEMPLATE }
                optionSet="radio"
                buttonTitle={ labels.current?.SELECT }
                active={templateSelectVisible}
                setActive={setTemplateSelectVisible}
                selectedValue={newChecklistTemplate}
                options={checklistOptions}
                onValueChange={(id) => {
                    let template = getTemplateObjByID(id);

                    if ( hasInProgressChecklist( template.name ) )
                    {
                        setDuplicateModalActive(true);
                        return;
                    }

                    // create asset upon select
                    let newSteps = template.steps;
                    if ( 'string' === typeof template.steps && 0 !== template.steps.length && '{' !== template.steps[0] )
                    {
                        newSteps = JSON.parse(atob(template.steps));
                    }

                   /* console.log({
                        vessel_id: yachtID,
                        name : template.name,
                        description : template.description || '',
                        is_template: false,
                        is_excluded: false,
                        is_complete: false,
                        is_published : 1,
                        steps : newSteps,
                    });*/

                    if ( newAssetValidation ) { return; }

                    doNewAssetValidation(true);
                    setNewAssetMsg('');

                    setTimeout(()=>
                    {
                        // check if fields are valid

                        // only required fields should be:
                        // name
                        // steps

                        if ( ! template.name
                            // || ! template.description
                            || ! newSteps
                            || [] === newSteps
                        ){
                            let errorMsg = [];

                            if ( ! template.name )
                                { errorMsg.push(labels.current?.REQUEST +' '+ labels.current?._ASSET +' '+ labels.current?.NAME); }

                            if ( ! newSteps || ! newSteps.length )
                                { errorMsg.push(labels.current?.REQUEST +' '+ labels.current?.STEPS); }

                            setNewAssetMsgType('warning');
                            setNewAssetMsg(errorMsg.join('\n'));
                            doNewAssetValidation(false);
                        }
                        else
                        {
                            // console.log(newSteps);
                            let preparedSteps = prepareStepsForAPI(newSteps);

                            let payload = {
                                vessel_id: yachtID,
                                name : template.name,
                                description : template.description || '',
                                is_template: false,
                                is_published : 1,
                                steps : preparedSteps,
                                display_order: 0,
                                is_complete: false,
                                is_excluded: false,
                                // create_user_id: user.id,
                                // color_id: null,
                            };
                            if ( 0 !== updateID ) { payload.id = updateID; }

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 0 !== updateID ? 'checklists/update' : 'checklists/add', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: payload
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setNewAssetMsgType('warning');
                                        setNewAssetMsg(result.error);
                                        doNewAssetValidation(false);
                                    }
                                    else
                                    {
                                        // setAddingNew(false);
                                        doNewAssetValidation(false);
                                        toast.show((0 !== updateID ? labels.current?.TOAST_UPDATE : labels.current?.TOAST_ADD).replace('{object}',labels.current?._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 500);

                                        setTimeout(()=>{
                                            setNewChecklistTemplate(0);
                                        }, 1500);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setNewAssetMsgType('warning');
                                if ( ! window.navigator.onLine ) { setNewAssetMsg('Internet connection failed. Please try again.'); }
                                else { setNewAssetMsg('An error ocurred. See console log.'); }
                                doNewAssetValidation(false);
                            });
                        }
                    }, 250);
                }}
                scrollable={checklistOptions.length > 8}
                searchable={checklistOptions.length > 16}
            />

            <Modal
                active={duplicateModalActive}
                setActive={setDuplicateModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.CHECKLIST_DUPLICATE_TITLE }</Heading>
                <Span>{ labels.current?.CHECKLIST_DUPLICATE_BODY }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.OK }
                        style={tw`w-full shrink py-2 bg-white border bg-blue border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setDuplicateModalActive(false)}}
                    />
                </View>
            </Modal>

            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ labels.current?.DELETE_CONFIRM_TITLE }</Heading>
                <Span>{ labels.current?.DELETE_CONFIRM_BODY }</Span>
                <Span style={tw`text-red mt-2`}>{ labels.current?.PERMANENT_ACTION }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ labels.current?.CANCEL }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ labels.current?.DELETE }
                        image={{ uri: require('../svg/delete_white.svg') }}
                        style={tw`ml-2 w-full shrink py-2 bg-red border border-red`}
                        styleHover={tw`bg-red`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            setPrimaryMsg('');

                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'checklists/delete', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID'),
                                    payload: {
                                       id: modalAffectID,
                                    }
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error )
                                    {
                                        if ( '401' === result.error ){ setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                                        setPrimaryMsgType('warning');
                                        setPrimaryMsg(result.error);
                                    }
                                    else
                                    {
                                        setModalAffectID(0);
                                        setModalActive(false);
                                        toast.show((labels.current?.TOAST_DELETE).replace('{object}',labels._ASSET), {type: 'success', duration:2000});

                                        setTimeout(()=>{
                                            setLastUpdate(now()); // forces app to refetch all account data
                                        }, 250);
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                                setPrimaryMsgType('warning');
                                if ( ! window.navigator.onLine ) { setPrimaryMsg('Internet connection failed. Please try again.'); }
                                else { setPrimaryMsg('An error ocurred. See console log.'); }
                            });
                        }}
                    />
                </View>
            </Modal>
        </View>
    )
}

export default YachtChecklistsView;
