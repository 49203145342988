import React from 'react';
import { View, ScrollView, useWindowDimensions, ActivityIndicator, Image } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { ToastProvider, Toast } from 'react-native-toast-notifications';
import { create, useDeviceContext } from 'twrnc';
import { fetchAccountData, now, uuidv4, fetchLabels, getLabel, maybeShowChangeLog } from './assets/helpers';
import { Modal, Heading, Span, HorizontalRule, Button } from './assets/elements';
import { GlobalContext } from './assets/global';
import RootClassManager from './assets/utils/RootClassManager';

import LoadingView from './assets/views/index';
import WelcomeView from './assets/views/welcome';
import RegisterView from './assets/views/register';
import VerifyAccountView from './assets/views/verifyAccount';
import LoginCodeView from './assets/views/loginCode';
import MyYachtsView from './assets/views/myYachts';
import MyYachtView from './assets/views/myYacht';
import EditYachtView from './assets/views/editYacht';
import ShareYachtView from './assets/views/yachtShared';
import YachtEnginesView from './assets/views/yachtEngines';
import YachtNotesView from './assets/views/yachtNotes';
import YachtEquipmentView from './assets/views/yachtEquipment';
import YachtInventoryView from './assets/views/yachtInventory';
import YachtLogsView from './assets/views/yachtLogs';
import YachtTasksView from './assets/views/yachtTasks';
import MyTasksView from './assets/views/myTasks';
import YachtChecklistsView from './assets/views/yachtChecklists';
import YachtTemplatesView from './assets/views/yachtTemplates';
import YachtPhotosView from './assets/views/yachtPhotos';
import YachtAttachmentsView from './assets/views/yachtAttachments';
import NotificationsView from './assets/views/notifications';
import AccountView from './assets/views/account';
import EditAccountView from './assets/views/editAccount';
// import RoadmapView from './assets/views/roadmap';

const version = 'v2.2.5';
const apiVersionNum = 2;

const Stack = createStackNavigator();
const tw = create(require(`./tailwind.config.js`));

export const linking = {
    prefixes: ['https://app.yachtwave.com'],
    config: {
        screens: {
            welcome: '/',
            register: 'create-account',
            yachts: 'home',
            yacht: 'home/yacht/:yachtID/',
            editYacht: 'home/yacht/:yachtID/edit/',
            shared: 'home/yacht/:yachtID/shared/',
            engines: 'home/yacht/:yachtID/engines/',
            inventory: 'home/yacht/:yachtID/inventory/',
            logs: 'home/yacht/:yachtID/logs/',
            yachtTasks: 'home/yacht/:yachtID/tasks/',
            notes: 'home/yacht/:yachtID/notes/',
            equipment: '/home/yacht/:yachtID/equipment/',
            checklists: 'home/yacht/:yachtID/checklists/',
            templates: 'home/yacht/:yachtID/checklists/templates/',
            attachments: 'home/yacht/:yachtID/attachments/',
            photos: 'home/yacht/:yachtID/photos/',
            tasks: 'home/tasks/',
            editTask: 'home/task/:taskID/edit/',
            notifications: 'home/notifications/',
            account: 'home/account/',
            editAccount: 'home/account/edit/',
            // roadmap: 'home/roadmap/',
        },
    },
};

const screenOptions = (title, transition) => ({
    title: getLabel(title),
    ...transition
});

const slideFromRightConfig = {
    animation: 'spring',
    config: {
        stiffness: 190,
        damping: 30,
        mass: 1,
        overshootClamping: true,
        restDisplacementThreshold: 0.01,
        restSpeedThreshold: 0.01,
    },
};

const slideFromRight = {
    cardStyleInterpolator: ({ current, layouts }) => ({
        cardStyle: {
            willChange: 'transform',
            maxWidth: '100vw',
            overflow: 'hidden',
            boxShadow: 'rgb(0 0 0 / 25%) 0px 0px 32px',
            transform: [{
                translateX: current.progress.interpolate({
                    inputRange: [0, 1],
                    outputRange: [layouts.screen.width, 0],
                }),
            }],
        },
    }),
    transitionSpec: {
        open: slideFromRightConfig,
        close: slideFromRightConfig,
    },
};

const fadeInConfig = {
    animation: 'spring',
    config: {
        stiffness: 190,
        damping: 10,
        mass: 1,
        overshootClamping: true,
        restDisplacementThreshold: 0.01,
        restSpeedThreshold: 0.01,
    },
};

const fadeIn = {
    cardStyleInterpolator: ({ current }) => ({
        cardStyle: {
            willChange: 'opacity',
            maxWidth: '100vw',
            overflow: 'hidden',
            opacity: current.progress,
        },
    }),
    transitionSpec: {
        open: fadeInConfig,
        close: fadeInConfig,
    },
};

function App() {
    useDeviceContext(tw);
    const { height } = useWindowDimensions();

    const dynamicDeviceTransition = tw.prefixMatch('md') ? fadeIn : slideFromRight;

    const clientKey = localStorage.getItem('clientKey');
    const deviceID = localStorage.getItem('deviceID') || uuidv4();
    if ( ! localStorage.getItem('deviceID') ) { localStorage.setItem('deviceID', deviceID); }
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;
    const yachts = localStorage.getItem('yachts') ? JSON.parse(localStorage.getItem('yachts')) : false;
    const tasks = localStorage.getItem('tasks') ? JSON.parse(localStorage.getItem('tasks')) : false;
    const notifications = localStorage.getItem('notifications') ? JSON.parse(localStorage.getItem('notifications')) : false;

    const [enabledFeatures] = React.useState({ registration: true, sharing: true, checklists: true, photos: true, attachments: true, export: true, import: true });
    const [apiUrl] = React.useState('https://app.yachtwave.com/api.php');
    const [apiVersion] = React.useState(apiVersionNum);
    const [recaptchaKey] = React.useState('6LeUUhMpAAAAAFWW9MQiirqUF0BoCDaR4JhOm0xN');
    const [loggedIn, setLoggedIn] = React.useState(clientKey ? true : false);
    const [shownIntro, setShownIntro] = React.useState(true);
    const [lastUpdate, setLastUpdate] = React.useState(now());
    const [userLang, setUserLang] = React.useState('English');
    const [userLangID, setUserLangID] = React.useState('en_us');
    const [userDarkmode, setUserDarkmode] = React.useState(false);
    const [userID, setUserID] = React.useState(user.id);
    const [userFirstName, setUserFirstName] = React.useState(user ? user.first_name : '');
    const [userLastName, setUserLastName] = React.useState(user ? user.last_name : '');
    const [userEmail, setUserEmail] = React.useState(user ? user.email : '');
    const [userPhonePrefix, setUserPhonePrefix] = React.useState(user ? user.country_code : '1');
    const [userPhone, setUserPhone] = React.useState(user ? user.mobile : '');
    const [userYachts, setUserYachts] = React.useState(yachts ? yachts : []);
    const [userTasks, setUserTasks] = React.useState(tasks ? tasks : []);
    const [userNotifications, setUserNotifications] = React.useState(notifications ? notifications : []);
    const [userNotificationSettings, setUserNotificationSettings] = React.useState({/*receives_notification_sms: user ? user.receives_notification_sms : 0, receives_notification_push: user ? user.receives_notification_push : 0,*/ receives_notification_emails: user ? user.receives_notification_emails : 0});
    const [labels, setLabels] = React.useState(false);
    const [latestChangeLog, setLatestChangeLog] = React.useState([]);
    const [showChangeLog, setShowChangeLog] = React.useState(false);
    const [loadingData, setLoadingData] = React.useState(false);

    const [noticeModalActive, setNoticeModalActive] = React.useState(false);

    const navigationRef = React.useRef(null);
    const [syncToast, setSyncToast] = React.useState(null);

    const GLOBALS = React.useMemo(() => ({
        version,
        labels,
        apiUrl,
        apiVersion,
        recaptchaKey,
        lastUpdate, setLastUpdate,
        shownIntro, setShownIntro,
        loggedIn, setLoggedIn,
        userID, setUserID,
        userDarkmode, setUserDarkmode,
        userLang, setUserLang,
        userLangID, setUserLangID,
        userFirstName, setUserFirstName,
        userLastName, setUserLastName,
        userEmail, setUserEmail,
        userPhonePrefix, setUserPhonePrefix,
        userPhone, setUserPhone,
        userYachts, setUserYachts,
        userTasks, setUserTasks,
        userNotifications, setUserNotifications,
        userNotificationSettings, setUserNotificationSettings,
        enabledFeatures,
        navigationRef,
    }), [
        labels, apiUrl, apiVersion, recaptchaKey, lastUpdate, setLastUpdate,
        loggedIn, setLoggedIn, userID, setUserID, userDarkmode, setUserDarkmode, userLang, setUserLang,
        userLangID, setUserLangID, userFirstName, setUserFirstName, userLastName, setUserLastName,
        userEmail, setUserEmail, userPhonePrefix, setUserPhonePrefix, userPhone, setUserPhone,
        userYachts, setUserYachts, userTasks, setUserTasks, userNotifications, setUserNotifications,
        enabledFeatures, userNotificationSettings, setUserNotificationSettings, navigationRef
    ]);

    React.useEffect(() => {
        if (clientKey) {
            fetchAccountData(clientKey, GLOBALS, setLoadingData, loggedIn);
            if (!loggedIn) setLoggedIn(true);
            if (!showChangeLog) {
                const changelog = maybeShowChangeLog();
                setLatestChangeLog(changelog);
                if (changelog) setShowChangeLog(true);
            }
        }
        if (!labels || !localStorage.getItem('labels_ver') || version !== localStorage.getItem('labels_ver')) {
            fetchLabels(
                (labeljson) => {
                    if (labeljson) {
                        setLabels(true);
                        localStorage.setItem('labels', JSON.stringify(labeljson));
                    }
                },
                version,
                apiUrl,
                apiVersion
            );
        }
    }, [clientKey, lastUpdate, setLabels, setLoadingData]);

    React.useEffect(() => {
        const offset = Toast?.state?.toasts?.length ? 0 : 1000;
        if (loggedIn && loadingData && !syncToast) {
            setSyncToast(true);
            setTimeout(() => {
                const thisSyncToast = Toast.show(getLabel('TOAST_SYNC'), {
                    duration: 86400,
                    type: 'warning',
                    icon: <ActivityIndicator animating={true} color='#FFFFFF' size="small" />,
                });
                setSyncToast(thisSyncToast);
            }, offset);
        } else if ((loggedIn && ! loadingData && typeof syncToast === 'string') || (! loggedIn && typeof syncToast === 'string') ) {
            const hideToast = () => {
                setTimeout(() => {
                    Toast.hide(syncToast);
                    setSyncToast(null);
                }, 1500);
            };

            if (document.hidden) {
                const visibilityChange = () => {
                    if (!document.hidden) {
                        hideToast();
                        document.removeEventListener('visibilitychange', visibilityChange);
                    }
                };
                document.addEventListener('visibilitychange', visibilityChange);
            } else {
                hideToast();
            }
        }
        // console.log({shownIntro: shownIntro});
        setNoticeModalActive(loggedIn && ! shownIntro && '' !== getLabel('WEB_LIMITED_TITLE') );
    }, [loggedIn, syncToast, setSyncToast, loadingData, shownIntro]);

    return (
        <ToastProvider
            successColor={tw.color('green')}
            dangerColor={tw.color('red')}
            warningColor={tw.color('orange')}
            normalColor={tw.color('gray4')}
        >
            <GlobalContext.Provider value={GLOBALS}>
                <NavigationContainer linking={linking} ref={navigationRef}>
                    <RootClassManager />
                    <Stack.Navigator initialRouteName="loading">
                        <Stack.Group screenOptions={{ headerShown: false, animationEnabled: true }}>
                            <Stack.Screen name="loading" component={LoadingView} options={{ title: 'Loading...', animationEnabled: false }} />
                            <Stack.Screen name="welcome" component={WelcomeView} options={{ title: 'Welcome', animationEnabled: false }} />
                            <Stack.Screen name="register" component={RegisterView} options={{ title: 'Create an Account', animationEnabled: false }} />
                            <Stack.Screen name="login" component={LoginCodeView} options={screenOptions('LOGIN_TITLE', slideFromRight)} />
                            <Stack.Screen name="verify" component={VerifyAccountView} options={screenOptions('VERIFY_MOBILE_TITLE', slideFromRight)} />
                            <Stack.Screen name="yachts" component={MyYachtsView} options={screenOptions('YACHTS_TITLE', fadeIn)} />
                            <Stack.Screen name="yacht" component={MyYachtView} options={screenOptions('YACHT', dynamicDeviceTransition)} />
                            <Stack.Screen name="editYacht" component={EditYachtView} options={screenOptions('YACHT_EDIT', dynamicDeviceTransition)} />
                            <Stack.Screen name="shared" component={ShareYachtView} options={screenOptions('SHARES', dynamicDeviceTransition)} />
                            <Stack.Screen name="engines" component={YachtEnginesView} options={screenOptions('ENGINES', dynamicDeviceTransition)} />
                            <Stack.Screen name="inventory" component={YachtInventoryView} options={screenOptions('INVENTORY', dynamicDeviceTransition)} />
                            <Stack.Screen name="equipment" component={YachtEquipmentView} options={screenOptions('EQUIPMENT', dynamicDeviceTransition)} />
                            <Stack.Screen name="logs" component={YachtLogsView} options={screenOptions('MAINTENANCE_LOGS', dynamicDeviceTransition)} />
                            <Stack.Screen name="yachtTasks" component={YachtTasksView} options={screenOptions('TASKS', dynamicDeviceTransition)} />
                            <Stack.Screen name="tasks" component={MyTasksView} options={screenOptions('ICON_TASKS', fadeIn)} />
                            <Stack.Screen name="notes" component={YachtNotesView} options={screenOptions('NOTES', dynamicDeviceTransition)} />
                            <Stack.Screen name="checklists" component={YachtChecklistsView} options={screenOptions('CHECKLISTS', dynamicDeviceTransition)} />
                            <Stack.Screen name="templates" component={YachtTemplatesView} options={screenOptions('TEMPLATES', dynamicDeviceTransition)} />
                            <Stack.Screen name="photos" component={YachtPhotosView} options={screenOptions('PHOTOS', dynamicDeviceTransition)} />
                            <Stack.Screen name="attachments" component={YachtAttachmentsView} options={screenOptions('ATTACHMENTS', dynamicDeviceTransition)} />
                            <Stack.Screen name="notifications" component={NotificationsView} options={screenOptions('NOTIFICATIONS', dynamicDeviceTransition)} />
                            <Stack.Screen name="account" component={AccountView} options={screenOptions('ICON_ACCOUNT', fadeIn)} />
                            <Stack.Screen name="editAccount" component={EditAccountView} options={screenOptions('EDIT', dynamicDeviceTransition)} />
                            {/* <Stack.Screen name="roadmap" component={RoadmapView} options={screenOptions('ROADMAP', fadeIn)} /> */}
                        </Stack.Group>
                    </Stack.Navigator>
                </NavigationContainer>

                {/*<Modal
                    active={noticeModalActive}
                    setActive={setNoticeModalActive}
                    doWhileExit={()=>{setShownIntro(true)}}
                >
                    <Image
                        accessibilityHidden={true}
                        source={{ uri: require('./assets/svg/big_info.svg') }}
                        resizeMode="contain"
                        style={tw`h-16 w-16`}
                    />
                    <Heading hlevel={2} styleHlevel={3}>{ getLabel('WEB_LIMITED_TITLE') }</Heading>
                    <Span>{ getLabel('WEB_LIMITED_BODY') }</Span>
                    <View style={tw`flex-row mt-5`}>
                        <Button
                            style={tw`w-full shrink py-2`}
                            title={ getLabel('OK') }
                            onPress={()=>{setShownIntro(true); setNoticeModalActive(false)}}
                        />
                    </View>
                </Modal>*/}

                {/*<Modal
                    active={showChangeLog}
                    setActive={setShowChangeLog}
                    style={tw`w-[640px] max-w-[90%]`}
                >
                    <Heading hlevel={2} styleHlevel={2}>What's new?</Heading>
                    <Span style={tw`-mt-3 text-sm`}>{latestChangeLog.version} | {latestChangeLog.date}</Span>
                    <HorizontalRule style={tw`mt-5`} />
                    <ScrollView
                        style={{ width: '100%', maxHeight: tw.prefixMatch('lg') ? `calc(${height}px - 348px)` : `calc(${height}px - 192px)`, overflowX: tw.prefixMatch('lg') ? 'visible' : 'hidden' }}
                        contentContainerStyle={tw`w-full justify-start px-0 mt-5`}
                    >
                        <Span>The YachtWave crew has been working tirelessly to improve your app experience. This update includes:</Span>
                        {latestChangeLog?.changes?.map((log, i) => (
                            <View key={i} style={tw`mt-5`}>
                                <Span style={tw`font-bold`}>{log.title}</Span>
                                <Span>{log.desc}</Span>
                            </View>
                        ))}
                    </ScrollView>
                </Modal>*/}
            </GlobalContext.Provider>
        </ToastProvider>
    );
}

export default App;
