import React from 'react';
import { View, ScrollView } from 'react-native';
import { create, useDeviceContext } from 'twrnc';
import { Heading, PrimaryNav, Span, Button, HorizontalRule, SpecsCard, SectionPositionBar, AddQuickImportExportButtons } from '../elements';
import { getInitials, getLabel, getOptionsByLabelGroup, getPropertiesByGroup, getShareAmnt, getCurrentRoute } from '../helpers';
import { GlobalContext } from '../global';

function MyYachtView ({route, navigation})
{
    const tw = create( require(`../../tailwind.config.js`) );
    useDeviceContext(tw);
    
    let {
        loggedIn, setLoggedIn,
        userYachts,
        enabledFeatures,
        navigationRef,
    } = React.useContext(GlobalContext);

    React.useEffect(()=> 
    {
        if ( ! loggedIn && 'yacht' === getCurrentRoute(navigationRef) )
        {
            // localStorage.setItem('attempted-view', 'account');
            /*let attempted_view_args = localStorage.getItem('attempted-view-args');
            let new_attempted_view_args = {yacht:{yachtID: true}};
            if ( attempted_view_args ) { new_attempted_view_args = {...JSON.parse(attempted_view_args), ...new_attempted_view_args}; }
            localStorage.setItem('attempted-view-args', JSON.stringify(new_attempted_view_args));*/
            navigation.navigate('loading');
        }
    },[loggedIn, navigation]);

    const { yachtID } = route.params;
    
    // const [ pillMenuValue, setPillMenuValue ] = React.useState('Details');
    const [ scrollPosition, setScrollPosition ] = React.useState(0);
    const [ yacht, setYacht ] = React.useState(0);
    const [ shareAmount, setShareAmount ] = React.useState(getShareAmnt(yachtID));
    
    const labels = React.useRef(null);
    React.useEffect(()=> 
    {
        if ( ! labels.current )
        {
            labels.current = {
                OWNED_BY: getLabel('YACHT_OWNED_BY'),
                ROLE_ME: getLabel('ROLE_ME'),
                SHARES: getLabel('SHARES'),
                YEAR: getLabel('YEAR'),
                MANUFACTURER: getLabel('MANUFACTURER'),
                STATUS: getLabel('STATUS'),
                GENERAL: getLabel('GENERAL'),
                CREDENTIALS: getLabel('CREDENTIALS'),
                TYPE: getLabel('TYPE'),
                HIN: getLabel('HIN'),
                BRAND: getLabel('BRAND'),
                MODEL: getLabel('MODEL'),
                FLAG: getLabel('FLAG'),
                PORT: getLabel('PORT'),
                USCG: getLabel('USCG'),
                LOCAL_REGISTRATION: getLabel('LOCAL_REGISTRATION'),
                IMO: getLabel('IMO'),
                MMSI: getLabel('MMSI'),
                CALL_SIGN: getLabel('CALL_SIGN'),
                EPIRB: getLabel('EPIRB'),
                DIMENSIONS: getLabel('DIMENSIONS'),
                BEAM: getLabel('BEAM'),
                METERS_SHORT: getLabel('METERS_SHORT'),
                FEET_SHORT: getLabel('FEET_SHORT'),
                LENGTH: getLabel('LENGTH'),
                DRAFT: getLabel('DRAFT'),
                DISPLACEMENT: getLabel('DISPLACEMENT'),
                CLEARANCE: getLabel('CLEARANCE'),
                TONS: getLabel('TONS'),
                ELECTRICAL: getLabel('ELECTRICAL'),
                SHOREPOWER: getLabel('SHOREPOWER'),
                QUANTITY_SHORT: getLabel('QUANTITY_SHORT'),
                VOLTAGE: getLabel('VOLTAGE'),
                STRUCTURAL: getLabel('STRUCTURAL'),
                HULL_TYPE: getLabel('HULL_TYPE'),
                HULL_MATERIAL: getLabel('HULL_MATERIAL'),
                EDIT: getLabel('EDIT'),
                IMPORT: getLabel('IMPORT'),
                EXPORT: getLabel('EXPORT'),
            };
        }
    },[]);

    const sectionsSection = React.useRef(0);
    const generalSection = React.useRef(0);
    const credentialsSection = React.useRef(0);
    const dimensionsSection = React.useRef(0);
    const electricalSection = React.useRef(0);
    const structuralSection = React.useRef(0);

    let statusOptions = getOptionsByLabelGroup('types.vessel.status', true);
    statusOptions.forEach((value, index)=>
    {
        value.badge = { uri: require('../svg/status_'+value.label.toLowerCase().replace(/\s/g,'')+'.svg') };
    });

    let countryFlags = getPropertiesByGroup( 'value', 'data.countries', 'img_flag', false, true );
    let shorepowerQtyOptions = getOptionsByLabelGroup('types.shorepower.quantity', true);
    let roleOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'label', false, true );

    React.useEffect(() => {
        if ( yachtID )
        {
            userYachts.every((value, index) => {
                if ( yachtID == value.id ) { setYacht(value); /*console.log(value);*/ return false; }
                return true;
            });
        }
    },[yachtID, userYachts]);
    
    return (
        <View label="myYacht" style={tw`bg-gray0 h-screen md:flex-row overflow-hidden max-w-screen`}>

            <PrimaryNav navigation={navigation} setLoggedIn={setLoggedIn} yachtID={yachtID} />
        
            <View style={tw`bg-blue h-8 md:h-full md:w-10 z-20 md:z-0 -mb-3 md:mb-0 md:-mr-3`}>
                <View style={tw`bg-gray0 h-full w-full rounded-t-3xl md:rounded-l-3xl md:rounded-r-0 z-10 md:shadow-black md:shadow-opacity-10 md:shadow-radius-4`}></View>
            </View>

            <View style={[tw`bg-gray0 flex-grow md:pr-0 md:pl-0 z-10`, {width: tw.prefixMatch('md') ? 'calc(100% - 320px)' : '100%', height: tw.prefixMatch('md') ? '100%' : 'calc(100% - 112px)'}]}>
                { 
                    // <View style={tw`h-13 px-5 pb-5 md:py-0 md:px-0 md:h-33 flex-row md:items-center z-20 bg-gray0 md:hidden`}>
                    //     <PillMenu
                    //         selected={pillMenuValue}
                    //         menu={[
                    //             {name: 'Details', onPress: ()=> {setPillMenuValue('Details'); console.log('Clicked Details')} },
                    //             {name: 'Engines', onPress: ()=> {setPillMenuValue('Engines'); console.log('Clicked Engines')} },
                    //             {name: 'Other', onPress: ()=> {setPillMenuValue('Other'); console.log('Clicked Other')} },
                    //         ]}
                    //     />
                    // </View>
    
                    // <HorizontalRule style={tw`md:hidden`} />
                }
                    
                { /* ! tw.prefixMatch('md') &&
                    <SectionPositionBar
                        style={tw`z-10 px-5 pt-3 pb-5 md:hidden`}
                        styleHR={tw`top-4 w-[69.75%] left-[15%]`}
                        scroll={scrollPosition}
                        menu={[
                            {name: 'General', offset: 0, onPress: ()=> { sectionsSection.current.scrollTo({y: generalSection.current.offsetTop, animated: true}) }},
                            {name: 'Dimensions', offset: dimensionsSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: dimensionsSection.current.offsetTop, animated: true}) }},
                            {name: 'Electrical', offset: electricalSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: electricalSection.current.offsetTop, animated: true}) }},
                            {name: 'Structural', offset: structuralSection.current.offsetTop, onPress: ()=> { sectionsSection.current.scrollTo({y: structuralSection.current.offsetTop, animated: true}) }},
                        ]}
                    />
                */ }

                <View style={tw`shrink`}>
                
                    <View style={tw`w-full justify-start px-5 pt-1 md:px-0 md:pt-0`}></View>
                    
                    <ScrollView
                        ref={sectionsSection}
                        style={{width:'100%', overflowX:'visible'}}
                        contentContainerStyle={tw`w-full h-full justify-start px-5 md:pr-8 md:pl-4 relative`}
                        // onScroll={(e) => { setScrollPosition(e.nativeEvent.contentOffset.y) }}
                        // scrollEventThrottle="0"
                    >
                        <View style={tw`max-w-[1084px] pb-10 justify-start`}>
                            <View style={tw`flex-row w-full items-center justify-start mt-2 md:mt-6 lg:mt-10 mb-5 md:mb-1 lg:mb-7 md:px-1`}>
                                <Span style={tw`bg-${yacht.color || 'gray6'} text-white uppercase rounded-full text-lg font-semibold shrink-0 w-14 h-14 mr-3 text-center leading-[56px]`}>{yacht.initials}</Span>

                                <View>
                                    <Heading style={tw`leading-4`} hlevel={1} styleHlevel={tw.prefixMatch('md') ? 2 : 3}>
                                        {yacht.name}
                                    </Heading>
                                </View>
                            </View>

                            { true === enabledFeatures.sharing && true === yacht.is_owned_by_me &&
                                <View style={tw`md:flex-row md:flex-wrap md:px-1`}>
                                    <SpecsCard
                                        style={[tw`mt-4 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                        label={ labels.current?.SHARES }
                                        labelImage={{ uri: require('../svg/shares.svg') }}
                                        unit={shareAmount}
                                        onPress={() => { navigation.navigate('shared', {yachtID:yachtID}) }}
                                    />
                                </View>
                            }

                            { true === enabledFeatures.sharing && false === yacht.is_owned_by_me &&
                                <View style={tw`md:flex-row md:flex-wrap md:px-1`}>
                                    <SpecsCard
                                        style={[tw`mt-4 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                        label={ labels.current?.OWNED_BY }
                                        labelImage={{ uri: require('../svg/user.svg') }}
                                        value={yacht.owner_name}
                                    />
                                    <SpecsCard
                                        style={[tw`mt-4 md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                        label={ labels.current?.ROLE_ME }
                                        labelImage={{ uri: require('../svg/user2.svg') }}
                                        value={roleOptions.find(obj => {return obj.value == yacht.whoami.security_role})?.label }
                                    />
                                </View>
                            }

                            <View style={tw`md:flex-row md:flex-wrap md:px-1`}>
                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.YEAR }
                                    labelImage={{ uri: require('../svg/year.svg') }}
                                    value={yacht.year}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.MANUFACTURER }
                                    labelImage={{ uri: require('../svg/manufacturer.svg') }}
                                    value={yacht.mfg_name}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.STATUS }
                                    labelImage={{ uri: require('../svg/status.svg') }}
                                    value={yacht.tag}
                                    valueImage={ yacht.status_id ? statusOptions.find(obj => {return obj.value == yacht.status_id}).badge : { uri: require('../svg/status_null.svg') } }
                                />
                            </View>

                            <View ref={generalSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start md:px-1`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.GENERAL }
                                </Heading>

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.TYPE }
                                    labelImage={{ uri: require('../svg/vesseltype.svg') }}
                                    value={yacht.type_name}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.HIN }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.hin}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.BRAND }
                                    labelImage={{ uri: require('../svg/brand.svg') }}
                                    value={yacht.brand_name}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.MODEL }
                                    labelImage={{ uri: require('../svg/model.svg') }}
                                    value={yacht.model}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.FLAG }
                                    labelImage={{ uri: require('../svg/flag.svg') }}
                                    value={yacht.flag_country}
                                    valueImage={ yacht.flag_country_id && countryFlags.find(obj => {return obj.value == yacht.flag_country_id})?.img_flag ? { uri: require(`../images/flags/iso2/${countryFlags.find(obj => {return obj.value == yacht.flag_country_id}).img_flag}`) } : { uri: require('../svg/BlankFlag.svg') } }
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.PORT }
                                    labelImage={{ uri: require('../svg/location_blue.svg') }}
                                    value={yacht.port}
                                />
                            </View>

                            <View ref={credentialsSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start md:px-1`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.CREDENTIALS }
                                </Heading>

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.USCG }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.uscg}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.LOCAL_REGISTRATION }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.local_registration}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.IMO }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.imo}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.MMSI }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.mmsi}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.CALL_SIGN }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.call_sign}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.EPIRB }
                                    labelImage={{ uri: require('../svg/hin.svg') }}
                                    value={yacht.epirb_beacon_id}
                                />
                            </View>

                            <View ref={dimensionsSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start md:px-1`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.DIMENSIONS }
                                </Heading>

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.BEAM }
                                    labelImage={{ uri: require('../svg/beam.svg') }}
                                    value={yacht.beam}
                                    unit={ yacht.is_metric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.LENGTH }
                                    labelImage={{ uri: require('../svg/lenght.svg') }}
                                    value={yacht.length}
                                    unit={ yacht.is_metric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.DRAFT }
                                    labelImage={{ uri: require('../svg/draft.svg') }}
                                    value={yacht.draft}
                                    unit={ yacht.is_metric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.CLEARANCE }
                                    labelImage={{ uri: require('../svg/clearance_blue.svg') }}
                                    value={yacht.clearance}
                                    unit={ yacht.is_metric ? labels.current?.METERS_SHORT : labels.current?.FEET_SHORT }
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.DISPLACEMENT }
                                    labelImage={{ uri: require('../svg/displacement.svg') }}
                                    value={yacht.displacement}
                                    unit={ yacht.is_metric ? labels.current?.TONS : 'lbs' }
                                />
                            </View>

                            <View ref={electricalSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start md:px-1`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.ELECTRICAL }
                                </Heading>

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.VOLTAGE }
                                    labelImage={{ uri: require('../svg/voltage.svg') }}
                                    value={yacht.voltage}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.SHOREPOWER }
                                    labelImage={{ uri: require('../svg/shorepower.svg') }}
                                    value={yacht.shorepower}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.SHOREPOWER +' '+ labels.current?.QUANTITY_SHORT }
                                    labelImage={{ uri: require('../svg/electrical_blue.svg') }}
                                    value={yacht.shorepower_qty_id ? shorepowerQtyOptions.find(obj => {return obj.value == yacht.shorepower_qty_id}).label : ''}
                                />
                            </View>

                            <View ref={structuralSection} style={tw`items-center mt-0 mb-0 md:flex-row md:flex-wrap md:items-start md:px-1`}>
                                <Heading style={tw`leading-4 mt-8 md:mt-12`} hlevel={2} styleHlevel={tw.prefixMatch('md') ? 3 : 4}>
                                    { labels.current?.STRUCTURAL }
                                </Heading>

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.HULL_TYPE }
                                    labelImage={{ uri: require('../svg/hull type.svg') }}
                                    value={yacht.hull_type}
                                />

                                <SpecsCard
                                    style={[tw`md:mr-2`, {width: tw.prefixMatch('lg') ? 'calc(33.33% - 8px)' : '100%'}]}
                                    label={ labels.current?.HULL_MATERIAL }
                                    labelImage={{ uri: require('../svg/material.svg') }}
                                    value={yacht.hull_material}
                                />
                            </View>

                        </View>
                        
                    </ScrollView>

                    <HorizontalRule style={tw`md:hidden`} />

                    <View style={tw`my-5 mx-0 md:absolute md:top-1 lg:top-6 md:right-4 lg:right-10 justify-center flex-row`}>
                        {/*<Button
                            title={ labels.current?.IMPORT }
                            image={{ uri: require('../svg/import_white.svg') }}
                            style={tw`py-3 mx-2`}
                            styleText={tw`text-base tracking-1`}
                            onPress={()=>{
                                navigation.navigate('account', {import: true, id: yachtID});
                                setTimeout(()=>{ if ( null !== sectionsSection.current ) { sectionsSection.current.scrollTo({y: 0, animated: false}) }}, 500)
                            }}
                        />
                        <Button
                            title={ labels.current?.EXPORT }
                            image={{ uri: require('../svg/export_white.svg') }}
                            style={tw`py-3 mx-2`}
                            styleText={tw`text-base tracking-1`}
                            onPress={()=>{
                                navigation.navigate('account', {export: true, id: yachtID});
                                setTimeout(()=>{ if ( null !== sectionsSection.current ) { sectionsSection.current.scrollTo({y: 0, animated: false}) }}, 500)
                            }}
                        />*/}
                        <Button
                            title={ labels.current?.EDIT }
                            image={{ uri: require('../svg/edit_white.svg') }}
                            style={tw`py-3 mx-2 w-full shrink`}
                            styleText={tw`text-base tracking-1`}
                            onPress={()=>{
                                navigation.navigate('editYacht', {yachtID: yachtID})
                                setTimeout(()=>{ if ( null !== sectionsSection.current ) { sectionsSection.current.scrollTo({y: 0, animated: false}) }}, 500)
                            }}
                        />
                    </View>

                    <AddQuickImportExportButtons navigation={navigation} yachtID={yachtID} style={tw`mb-18 md:mb-0`} />
                </View>
            </View>
        </View>
    )
}

export default MyYachtView;
