import changelog from '../changelog.json';
import RegExHelper from './regex';


export function obscurePhoneNumber ( phoneNum )
{
    const lastFour = phoneNum.substring(phoneNum.length - 4, phoneNum.length);
    phoneNum = phoneNum.replace(/[0-9]/g, "X");
    phoneNum = phoneNum.substring(0, phoneNum.length - 4) + lastFour;
    return phoneNum;
}

export function debounce(fn, delay) {
    let timer = null;
    return function (...args)
    {
        const context = this;
        clearTimeout(timer);
        timer = setTimeout(() => fn.apply(context, args), delay);
    };
}

export function getInitials ( name ) 
{
    if ( ! name ) { name = ''; }
    const initials = name
        .split(' ')
        .map(word => word[0])
        .join('');

    if ( ! initials.length ) { return '--'; }
    
    return initials.substring(0, 2).toUpperCase();
}

export function goPrevScreen ( navigation, fallback, parameters )
{
    const routes = navigation.getState()?.routes;
    const lastRoute = routes[routes.length - 2];

    if ( lastRoute ) {
        navigation.navigate(lastRoute.name, lastRoute.params);
    }
    else if ( fallback ) {
        parameters = parameters || {};
        navigation.navigate(fallback, parameters);
    }
}

export function makeCopyright ( version )
{
    let copyright = getLabel('COPYRIGHT');
    copyright = copyright.replace('v%version%', version);

    return copyright;
}

export function dateStr_to_timeAgo ( dateStr )
{
    // take a date string like "2022-08-11 18:13:26.000000" and return X days, hours, or minutes ago
    let date = new Date(dateStr);
    let now = new Date();

    // correct for api time offset
    const offset = date.getTimezoneOffset();
    date = new Date(date.getTime() - (offset*60*1000));
    
    const diff = now.getTime() - date.getTime();

    const days = Math.floor(diff / (1000 * 60 * 60 * 24));
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor(diff / (1000));

    if ( days > 0 ) { return days + ' ' + getLabel('DAYS_AGO'); }
    if ( hours > 0 ) { return hours + ' ' + getLabel('HOURS_AGO'); }
    if ( minutes > 0 ) { return minutes + ' ' + getLabel('MINUTES_AGO'); }
    if ( seconds > 0 ) { return seconds + ' ' + getLabel('SECONDS_AGO'); }
    return getLabel('JUST_NOW');
}

function splitByFirstDelimiter (str, delimiter) {
    const delimiterPosition = str.indexOf(delimiter);

    if (delimiterPosition === -1) {
        return [str, '']; // If the delimiter isn't found, return the entire string as the first part.
    }

    const firstPart = str.substring(0, delimiterPosition);
    const remainingPart = str.substring(delimiterPosition + 1);

    return [firstPart, remainingPart];
}

function getDescendantProp (obj, desc)
{
    if ( ! obj ) { return ''; }

    const [category, key] = splitByFirstDelimiter(desc, '.');

    return obj[category][key];
}

export function fetchLabels ( func, version, apiUrl, apiVersion )
{
    fetch(apiUrl, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            endpoint: apiVersion < 2 ? 'system/locales/v2' : 'system/locales', v: apiVersion,
        }),
    })
    .then((response) => {
        return response.text().then(function(text) {
            const result = JSON.parse(text);
            if ( result.error )
            {
                console.warn(result.error);
            }
            else
            {
                // console.log('fetching labels');
                localStorage.setItem( 'labels_ver', version );
                // localStorage.setItem( 'labels', JSON.stringify(result) );
                if ( 'function' === typeof func ) { func(result); }
            }
        });
    })
    .catch(error => {
        console.warn(error);
        if ( 'function' === typeof func ) { func(false); }
    });
}

export function maybeShowChangeLog ()
{
    const lastVersionSeen = localStorage.getItem( 'last_ver' );

    if ( ! lastVersionSeen || changelog.latest !== lastVersionSeen )
    {
        // remove "v" and decimals from string
        const changelogLatestInt = parseInt(changelog.latest.replace(/\./g, '').replace('v',''));
        const lastVersionSeenInt = ! lastVersionSeen ? 0 : parseInt(lastVersionSeen.replace(/\./g, '').replace('v',''));

        /*console.log({
            changelogLatestInt: changelogLatestInt,
            lastVersionSeenInt: lastVersionSeenInt,
        });*/

        if ( changelogLatestInt > lastVersionSeenInt ) {
            localStorage.setItem( 'last_ver', changelog.latest );
            return changelog.versions.find((version) => { return version.version === changelog.latest; });
        }

        return false;
    }

    return false;
}

export function getChangeLog ()
{
    return changelog;
}

export function getSubscription ( feature )
{
    let has_access = false;

    let subscriptions = localStorage.getItem( 'subscription' );
    if ( ! subscriptions ) {  console.warn('no subscriptions found'); return has_access; }

    subscriptions = JSON.parse(subscriptions);

    if ( 'undefined' === typeof subscriptions[feature] ) { console.warn('subscription "'+feature+'" not found'); return has_access; }

    if ( subscriptions[feature] ) { has_access = true; }

    return has_access;
}

const tempLabels = {
    // Won't add to API; need to alternatively handle
    'LOW_PRIORITY': 'Priority: Low',
    'MEDIUM_PRIORITY': 'Priority: Medium',
    'HIGH_PRIORITY': 'Priority: High',
    'CRITICAL_PRIORITY': 'Priority: Critical',

    // Request labels:
    'REFRESH_PAGE': 'Refresh Page',
    'SEARCH_REQUIRED_TITLE': 'Search to Begin',
    'SEARCH_REQUIRED_BODY': 'Enter your search criteria to view results',

    'IMPORT_CHOOSE_VESSEL_TITLE': 'Choose where to import data.',
    'IMPORT_CHOOSE_VESSEL_BODY': 'Choose a vessel to import data into, then click “next”.',
    'IMPORT_CHOOSE_DATA_TITLE': 'Choose what data to import.',
    'IMPORT_CHOOSE_DATA_BODY': 'Choose the type of data you’re looking to import and click “next”.',
    'IMPORT_CSV': 'Import CSV',
    'IMPORT_DOWNLOAD_TEMPLATE_BODY': 'Download the CSV template. The uploaded CSV must follow this same structure in order to ensure your import functions properly. Non required data can be left empty.',
    'IMPORT_DOWNLOAD_TEMPLATE': 'Download Template',
    'IMPORT_SELECT_CSV': 'Select CSV',
    'IMPORT': 'Import',
    'IMPORT_FIELD_INVALID': 'The %field% “%value%” isn’t available. Please make sure you specify a value that exists in the %field% pick list on this site and try again.',
    'IMPORT_PARTIAL_SUCCESS': 'Data was successfully imported until the error specified was found. Review what successfully imported to avoid creating duplicates.',
    'MAX_CHARS': 'Exceeds maximum character count of %size%',
};

let cachedLabels = null;

function parseLabels()
{
    const labelsRaw = localStorage.getItem('labels');
    if (!labelsRaw) {
        console.warn('no labels found');
        return null;
    }
    return JSON.parse(labelsRaw);
}

function getCachedLabels()
{
    if ( ! cachedLabels )
    {
        cachedLabels = parseLabels();
        if ( ! cachedLabels ) { return ''; }
    }

    return cachedLabels;
}

export function getLabel ( labelName )
{
    const labels = getCachedLabels();

    const label = labels.lcid?.strings?.[labelName] || tempLabels[labelName];

    if (!label) {
        console.warn(`label "${labelName}" not found`);
        return '';
    }

    if (Array.isArray(label)) {
        return label[Math.floor(Math.random() * label.length)];
    }

    return label;
}

export function getLabelByID ( ID, labelGroup )
{
    let label = '';
    const labels = getCachedLabels();
    const found = getDescendantProp(labels, labelGroup);

    if ( ! found ) { console.warn('label group "'+labelGroup+'" not found'); return label; }

    found.every((obj, index) => {
        if ( ID.toString() === obj.id?.toString() ) { label = obj.label; return false; }
        return true;
    });
    
    if ( ! label ) { console.warn('ID "'+ID+'" not found in label group "'+labelGroup+'"'); return label; }

    return label;
}

export function getColorIDByVesselStatusID ( ID )
{
    let label = '';
    const labelGroup = 'types.vessel.status';
    const labels = getCachedLabels();
    const found = getDescendantProp(labels, labelGroup);

    if ( ! found ) { console.warn('label group "'+labelGroup+'" not found'); return label; }

    found.every((obj, index) => {
        if ( ID.toString() === obj.id?.toString() ) { label = obj.color_id; return false; }
        return true;
    });
    
    if ( ! label ) { console.warn('ID "'+ID+'" not found in label group "'+labelGroup+'"'); return label; }

    return label;
}

export function getColorByID ( ID )
{
    if ( 'undefined' === typeof ID ) { return ''; }

    let label = '';
    const labelGroup = 'types.colors';
    const labels = getCachedLabels();
    const found = getDescendantProp(labels, labelGroup);

    if ( ! found ) { console.warn('label group "'+labelGroup+'" not found'); return label; }

    found.every((obj, index) => {
        if ( ID.toString() === obj.id?.toString() ) { label = obj.hex; return false; }
        return true;
    });
    
    if ( ! label ) { console.warn('ID "'+ID+'" not found in label group "'+labelGroup+'"'); return label; }

    return label;
}

export function getPropertiesByGroup ( property, labelGroup, keyForValuePairs, displayTransform, returnValueLabelObj, sortResultsBy )
{
    if ( undefined === sortResultsBy ) { sortResultsBy = 'display_order'; }

    let returnedLabels = [];
    const labels = getCachedLabels();
    let found = getDescendantProp(labels, labelGroup);
    
    if ( ! found ) { console.warn('label group "'+labelGroup+'" not found'); return returnedLabels; }


    let sortedLabels = found;

    if ( sortResultsBy ) {
        sortedLabels = found.sort((a, b) => {
           return a[sortResultsBy] - b[sortResultsBy];
        });
    }


    if ( keyForValuePairs && returnValueLabelObj )
    {
        sortedLabels.forEach((value, index) => {
            let label = value[keyForValuePairs];
            if ( 'function' === typeof displayTransform ) { label = displayTransform(value[keyForValuePairs]); }
            returnedLabels.push({value:value[property],[keyForValuePairs]:label});
        });

        return returnedLabels;
    }

    if ( keyForValuePairs )
    {
        sortedLabels.forEach((value, index) => {
            let label = value[keyForValuePairs];
            if ( 'function' === typeof displayTransform ) { label = displayTransform(value[keyForValuePairs]); }
            returnedLabels[value[property]] = label;
        });

        return returnedLabels;
    }

    sortedLabels.forEach((value, index) => {
        returnedLabels.push(value[property]);
    });

    returnedLabels = returnedLabels.filter((value, index, self)=>{return self.indexOf(value) === index;}).sort();

    return returnedLabels;
}

export function getOptionsByLabelGroup ( labelGroup, returnKeyValuePairs, sortResultsBy )
{
    if ( returnKeyValuePairs ) { returnKeyValuePairs = 'label'; }
    return getPropertiesByGroup( 'value', labelGroup, returnKeyValuePairs, false, true, sortResultsBy );
}

export function sort_by(fields, reverse, primer) {
    const fieldNames = fields.split(',').map(field => field.trim());

    const keys = fieldNames.map(field => {
        const fieldReverse = field.startsWith('!');
        const fieldName = fieldReverse ? field.slice(1) : field;

        return {
            key: primer ?
                function(x) { return primer(x[fieldName]); } :
                function(x) { return x[fieldName]; },
            reverse: fieldReverse ? -1 : 1
        };
    });

    reverse = !reverse ? 1 : -1;

    return function(a, b) {
        for (let { key, reverse: fieldReverse } of keys) {
            let keyA = key(a), keyB = key(b);
            let order = reverse * fieldReverse;

            // console.log(`Comparing by ${fieldReverse === -1 ? '!' : ''}${key.name}:`, keyA, keyB);

            // Special handling for boolean fields to ensure false values sort before true values
            if (typeof keyA === 'boolean' && typeof keyB === 'boolean') {
                let result = order * ((keyB === keyA) ? 0 : keyA ? 1 : -1);
                // console.log(`Result for boolean comparison: ${result}`);
                return result;
            }

            if (keyA !== keyB) {
                let result = order * ((keyA > keyB) - (keyB > keyA));
                // console.log(`Result for comparison: ${result}`);
                return result;
            }
        }
        // console.log('Elements are equal, returning 0');
        return 0;
    };
}

export function now ()
{
    let yourDate = new Date();
    return yourDate.toISOString();
}

export function now_datetime ()
{
    const now = new Date();
    const isoString = now.toISOString();

    // Extracting parts from the ISO string
    const date = isoString.slice(0, 10); // "YYYY-MM-DD"
    const time = isoString.slice(11, 19); // "HH:MM:SS"
    const milliseconds = isoString.slice(20, 23); // "sss"

    return `${date} ${time}.${milliseconds}`;
}

export function now_MMDDYYYY ()
{
    return date_MMDDYYYY( now(), true );
}

export function now_YYYYMMDD ()
{
    return date_YYYYMMDD( now(), true );
}

export function date_MMDDYYYY ( dateStr, leadingZeroes, offsetTimezone )
{
    let yourDate = new Date(dateStr);

    if ( offsetTimezone ) {
        const offset = yourDate.getTimezoneOffset();
        yourDate = new Date(yourDate.getTime() - (offset*60*1000));
    }

    if ( leadingZeroes )
    {
        return ('0' + (yourDate.getMonth() + 1)).slice(-2) + '/' + ('0' + yourDate.getDate()).slice(-2) + '/' +  yourDate.getFullYear();
    }
    return (yourDate.getMonth() + 1) + '/' + yourDate.getDate() + '/' +  yourDate.getFullYear();
}

export function date_YYYYMMDD ( dateStr, leadingZeroes )
{
    let yourDateISO = new Date(dateStr).toISOString();
    let YYYYMMDD = yourDateISO.slice(0,10);

    if ( false === leadingZeroes )
    {
        return YYYYMMDD.replaceAll('-0', '-');
    }

    return YYYYMMDD;
}

export function date_DMJYYYY ( dateStr )
{
    if ( ! dateStr ) { dateStr = now(); }
    let yourDate = new Date(dateStr);
    return yourDate.toLocaleDateString('en-US', {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
    });
}

export function date_DMJYYYY_TIME ( dateStr, offsetTimezone )
{
    /* do the same as date_DMJYYYY() but also add Hour, Minute, Second & AM/PM */
    if ( ! dateStr ) { dateStr = now(); }
    let yourDate = new Date(dateStr);
    // account for timezone offset
    if ( offsetTimezone ) {
        const offset = yourDate.getTimezoneOffset();
        yourDate = new Date(yourDate.getTime() - (offset*60*1000));
    }
    return yourDate.toLocaleDateString('en-US', {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true
    });
}

export function MMDDYYYY_to_YYYYMMDD ( MMDDYYYY, inputDelimiter, outputDelimiter )
{
    // function does not alter date in any way, simply re-structures the string
    if ( ! MMDDYYYY ) { return ''; }
    if ( ! inputDelimiter ) { inputDelimiter = '/'; }
    if ( ! outputDelimiter ) { outputDelimiter = '-'; }
    var dateParts = MMDDYYYY.split(inputDelimiter);
    return dateParts[2] + outputDelimiter + dateParts[0] + outputDelimiter + dateParts[1];
}

export function YYYYMMDD_to_MMDDYYYY ( YYYYMMDD, inputDelimiter, outputDelimiter )
{
    // function does not alter date in any way, simply re-structures the string
    if ( ! YYYYMMDD ) { return ''; }
    if ( ! inputDelimiter ) { inputDelimiter = '-'; }
    if ( ! outputDelimiter ) { outputDelimiter = '/'; }
    var dateParts = YYYYMMDD.split(inputDelimiter);
    return dateParts[1] + outputDelimiter + dateParts[2] + outputDelimiter + dateParts[0];
}

export function MMDDYY_to_MMDDYYYY ( MMDDYY, inputDelimiter, outputDelimiter, YY )
{
    // function does not alter date in any way, simply re-structures the string
    if ( ! MMDDYY ) { return ''; }
    if ( ! inputDelimiter ) { inputDelimiter = '/'; }
    if ( ! outputDelimiter ) { outputDelimiter = '/'; }
    if ( ! YY ) { YY = '20'; } // default to 20YY (4-digit year)
    var dateParts = MMDDYY.split(inputDelimiter);
    return dateParts[0] + outputDelimiter + dateParts[1] + outputDelimiter + YY + dateParts[2];
}

export function YYYYMMDD_to_DMJYYYY (YYYYMMDD, inputDelimiter, outputDelimiter) {
    // function does not alter date in any way, simply re-structures the string
    if (!YYYYMMDD) { return ''; }
    if (!inputDelimiter) { inputDelimiter = '-'; }
    if (!outputDelimiter) { outputDelimiter = ','; }

    var dateParts = YYYYMMDD.split(inputDelimiter);

    // Create a new date string with local time offset
    var localDateString = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]).toLocaleDateString('en-US', {
        weekday: 'short', year: 'numeric', month: 'short', day: 'numeric'
    });

    // Remove the comma from the weekday and format
    return localDateString.replaceAll(',', outputDelimiter);
}

export function is_date_in_past(date_value) {
    // Parse date_value as UTC
    const inputDate = new Date(`${date_value}T00:00:00Z`);

    // Get the current date in UTC
    const today = new Date();
    const todayUTC = new Date(Date.UTC(today.getUTCFullYear(), today.getUTCMonth(), today.getUTCDate() - 1));

    // Compare the two dates
    return inputDate < todayUTC;
}

export function truncate ( value, limit ) 
{
    if ( ! value ) { return ''; }

    if (value.length > limit) {
        return value.substring(0, limit) + '…';
    }
    return value;
}

export function getYachtOptions ()
{
    let yachts = localStorage.getItem( 'yachts' );
    if ( ! yachts ) { return []; }

    yachts = JSON.parse(yachts);

    let options = [];
    yachts.every((item, index) => {
        if ( item.pending ) { return true; } // skip unaccepted shared yachts
        options.push({badge: true, color: item.color, value: item.id, label: item.name});
        return true;
    });

    return options;
}

export function getUserOptions ( vessel_id )
{
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;
    let options = [];
    let roleOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'label', false, true );

    let yachts = localStorage.getItem( 'yachts' );
    if ( ! yachts ) { return options; }

    yachts = JSON.parse(yachts);

    yachts.every((vessel, index) => {
        // select menu options
        if ( vessel_id && parseInt(vessel_id) !== parseInt(vessel.id) ) { return true; }

        if ( ! vessel_id && ! options.find(item => parseInt(item.value) === parseInt(user.id)) ) { options.push({badge: true, label:user ? user.name + ' (Me)' : '', value:user ? user.id : 0}); }

        vessel.vessel_users.every((vesselUser, i) => {
            if ( ! options.find(item => parseInt(item.value) === parseInt(vesselUser.user_id)) )
            {
                let role = roleOptions.find(obj => {return obj.value === vesselUser.security_role});
                options.push({badge: true, label: vesselUser.name + ( vesselUser.user_id === user.id ? ' (Me)' : '' ), value: vesselUser.user_id, description: role && vessel_id ? role.label : ''});
                return true;
            }
        });
        return true;
    });

    return options;
}

export function getUserDataByID ( user_id )
{
    let yachts = localStorage.getItem( 'yachts' );
    if ( ! yachts ) { return {}; }

    yachts = JSON.parse(yachts);

    let user = {};
    yachts.every((vessel, index) => {
        vessel.vessel_users.every((vesselUser, i) => {
            if ( parseInt(vesselUser.user_id) === parseInt(user_id) )
            {
                user = vesselUser;
                return false;
            }
            return true;
        });
        return true;
    });

    return user;
}

export function getEngineOptions ( vessel_id, maintenance_type_id )
{
    let options = [];
    const engineTypeOptions = getOptionsByLabelGroup('types.engine.types', true);
    const locationOptions = getOptionsByLabelGroup('types.vessel.locations', true);
    const maintenanceType = getPropertiesByGroup('value', 'types.engine.types', 'maintenance_type_id', false, true);

    let yachts = localStorage.getItem( 'yachts' );
    if ( ! yachts ) { return options; }

    yachts = JSON.parse(yachts);

    yachts.every((vessel, index) => {
        // select menu options
        if ( vessel_id && parseInt(vessel_id) !== parseInt(vessel.id) ) { return true; }

        vessel.engines.every((engine, i) => {
            if ( ! options.find(item => parseInt(item.value) === parseInt(engine.id)) )
            {
                if ( maintenance_type_id && maintenanceType.find(obj => {return obj.value === engine.type_id})?.maintenance_type_id !== maintenance_type_id ) { return true; }

                let engineType = engineTypeOptions.find(obj => {return obj.value === engine.type_id});
                let engineLocation = locationOptions.find(obj => {return obj.value === engine.location_id});

                options.push({label: engineType.label, value: engine.id, description: engineLocation && vessel_id ? getLabel('LOCATION') +': '+ engineLocation.label : ''});
                return true;
            }
        });
        return true;
    });

    return options;
}

export function getEquipmentOptions ( vessel_id )
{
    let options = [];
    // const equipmentTypeOptions = getOptionsByLabelGroup('types.equipment.types', true);
    const locationOptions = getOptionsByLabelGroup('types.inventory.locations', true);
    const manufacturerOptions = getOptionsByLabelGroup('data.equipment.manufacturers', true);

    let yachts = localStorage.getItem( 'yachts' );
    if ( ! yachts ) { return options; }

    yachts = JSON.parse(yachts);

    yachts.every((vessel, index) => {
        // select menu options
        if ( vessel_id && parseInt(vessel_id) !== parseInt(vessel.id) ) { return true; }

        vessel.equipment.every((equipment, i) => {
            if ( ! options.find(item => parseInt(item.value) === parseInt(equipment.id)) )
            {
                // let equipmentType = equipmentTypeOptions.find(obj => {return obj.value === equipment.type_id});
                // console.log(equipmentType);
                let equipmentLocation = locationOptions.find(obj => {return obj.value === equipment.location_id});

                options.push({
                    label: ( equipment.manufacturer_id ? manufacturerOptions.find(obj => {return obj.value == equipment.manufacturer_id})?.label + ' ' : '' ) + equipment.model, // equipmentType.label,
                    value: equipment.id,
                    description: equipmentLocation && vessel_id ? getLabel('LOCATION') +': '+ equipmentLocation.label : ''
                });
                return true;
            }
        });
        return true;
    });

    return options;
}

export function getLocationFromAssetID ( asset_id, vessel_id, asset_type, locations_labels )
{
    if ( ! asset_id ) { return 0; }
    if ( ! vessel_id ) { return 0; }
    if ( ! asset_type ) { return 0; }
    if ( ! locations_labels ) { locations_labels = 'types.inventory.locations'; }

    const locationOptions = getOptionsByLabelGroup(locations_labels, true);

    // search through vessels until we find id match, then in the assset_type specified, search through assets until we find id match, then get asset's location_id and return corresponding label value

    let yachts = localStorage.getItem( 'yachts' );
    if ( ! yachts ) { return 0; }

    yachts = JSON.parse(yachts);

    let location_id = 0;

    yachts.every((vessel, index) => {
        // select menu options
        if ( parseInt(vessel_id) !== parseInt(vessel.id) ) { return true; }

        vessel[asset_type].every((asset, i) => {
            if ( parseInt(asset_id) === parseInt(asset.id) )
            {
                location_id = asset.location_id;
                return false;
            }
            return true;
        });
        return true;
    });

    return location_id;
}

export function getShareAmnt ( vessel_id )
{
    let yachts = localStorage.getItem( 'yachts' );
    let count = 0;

    if ( ! yachts ) { return '' + count; }
    yachts = JSON.parse(yachts);

    yachts.every((vessel, index) => {

        if ( ! vessel_id ) { return true; }

        if ( vessel_id && parseInt(vessel_id) !== parseInt(vessel.id) ) { return true; }

        let shares = vessel.vessel_users.filter((value, index) => {
            return ! value.is_rejected;
        });

        count += shares.length - 1;
        return true;
    });

    return '' + count;
}

export function getShares ( vessel_id )
{
    let yachts = localStorage.getItem( 'yachts' );
    let shares = [];
    let roleOptions = getPropertiesByGroup( 'identifier', 'types.security.roles', 'label', false, true );

    if ( ! yachts ) { return shares; }
    yachts = JSON.parse(yachts);

    yachts.every((vessel, index) => {

        if ( vessel_id && parseInt(vessel_id) !== parseInt(vessel.id) ) { return true; }

        if ( ! vessel_id ) { return true; }

        vessel.vessel_users.every((vesselUser, i) => {
            if ( ! shares.find(item => parseInt(item.email) === parseInt(vesselUser.email)) )
            {
                if ( vesselUser.is_owner ) { return true; } // we don't count the owner

                let role = roleOptions.find(obj => {return obj.value === vesselUser.security_role});
                vesselUser.security_role_name = role ? role.label : '';
                vesselUser.status = 1 === vesselUser.is_accepted ? 'accepted' : 'pending';
                vesselUser.status = vesselUser.is_rejected ? 'rejected' : vesselUser.status;

                shares.push(vesselUser);
                return true;
            }
        });
        return true;
    });

    return shares;
}

export function getUnreadNotificationsCount ()
{
    let notifications = localStorage.getItem( 'notifications' );
    if ( ! notifications ) { return 0; }

    notifications = JSON.parse(notifications);

    let count = 0;
    notifications.every((item, index) => {
        if ( ! item.is_viewed ) { count++; }
        return true;
    });

    return count;
}

export function getCurrentRoute ( navRef )
{
    // possibly delivers incorrect route name if the route is not yet loaded
    return navRef?.current?.getState() ? navRef.current.getState().routes[navRef.current.getState().routes.length - 1].name : '';
}

export function getFilterStates ()
{
    let filterStates = localStorage.getItem( 'filterStates' );
    if ( ! filterStates )
    {
        filterStates = JSON.stringify({});
        localStorage.setItem( 'filterStates', filterStates );
    }

    return JSON.parse(filterStates);
}

export function getRouteFilter ( currentRoute )
{
    let filterStates = getFilterStates();

    return filterStates[currentRoute] ? filterStates[currentRoute] : '';
}

export function updateRouteFilter ( currentRoute, filter )
{
    if ( ! currentRoute || ! filter ) { return false; }

    let filterStates = getFilterStates();

    filterStates[currentRoute] = filter;

    localStorage.setItem( 'filterStates', JSON.stringify(filterStates) );
}

export function fetchAccountData ( clientKey, GLOBALS, setLoadingData )
{
    if ( ! clientKey.length ) { return false; }

    // console.log('fetching account data');

    const thisRoute = getCurrentRoute(GLOBALS.navigationRef);

    // console.log(thisRoute);

    if ( 'function' === typeof setLoadingData && GLOBALS.loggedIn && 'login' !== thisRoute ) { setLoadingData(true); }
    
    fetch(GLOBALS.apiUrl, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            endpoint: 'user/data',
            v: GLOBALS.apiVersion,
            client_key: clientKey,
            device_id: localStorage.getItem('deviceID')
        }),
    })
    .then((response) => {
        return response.text().then((text) => {
            const result = JSON.parse(text);
            if ( result.error ) 
            {
                if ( '401' === result.error ){ GLOBALS.setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                console.warn(result.error);
            }
            else
            {
                localStorage.setItem( 'raw_data', JSON.stringify(result) );
                
                const data = result.user_data;

                // user account data 
            
                let user = {
                    id: data?.id || 0,
                    name: data?.name || '',
                    email: data?.email || '',
                    mobile: data?.mobile || '',
                    lang_id: data?.lang_id || 'en_us',
                    last_name: data?.last_name || '',
                    first_name: data?.first_name || '',
                    name_short: data?.name_short || '',
                    country_id: data?.country_id || 1,
                    country_code: data?.country_code || 1,
                    receives_system_emails: data?.receives_system_emails || 0,
                    receives_info_emails: data?.receives_info_emails || 0,
                    receives_notification_sms: data?.receives_notification_sms || 0,
                    receives_notification_push: data?.receives_notification_push || 0,
                    receives_notification_emails: data?.receives_notification_emails || 0,
                    is_mobile_verified: data?.is_mobile_verified || 0,
                    is_email_verified: data?.is_email_verified || 0,
                    modified_date: data?.modified_date || '1970-01-01 00:00:00.000000',
                };

                localStorage.setItem( 'user', JSON.stringify(user) );
                GLOBALS.setUserID(user.id);
                GLOBALS.setUserFirstName(user.first_name);
                GLOBALS.setUserLastName(user.last_name);
                GLOBALS.setUserPhone(user.mobile);
                GLOBALS.setUserPhonePrefix(user.country_code.replace('+',''));

                // subscription details

                let subscription = {
                    id: data.subscription[0].id,
                    name: data.subscription[0].name,
                    description: data.subscription[0].description,
                    feature_notes: data.subscription[0].feature_notes,
                    feature_share: data.subscription[0].feature_share,
                    feature_tasks: data.subscription[0].feature_tasks,
                    modified_date: data.subscription[0].modified_date,
                    feature_images: data.subscription[0].feature_images,
                    feature_engines: data.subscription[0].feature_engines,
                    feature_manuals: data.subscription[0].feature_manuals,
                    feature_inventory: data.subscription[0].feature_inventory,
                    feature_checklists: data.subscription[0].feature_checklists,
                    feature_log_cruise: data.subscription[0].feature_log_cruise,
                    feature_log_maintenance: data.subscription[0].feature_log_maintenance,
                    feature_roadmap: data.subscription[0].feature_roadmap,
                };

                localStorage.setItem( 'subscription', JSON.stringify(subscription) ); 

                // notifications
                let notifications = [];
                if ( data.notifications ) { notifications = data.notifications; }
                localStorage.setItem( 'notifications', JSON.stringify(notifications) );
                GLOBALS.setUserNotifications(notifications);

                // vessels

                let yachts = [];
                let myTasks = [];

                // filter out vessels that have is_rejected=1
                if ( data.vessels )
                {
                    data.vessels = data.vessels.filter((value, index) => {
                        return ! value.is_rejected;
                    });
                }

                if ( data.vessels )
                {
                    let vesselData = processVesselsData(data.vessels, user);
                    yachts = vesselData.yachts;
                    myTasks = vesselData.myTasks;
                }
                
                localStorage.setItem( 'yachts', JSON.stringify(yachts) );
                GLOBALS.setUserYachts(yachts);

                localStorage.setItem( 'tasks', JSON.stringify(myTasks) );
                GLOBALS.setUserTasks(myTasks);

                if ( 'function' === typeof setLoadingData ) { setLoadingData(false); }
            }
        });
    })
    .catch(error => {
        console.warn(error);
    });
}

export function processVesselsData( vessels, user )
{
    let yachts = [];
    let myTasks = [];
    if ( ! user ) { user = localStorage.getItem( 'user' ); }

    vessels.every((value, index) => {

        let yacht = processSingleVesselData(value, user.id);

        value.tasks.every((taskValue, index) => {
            taskValue.vessel_name = yacht.name;
            taskValue.vessel_initials = yacht.initials;
            taskValue.vessel_color = yacht.color;

            // collect all tasks we created or are assigned to us
            if ( taskValue.assignee_user_id === user.id || taskValue.create_user_id === user.id )
            {
                myTasks.push(taskValue);
            }

            return true;
        });

        yachts.push(yacht);
        return true;
    });
    return {yachts:yachts, myTasks:myTasks};
}

export function processSingleVesselData( yacht, user_id )
{
    if ( yacht.type_id ) { yacht.type_name = getLabelByID( yacht.type_id, 'types.vessel.types' ); }
    if ( yacht.manufacturer_id ) { yacht.mfg_name = getLabelByID( yacht.manufacturer_id, 'data.vessel.manufacturers' ); }
    if ( yacht.brand_id ) { yacht.brand_name = getLabelByID( yacht.brand_id, 'data.vessel.brands' ); }
    if ( yacht.status_id )
    {
        yacht.tag = getLabelByID( yacht.status_id, 'types.vessel.status' );
        yacht.color = '[#'+getColorByID(getColorIDByVesselStatusID(yacht.status_id))+']';
        yacht.tagColor = yacht.color;
    }
    if ( yacht.flag_country_id ) { yacht.flag_country = getLabelByID( yacht.flag_country_id, 'data.countries' ); }
    if ( yacht.shorepower_id ) { yacht.shorepower = getLabelByID( yacht.shorepower_id, 'types.shorepower' ); }
    if ( yacht.voltage_id ) { yacht.voltage = getLabelByID( yacht.voltage_id, 'types.voltage' ); }
    if ( yacht.hull_type_id ) { yacht.hull_type = getLabelByID( yacht.hull_type_id, 'types.hull.types' ); }
    if ( yacht.hull_material_id ) { yacht.hull_material = getLabelByID( yacht.hull_material_id, 'types.hull.materials' ); }

    yacht.description = ( yacht.year ? yacht.year : '' ) + ( yacht.brand_name ? ' / '+ yacht.brand_name : '' ) + ( yacht.model ? ' / '+ yacht.model : '' );
    yacht.location = yacht.port;
    yacht.pending = false;

    // remove trailing zeros past decimal point on the following attributes:
    // beam, length, draft, clearance, displacement
    yacht.beam = parseFloat(parseFloat(yacht.beam).toFixed(3));
    yacht.length = parseFloat(parseFloat(yacht.length).toFixed(3));
    yacht.draft = parseFloat(parseFloat(yacht.draft).toFixed(3));
    yacht.clearance = parseFloat(parseFloat(yacht.clearance).toFixed(3));
    yacht.displacement = parseFloat(parseFloat(yacht.displacement).toFixed(3));

    yacht.owner = yacht.vessel_users.find((yachtUser)=>{return yachtUser.security_role === 'ROLE_OWNER'});
    yacht.owner_id = yacht.owner?.user_id || 0;
    yacht.owner_name = yacht.owner?.name || 'No Owner Specified';
    yacht.whoami = yacht.vessel_users.find((yachtUser)=>{return parseInt(yachtUser.user_id) === parseInt(user_id) });

    if ( 'ROLE_OWNER' !== yacht.whoami.security_role && ! yacht.whoami.is_accepted )
    {
        yacht.pending = true;
    }

    yacht.is_owned_by_me = false;
    if ( 'ROLE_OWNER' === yacht.whoami.security_role ) {
        yacht.is_owned_by_me = true;
    }

    return yacht;
}

// Conversion between feet and meters
export function feetMeters(feet, meters) {
    if ( feet && ! meters )
    {
        let result = parseFloat((feet * 0.3048).toFixed(3));
        return 'undefined' !== typeof result ? result : '';
    }
    else if ( meters && ! feet )
    {
        let result = parseFloat((meters / 0.3048).toFixed(3));
        return 'undefined' !== typeof result ? result : '';
    }
    return '';
}

// Conversion between lbs and metric tons (tonnes)
export function lbsTonnes(lbs, tonnes) {
    if ( lbs && ! tonnes )
    {
        let result = (lbs / 2204.62).toFixed(3);
        return 'undefined' !== typeof result ? result : '';
    }
    else if ( tonnes && ! lbs )
    {
        let result = (tonnes * 2204.62).toFixed(3);
        return 'undefined' !== typeof result ? result : '';
    }
    return '';
}

// return valid UUID v4 string
// uses cryptography functions for true random number generation when available (97.23% of all web browsers; https://caniuse.com/?search=crypto.getRandomValues)
// falls back to Math.random() otherwise
export function uuidv4 ()
{
    if ( 'undefined' !== typeof crypto && 'function' === typeof crypto.getRandomValues) {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, function (c) {
            return (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16);
        });
    } else {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}

export function recurrence_fromPeriod (period)
{
    const types = getPropertiesByGroup('factor', 'types.time', 'type', false, true).reverse();
    const typeIDs = getPropertiesByGroup('id', 'types.time', 'type', false, true);

    let type = 'none';
    let typeId = 0;
    let interval = '0';

    let remainder;
    let test_type;

    if (!period) return null;
    if (period === 0 || period === "0") return null;
    if (period === undefined || period === null) return null;

    period = Number(Number(period).toFixed(5));

    /* javascript loop that returns as soon as condition is satisfied */
    for (let i = 0; i < types.length; i++) {
        test_type = types[i];
        let factor = Number(Number(test_type.value).toFixed(5)) || 0;
        if (factor && period >= factor && 0 === period % factor) {
            type = test_type.type;
            typeId = typeIDs.find(element => {return element.type === type})?.value || 0;
            interval = (period / factor).toString();
            break;
        }
    }

    return {typeId, interval};
}

export function processImagesInput ( targetFiles )
{
    // if targetFiles is > 15 MB return error
    let imagesTotalSize = 0;
    let errorMsg = '';

    // Use map to transform the files array into an array of promises
    const promises = Array.from(targetFiles).map(file => {
        imagesTotalSize += Math.round((file.size / 1024));
        return new Promise((resolve, reject) => {

            if ( imagesTotalSize > 15360 ) { // 15 MB
                console.warn(getLabel('PHOTOS_MAX_UPLOAD_BODY'));
                errorMsg = getLabel('PHOTOS_MAX_UPLOAD_BODY');
                reject(new Error(getLabel('PHOTOS_MAX_UPLOAD_BODY'))); // Reject the promise on error
            }

            let reader = new FileReader();

            reader.onloadend = () => {
                let pureBase64 = reader.result.replace(/^data:image\/(png|jpg|jpeg);base64,/, '');
                resolve(pureBase64); // Resolve each promise with the base64 image
            };

            reader.onerror = () => {
                console.warn(getLabel('PHOTOS_PICKER_ERROR'));
                errorMsg = getLabel('PHOTOS_PICKER_ERROR');
                reject(new Error(getLabel('PHOTOS_PICKER_ERROR'))); // Reject the promise on error
            };

            reader.readAsDataURL(file);
        });
    });

    // Use Promise.all to wait for all promises to resolve
    return Promise.all(promises).then(images => {
        // Once all promises resolve, return the images array and the total size
        return images;
    }).catch(error => {
        // Handle or log any errors that occurred during file reading
        console.error(getLabel('PHOTOS_PICKER_ERROR')+': ', error);
        return errorMsg;
    });
}

export function processAttachmentInput ( targetFile )
{
    let errorMsg = '';
    return new Promise((resolve, reject) => {
        let reader = new FileReader();

        reader.onloadend = () => {
            let pureBase64 = reader.result.replace(/^data:.*;base64,/, '');
            resolve(pureBase64); // Resolve each promise with the base64 image
        };

        reader.onerror = () => {
            console.warn(getLabel('ATTACHMENT_OPEN_ERROR'));
            errorMsg = getLabel('ATTACHMENT_OPEN_ERROR');
            reject(new Error(getLabel('ATTACHMENT_OPEN_ERROR'))); // Reject the promise on error
        };

        reader.readAsDataURL(targetFile);
    }).then(attachment => {
        return attachment;
    }).catch(error => {
        // Handle or log any errors that occurred during file reading
        console.error(getLabel('ATTACHMENT_OPEN_ERROR')+': ', error);
        return errorMsg;
    });

}

export function uploadImages ( imagesArray, object_type, object_id, vessel_id, toast, toastSuccessMessage, GLOBALS, userFunc )
{
    if ( ! imagesArray ) { return false; }
    // console.log(typeof userFunc);
    // imagesArray is an array of base64 images, calculated imagesTotalSize based upon that
    let imagesTotalSize = 0;
    imagesArray.forEach((image) => {
        imagesTotalSize += Math.round((image.length * 3/4 - 2) / 1024);
    });

    // if size is > 30 MB
    if (imagesTotalSize > 30720) {
        toast.show(getLabel('PHOTOS_MAX_UPLOAD_BODY'), {type: 'danger', duration: 10000});
        if ( 'function' === typeof userFunc ) { userFunc('error'); }
        return;
    }

    console.log('Photos:', {
        object_type: object_type,
        object_id: object_id,
        vessel_id: vessel_id,
        images: imagesArray,
    });

    fetch(GLOBALS.apiUrl, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            endpoint: 'images/add', v: GLOBALS.apiVersion,
            client_key: localStorage.getItem('clientKey'),
            device_id: localStorage.getItem('deviceID'),
            payload: {
                object_type: object_type,
                object_id: object_id,
                vessel_id: vessel_id,
                images: imagesArray,
            }
        }),
    })
    .then((response) => {
        return response.text().then(function(text) {
            const result = JSON.parse(text);
            if ( result.error )
            {
                if ( '401' === result.error ){ GLOBALS.setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                toast.show(result.error, {type: 'danger', duration:10000});
                if ( 'function' === typeof userFunc ) { userFunc('error'); }
            }
            else
            {
                toast.show(toastSuccessMessage, {type: 'success', duration:2000});
                if ( 'function' === typeof userFunc ) { userFunc('success'); }
            }
        });
    })
    .catch(error => {
        console.warn(error);
        if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
        else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
        if ( 'function' === typeof userFunc ) { userFunc('error'); }
    });
}

/**
 * Similar to uploadImages, but uploadAttachment only allows one attachment at a time, likewise caps max upload size
 */
export function uploadAttachment ( attachment, attachment_type_id, attachment_description, object_type, object_id, vessel_id, toast, toastSuccessMessage, GLOBALS, userFunc )
{
    let attachmentSize = Math.round((attachment.length * 3/4 - 2) / 1024);

    // if size is > 15 MB
    if (attachmentSize > 15360) {
        toast.show(getLabel('ATTACHMENT_TOO_LARGE_BODY'), {type: 'danger', duration: 10000});
        if ( 'function' === typeof userFunc ) { userFunc('error'); }
        return;
    }

    console.log('Attachment:', {
        object_type: object_type,
        object_id: object_id,
        vessel_id: vessel_id,
        type_id: attachment_type_id,
        description: attachment_description,
        attachment: attachment,
    });

    fetch(GLOBALS.apiUrl, {
        method: 'POST',
        cache: 'no-cache',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            endpoint: 'attachments/add', v: GLOBALS.apiVersion,
            client_key: localStorage.getItem('clientKey'),
            device_id: localStorage.getItem('deviceID'),
            payload: {
                object_type: object_type,
                object_id: object_id,
                vessel_id: vessel_id,
                attachments: [{
                    "type_id": attachment_type_id,
                    "description": attachment_description,
                    "base64": attachment,
                }]
            }
        }),
    })
    .then((response) => {
        return response.text().then(function(text) {
            const result = JSON.parse(text);
            if ( result.error )
            {
                if ( '401' === result.error ){ GLOBALS.setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                toast.show(result.error, {type: 'danger', duration:10000});
                if ( 'function' === typeof userFunc ) { userFunc('error'); }
            }
            else
            {
                toast.show(toastSuccessMessage, {type: 'success', duration:2000});
                if ( 'function' === typeof userFunc ) { userFunc('success'); }
            }
        });
    })
    .catch(error => {
        console.warn(error);
        if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:10000}); }
        else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:10000}); }
        if ( 'function' === typeof userFunc ) { userFunc('error'); }
    });

}


/**
 * [validateBasedOnRegex description]
 * @param  {[type]} value [description]
 * @param  {[type]} regex    [description]
 * @param  {[type]} required [description]
 * @return {[type]}          [description]
 */
export function validateValue ( value, regex, required )
{
    // Immediate return if the ref is not set or the value is undefined
    if ( ! value || typeof value === 'undefined') {
        return false;
    }

    // If the field is not required and is empty, it's considered valid
    if ( ! required && value === '') {
        return true;
    }

    // Initialize result
    let result = false;

    // Helper function to test a single pattern
    const testPattern = (pattern, value) => {
        const condition = new RegExp(pattern, 'g');
        return condition.test(value);
    };

    // If 'regex' is a string or RegExp instance, test the value against it
    if (typeof regex === 'string') {
        result = testPattern(regex, value);
    } else if (regex instanceof RegExp) {
        result = regex.test(value);
    } else if (typeof regex === 'object' && Array.isArray(regex)) {
        // If an array of patterns is provided, every pattern must match (logical AND)
        result = regex.every(pattern => testPattern(pattern, value));
    }

    return result;
}


export function transformArrayIntoObjects (inputArray)
{
    const headers = inputArray.map(row => row[0]);
    const numObjects = inputArray[0].length - 1;
    const result = [];

    for (let i = 0; i < numObjects; i++) {
        const obj = {};
        inputArray.forEach(row => {
            const key = row[0];
            const value = row[i + 1];
            if (key) {
                obj[key] = value;
            }
        });
        result.push(obj);
    }

    return result;
}


export function prepareImportData ( dataArray, dataType, vessel_id )
{
    let payloads = [];
    let dataObj = transformArrayIntoObjects(dataArray);

    switch ( dataType )
    {
        case 'Boat Details':
            let payload = {};
            dataObj.forEach((row, index) => {
                if ( payload.vessel_id ) { return; } // we only care about the first row
                payload.vessel_id = vessel_id;
                payload.hin = row['HIN'] || '';
                payload.year = row['Year'] || '';
                payload.manufacturer_id = row['Manufacturer'] || '';
                payload.brand_id = row['Brand'] || '';
                payload.model = row['Model'] || '';
                payload.flag_country_id = row['Country'] || '';
                payload.port = row['Port'] || '';
                payload.uscg = row['USCG Number'] || '';
                payload.local_registration = row['Registration Number'] || '';
                payload.imo = row['IMO'] || '';
                payload.mmsi = row['MMSI'] || '';
                payload.call_sign = row['Callsign'] || '';
                payload.epirb_beacon_id = row['EPIRB'] || '';
                payload.is_metric = row['Use Metric Units? (m/tons vs ft/lbs)'] || '';
                payload.beam = row['Beam'] || '';
                payload.length = row['Length'] || '';
                payload.draft = row['Draft'] || '';
                payload.clearance = row['Air Draft'] || '';
                payload.displacement = row['Displacement'] || '';
                payload.shorepower_id = row['Shorepower'] || '';
                payload.shorepower_qty_id = row['Shorepower Qty'] || '';
                payload.voltage_id = row['Voltage'] || '';
                payload.hull_type_id = row['Hull Type'] || '';
                payload.hull_material_id = row['Hull Material'] || '';

                payloads.push(payload);
            });
            break;

        case 'Mechanical':
            dataObj.forEach((row, index) => {
                let payload = {};
                payload.vessel_id = vessel_id;
                payload.type_id = row['Type (Required)'] || '';
                payload.location_id = row['Location (Required)'] || '';
                payload.manufacturer_id = row['Manufacturer'] || '';
                payload.year = row['Year'] || '';
                payload.model = row['Model'] || '';
                payload.serial = row['Serial Number'] || '';
                payload.part_no = row['Part Number'] || '';
                payload.fuel_type_id = row['Fuel Type'] || '';
                payload.horsepower = row['Horsepower'] || '';

                payloads.push(payload);
            });
            break;

        case 'Inventory':
            dataObj.forEach((row, index) => {
                let payload = {};
                payload.vessel_id = vessel_id;
                payload.name = row['Name (Required)'] || '';
                payload.type_id = row['Type (Required)'] || '';
                payload.location_id = row['Location (Required)'] || '';
                payload.position = row['Position'] || '';
                payload.quantity_required = row['Quantity Required'] || '';
                payload.quantity_on_board = row['Quantity On-hand'] || '';
                payload.part_no = row['Part Number'] || '';
                payload.url = row['URL'] || '';
                payload.notes = row['Notes'] || '';

                payloads.push(payload);
            });
            break;

        case 'Equipment':
            dataObj.forEach((row, index) => {
                let payload = {};
                payload.vessel_id = vessel_id;
                payload.type_id = row['Type (Required)'] || '';
                payload.location_id = row['Location (Required)'] || '';
                payload.manufacturer_id = row['Manufacturer (Required)'] || '';
                payload.model = row['Model (Required)'] || '';
                payload.position = row['Position'] || '';
                payload.serial = row['Serial Number'] || '';
                payload.install_date = row['Deployed Date (MM/DD/YYYY)'] || '';
                payload.notes = row['Notes'] || '';

                payloads.push(payload);
            });
            break;

        case 'Maintenance':
            dataObj.forEach((row, index) => {
                let payload = {};
                payload.vessel_id = vessel_id;
                payload.detail = row['Log Title (Required)'] || '';
                payload.type_id = row['Type (Required)'] || '';
                payload.date = row['Date (MM/DD/YYYY) (Required)'] || '';
                payload.location_id = row['Equipment Location'] || '';
                payload.hours = row['Engine Hours'] || '';
                payload.notes = row['Notes'] || '';

                payloads.push(payload);
            });
            break;

        case 'Tasks':
            dataObj.forEach((row, index) => {
                let payload = {};
                payload.vessel_id = vessel_id;
                payload.name = row['Name (Required)'] || '';
                payload.due_date = row['Due Date (MM/DD/YYYY) (Defaults to Today)'] || now_MMDDYYYY();
                payload.priority_id = row['Priority (Low, Medium, High, Critical) (Defaults to Medium)'] || 'Medium';
                payload.details = row['Details'] || '';
                payload.due_hours = row['Due Hours'] || '';
                payload.is_recurring = row['Recurring? (Yes, No) (Defaults to No)'] || 'No';
                payload.recurrence_interval = row['Recurring Inteval (1-99)'] || '';
                payload.recurrence_type_id = row['Recurring Interval Type (Hour(s), Day(s), Week(s), Month(s), Year(s))'] || '';

                payloads.push(payload);
            });
            break;

        case 'Notes':
            dataObj.forEach((row, index) => {
                let payload = {};
                payload.vessel_id = vessel_id;
                payload.name = row['Name (Required)'] || '';
                payload.type_id = row['Type (Required)'] || '';
                payload.content = row['Details'] || '';

                payloads.push(payload);
            });
            break;
    }

    return payloads;
}


/**
 * receive a json payload, and a resource type, and push data to the API server
 */
export function importData ( payloads, resourceType, toast, toastSuccessMessage, GLOBALS, userFunc )
{
    let failures = 0;
    let processed = 0;

    const promises = payloads.map((payload) => {

        if ( failures > 0 ) { return; }

        let action = 'add';

        // loop through each payload value and sanitize string
        for (const [key, value] of Object.entries(payload)) {
            // set value as string
            let stringValue = '' + value;

            stringValue = stringValue.trim();

            // strip out all non-ascii characters
            stringValue = stringValue.replace(/[^\S\r\n ]+/g, "");

            // strip out all html tag characters
            stringValue = stringValue.replace(/<\/?[^>]+(>|$)/g, "");

            // strip out all html entity characters
            stringValue = stringValue.replace(/&[a-z]+;/g, "");

            // if value is a number, cast it to an int or float
            /*if (typeof stringValue === "string" && stringValue.length > 0 && !isNaN(stringValue)) {
                payload[key] = +stringValue;
            } else {
                payload[key] = stringValue;
            }*/
        }

        // check for required fields depending on resourceType
        // certain recourceType fields can accept a string or ID, if a string is passed, convert to ID
        switch ( resourceType )
        {
            case 'vessels':
                // we'll only be using import to update an existing vessel, so we need to ensure the vessel ID is set, and force our action to "update"
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                payload.id = payload.vessel_id;
                delete payload.vessel_id;
                action = 'update';

                // remove all alpha characters from length, beam, draft, clearance, and displacement
                if ( payload.length && 'string' === typeof payload.length ) { payload.length = payload.length.replace(/[^0-9.]/g, ''); }
                if ( payload.beam && 'string' === typeof payload.beam ) { payload.beam = payload.beam.replace(/[^0-9.]/g, ''); }
                if ( payload.draft && 'string' === typeof payload.draft ) { payload.draft = payload.draft.replace(/[^0-9.]/g, ''); }
                if ( payload.clearance && 'string' === typeof payload.clearance ) { payload.clearance = payload.clearance.replace(/[^0-9.]/g, ''); }
                if ( payload.displacement && 'string' === typeof payload.displacement ) { payload.displacement = payload.displacement.replace(/[^0-9.]/g, ''); }

                // if the following values are set, ensure they meet their regex requirements
                if ( payload.year && ! validateValue( payload.year, "^(?:(?:19|20)[0-9]{2})$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('YEAR'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.model && ! validateValue( payload.model, "^.{2,}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MODEL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.length && ! validateValue( payload.length, "^([0-9]*[.])?[0-9]+$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('LENGTH'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.beam && ! validateValue( payload.beam, "^([0-9]*[.])?[0-9]+$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('BEAM'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.draft && ! validateValue( payload.draft, "^([0-9]*[.])?[0-9]+$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('DRAFT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.clearance && ! validateValue( payload.clearance, "^([0-9]*[.])?[0-9]+$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('CLEARANCE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.displacement && ! validateValue( payload.displacement, "^([0-9]*[.])?[0-9]+$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('DISPLACEMENT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.port && ! validateValue( payload.port, "^.{2,}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('PORT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.uscg && ! validateValue( payload.uscg, RegExHelper.validators.uscg ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('USCG'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.local_registration && ! validateValue( payload.local_registration, RegExHelper.validators.registration ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('LOCAL_REGISTRATION'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.imo && ! validateValue( payload.imo, RegExHelper.validators.imo ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('IMO'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.mmsi && ! validateValue( payload.mmsi, RegExHelper.validators.mmsi ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MMSI'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.call_sign && ! validateValue( payload.call_sign, RegExHelper.validators.callsign ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('CALL_SIGN'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.epirb_beacon_id && ! validateValue( payload.epirb_beacon_id, RegExHelper.validators.eprib ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EPIRB'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.hin && ! validateValue( payload.hin, "^.{2,}$" /*RegExHelper.validators.hin*/ ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('HIN'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if the following values are set, convert to the ID using getOptionsByLabelGroup to find the ID
                if ( payload.manufacturer_id ) {
                    let found_manufacturer_id = getOptionsByLabelGroup('data.vessel.manufacturers', true).find(obj => {return obj.label.toLowerCase() === payload.manufacturer_id.toLowerCase()})?.value || false;
                    if ( found_manufacturer_id ) { payload.manufacturer_id = found_manufacturer_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('MANUFACTURER')).replace('%value%', payload.manufacturer_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.brand_id ) {
                    let found_brand_id = getOptionsByLabelGroup('data.vessel.brands', true).find(obj => {return obj.label.toLowerCase() === payload.brand_id.toLowerCase()})?.value || false;
                    if ( found_brand_id ) { payload.brand_id = found_brand_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('BRAND')).replace('%value%', payload.brand_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.flag_country_id ) {
                    let found_flag_country_id = getOptionsByLabelGroup('data.countries', true).find(obj => {return obj.label.toLowerCase() === payload.flag_country_id.toLowerCase()})?.value || false;
                    if ( found_flag_country_id ) { payload.flag_country_id = found_flag_country_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('COUNTRY')).replace('%value%', payload.flag_country_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.shorepower_id ) {
                    let found_shorepower_id = getOptionsByLabelGroup('types.shorepower', true).find(obj => {return obj.label.toLowerCase() === payload.shorepower_id.toLowerCase()})?.value || false;
                    if ( found_shorepower_id ) { payload.shorepower_id = found_shorepower_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('SHOREPOWER')).replace('%value%', payload.shorepower_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.shorepower_qty_id ) {
                    let found_shorepower_qty_id = getOptionsByLabelGroup('types.shorepower.quantity', true).find(obj => {return obj.label.toLowerCase() === payload.shorepower_qty_id.toLowerCase()})?.value || false;
                    if ( found_shorepower_qty_id ) { payload.shorepower_qty_id = found_shorepower_qty_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('SHOREPOWER')+' '+getLabel('QUANTITY_SHORT')).replace('%value%', payload.shorepower_qty_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.voltage_id ) {
                    let found_voltage_id = getOptionsByLabelGroup('types.voltage', true).find(obj => {return obj.label.toLowerCase() === payload.voltage_id.toLowerCase()})?.value || false;
                    if ( found_voltage_id ) { payload.voltage_id = found_voltage_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('VOLTAGE')).replace('%value%', payload.voltage_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.hull_type_id ) {
                    let found_hull_type_id = getOptionsByLabelGroup('types.hull.types', true).find(obj => {return obj.label.toLowerCase() === payload.hull_type_id.toLowerCase()})?.value || false;
                    if ( found_hull_type_id ) { payload.hull_type_id = found_hull_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('HULL_TYPE')).replace('%value%', payload.hull_type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.hull_material_id ) {
                    let found_hull_material_id = getOptionsByLabelGroup('types.hull.materials', true).find(obj => {return obj.label.toLowerCase() === payload.hull_material_id.toLowerCase()})?.value || false;
                    if ( found_hull_material_id ) { payload.hull_material_id = found_hull_material_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('HULL_MATERIAL')).replace('%value%', payload.hull_material_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.is_metric = payload.is_metric ? payload.is_metric.toLowerCase() === 'yes' : false;

                // get current vessel details by payload.id
                let yachts = localStorage.getItem( 'yachts' );
                if ( ! yachts ) { return false; }
                yachts = JSON.parse(yachts);
                let vessel = yachts.find((vessel) => { return parseInt(vessel.id) === parseInt(payload.id) });
                payload.name = vessel.name;
                payload.type_id = vessel.type_id;
                payload.status_id = vessel.status_id;
                payload.initials = getInitials(vessel.name);
                payload.description = null;
                payload.date_acquired = vessel.date_acquired || now_YYYYMMDD();
                break;

            case 'tasks':
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check required: name, priority_id, due_date
                if ( ! payload.name ) { toast.show(getLabel('REQUEST') +' '+ getLabel('TASK') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.due_date ) { toast.show(getLabel('REQUEST') +' '+ getLabel('TASK') +' '+ getLabel('DUE_DATE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.priority_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('TASK') +' '+ getLabel('PRIORITY'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if due_date is MM/DD/YY, convert it to MM/DD/YYYY
                if ( payload.due_date && payload.due_date.match(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]\d{2}$/) ) {
                    payload.due_date = MMDDYY_to_MMDDYYYY(payload.due_date);
                }

                // check if required fields are valid
                if ( ! validateValue( payload.name, "^.{2,}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('TASK') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! validateValue( payload.due_date, "^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('TASK') +' '+ getLabel('DUE_DATE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if the following values are set, ensure they're valid
                if ( payload.details && ! validateValue( payload.details, "^.{2,}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('TASK') +' '+ getLabel('DETAILS'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.due_hours && ! validateValue( payload.due_hours, "^[0-9]*$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('TASK') +' '+ getLabel('DUE_HOURS'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if the following values are set, convert to the ID using getOptionsByLabelGroup to find the ID
                // priority_id
                const priorityOptions = getPropertiesByGroup('id', 'types.task.priorities', 'label', false, true);
                if ( payload.priority_id ) {
                    let found_priority_id = priorityOptions.find(obj => {return obj.label.toLowerCase() === payload.priority_id.toLowerCase()})?.value || false;
                    if ( found_priority_id ) { payload.priority_id = found_priority_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('PRIORITY')).replace('%value%', payload.priority_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // format recurrence if necessary
                if ( payload.recurrence_type_id ) {
                    const mapping = {
                        "day(s)": "day(s)",
                        day: "day(s)",
                        days: "day(s)",
                        "week(s)": "week(s)",
                        week: "week(s)",
                        weeks: "week(s)",
                        "hour(s)": "hour(s)",
                        hour: "hour(s)",
                        hours: "hour(s)",
                        "month(s)": "month(s)",
                        month: "month(s)",
                        months: "month(s)",
                        "year(s)": "year(s)",
                        year: "year(s)",
                        years: "year(s)"
                    };
                    payload.recurrence_type_id = mapping[payload.recurrence_type_id.toLowerCase()];

                    let found_recurrence_type_id = getOptionsByLabelGroup('types.time', true).filter(option => option.value !== 1 && option.value !== 2).find(obj => {return obj.label.toLowerCase() === payload.recurrence_type_id.toLowerCase()})?.value || false;
                    if ( found_recurrence_type_id ) { payload.recurrence_type_id = found_recurrence_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('RECURRING_INTERVAL_TYPE')).replace('%value%', payload.recurrence_type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.assignee_user_id = parseInt(GLOBALS.userID);
                payload.due_date = MMDDYYYY_to_YYYYMMDD(payload.due_date);
                payload.is_complete = 0;
                payload.due_hours = payload.due_hours ? parseInt(payload.due_hours) : null;

                // calculate recurring fields
                const recurrenceTypeFactor = getPropertiesByGroup('value', 'types.time', 'factor', false, true);
                const recurrence_days = payload.recurrence_interval ? recurrenceTypeFactor.find(obj => {return obj.value == payload.recurrence_type_id})?.factor * parseInt(payload.recurrence_interval) : 0;
                delete payload.recurrence_interval;
                delete payload.recurrence_type_id;

                payload.is_recurring = payload.is_recurring ? ( payload.is_recurring.toLowerCase() === 'yes' ? 1 : 0) : 0;
                payload.recurrence_days = recurrence_days;
                payload.is_recurrence_locked = 0;
                break;

            case 'engines':
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check required:
                // type_id, location_id
                if ( ! payload.type_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('ENGINE') +' '+ getLabel('TYPE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.location_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('ENGINE') +' '+ getLabel('LOCATION'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if non-required fields are set, check that they're valid:
                // part_no, year, model, serial, horsepower
                if ( payload.year && ! validateValue( payload.year, "^(19|20)[0-9]{2}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('ENGINE') +' '+ getLabel('YEAR'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.model && ! validateValue( payload.model, RegExHelper.validators.model_no ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('ENGINE') +' '+ getLabel('MODEL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.serial && ! validateValue( payload.serial, RegExHelper.validators.serial_no ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('ENGINE') +' '+ getLabel('SERIAL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.horsepower && ! validateValue( payload.horsepower, "^([0-9]*)$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('ENGINE') +' '+ getLabel('HORSEPOWER'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.part_no && ! validateValue( payload.part_no, RegExHelper.validators.part_no ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('ENGINE') +' '+ getLabel('PART_NO'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if picker values are set, convert to the ID using getOptionsByLabelGroup to find the ID:
                // manufacturer_id, fuel_type_id, location_id, type_id
                if ( payload.manufacturer_id ) {
                    let found_manufacturer_id = getOptionsByLabelGroup('data.engine.manufacturers', true).find(obj => {return obj.label.toLowerCase() === payload.manufacturer_id.toLowerCase()})?.value || false;
                    if ( found_manufacturer_id ) { payload.manufacturer_id = found_manufacturer_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('ENGINE') +' '+ getLabel('MANUFACTURER')).replace('%value%', payload.manufacturer_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.fuel_type_id ) {
                    let found_fuel_type_id = getOptionsByLabelGroup('types.engine.fuel.types', true).find(obj => {return obj.label.toLowerCase() === payload.fuel_type_id.toLowerCase()})?.value || false;
                    if ( found_fuel_type_id ) { payload.fuel_type_id = found_fuel_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('ENGINE') +' '+ getLabel('FUEL_TYPE')).replace('%value%', payload.fuel_type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.location_id ) {
                    let found_location_id = getOptionsByLabelGroup('types.vessel.locations', true).find(obj => {return obj.label.toLowerCase() === payload.location_id.toLowerCase()})?.value || false;
                    if ( found_location_id ) { payload.location_id = found_location_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('ENGINE') +' '+ getLabel('LOCATION')).replace('%value%', payload.location_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.type_id ) {
                    let found_type_id = getOptionsByLabelGroup('types.engine.types', true).find(obj => {return obj.label.toLowerCase() === payload.type_id.toLowerCase()})?.value || false;
                    if ( found_type_id ) { payload.type_id = found_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('ENGINE') +' '+ getLabel('TYPE')).replace('%value%', payload.type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.is_active = 1;
                break;

            case 'inventory':
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check required:
                // name, type_id, location_id
                if ( ! payload.name ) { toast.show(getLabel('REQUEST') +' '+ getLabel('INVENTORY') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.type_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('INVENTORY') +' '+ getLabel('TYPE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.location_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('INVENTORY') +' '+ getLabel('LOCATION'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check if required fields are valid:
                // name
                if ( ! validateValue( payload.name, "^.{2,}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if non-required fields are set, check that they're valid:
                // quantity_required, quantity_on_board, part_no, position, notes, url
                if ( payload.quantity_required && ! validateValue( payload.quantity_required, "^[0-9]+(\.[0-9]+)?$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('QUANTITY') +' '+ getLabel('QTY_REQUIRED'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.quantity_on_board && ! validateValue( payload.quantity_on_board, "^[0-9]+(\.[0-9]+)?$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('QUANTITY') +' '+ getLabel('QTY_ON_HAND'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.part_no && ! validateValue( payload.part_no, RegExHelper.validators.part_no ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('PART'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                /* if position is > 50 characters */
                if ( payload.position && payload.position.length > 50 ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('POSITION') +', '+ getLabel('MAX_CHARS').replace('%size%', '50'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.position && ! validateValue( payload.position, "^.{2,50}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('POSITION'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                /* if notes is > 500 characters */
                if ( payload.notes && payload.notes.length > 500 ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('NOTES') +', '+ getLabel('MAX_CHARS').replace('%size%', '500'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.notes && ! validateValue( payload.notes, "^.{0,500}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('NOTES'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.url && ! validateValue( payload.url, "^(|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('INVENTORY') +' '+ getLabel('URL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }


                // if picker values are set, convert to the ID using getOptionsByLabelGroup to find the ID:
                // type_id, location_id
                if ( payload.type_id ) {
                    let found_type_id = getOptionsByLabelGroup('types.inventory.types', true).find(obj => {return obj.label.toLowerCase() === payload.type_id.toLowerCase()})?.value || false;
                    if ( found_type_id ) { payload.type_id = found_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('INVENTORY') +' '+ getLabel('TYPE')).replace('%value%', payload.type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.location_id ) {
                    let found_location_id = getOptionsByLabelGroup('types.inventory.locations', true).find(obj => {return obj.label.toLowerCase() === payload.location_id.toLowerCase()})?.value || false;
                    if ( found_location_id ) { payload.location_id = found_location_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('INVENTORY') +' '+ getLabel('LOCATION')).replace('%value%', payload.location_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.quantity_required = String(payload.quantity_required || 0);
                payload.quantity_on_board = String(payload.quantity_on_board || 0);
                break;

            case 'equipment':
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check required:
                // type_id, manufacturer_id, location_id, model
                if ( ! payload.type_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('TYPE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.manufacturer_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('MANUFACTURER'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.location_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('LOCATION'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.model ) { toast.show(getLabel('REQUEST') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('MODEL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check if required fields are valid:
                // model
                if ( ! validateValue( payload.model, "^.{2,}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('MODEL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if non-required fields are set, check that they're valid:
                // position, serial, notes, install_date
                /* if notes is > 500 characters */
                if ( payload.position && payload.position.length > 50 ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('POSITION') +', '+ getLabel('MAX_CHARS').replace('%size%', '50'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.position && ! validateValue( payload.position, "^.{2,50}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('POSITION'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.serial && ! validateValue( payload.serial, RegExHelper.validators.serial_no ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('SERIAL'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                /* if notes is > 500 characters */
                if ( payload.notes && payload.notes.length > 500 ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('NOTES') +', '+ getLabel('MAX_CHARS').replace('%size%', '500'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.notes && ! validateValue( payload.notes, "^.{0,500}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('NOTES'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if install_date is MM/DD/YY, convert it to MM/DD/YYYY
                if ( payload.install_date && payload.install_date.match(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]\d{2}$/) ) {
                    payload.install_date = MMDDYY_to_MMDDYYYY(payload.install_date);
                }
                if ( payload.install_date && ! validateValue( payload.install_date, "^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('EQUIPMENT') +' '+ getLabel('DEPLOYED') +' '+ getLabel('DATE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if picker values are set, convert to the ID using getOptionsByLabelGroup to find the ID:
                // type_id, manufacturer_id, location_id
                if ( payload.type_id ) {
                    let found_type_id = getOptionsByLabelGroup('types.equipment.types', true).find(obj => {return obj.label.toLowerCase() === payload.type_id.toLowerCase()})?.value || false;
                    if ( found_type_id ) { payload.type_id = found_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('EQUIPMENT') +' '+ getLabel('TYPE')).replace('%value%', payload.type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.manufacturer_id ) {
                    let found_manufacturer_id = getOptionsByLabelGroup('data.equipment.manufacturers', true).find(obj => {return obj.label.toLowerCase() === payload.manufacturer_id.toLowerCase()})?.value || false;
                    if ( found_manufacturer_id ) { payload.manufacturer_id = found_manufacturer_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('EQUIPMENT') +' '+ getLabel('MANUFACTURER')).replace('%value%', payload.manufacturer_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.location_id ) {
                    let found_location_id = getOptionsByLabelGroup('types.inventory.locations', true).find(obj => {return obj.label.toLowerCase() === payload.location_id.toLowerCase()})?.value || false;
                    if ( found_location_id ) { payload.location_id = found_location_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('EQUIPMENT') +' '+ getLabel('LOCATION')).replace('%value%', payload.location_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.install_date = payload.install_date ? MMDDYYYY_to_YYYYMMDD(payload.install_date) : null;

                break;

            case 'logs/maintenance':
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check required:
                // type_id, date, detail
                if ( ! payload.type_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('TYPE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.date ) { toast.show(getLabel('REQUEST') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('DATE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.detail ) { toast.show(getLabel('REQUEST') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check if required fields are valid:
                // date, detail
                // if date is MM/DD/YY, convert it to MM/DD/YYYY
                if ( payload.date && payload.date.match(/^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.]\d{2}$/) ) {
                    payload.date = MMDDYY_to_MMDDYYYY(payload.date);
                }
                if ( ! validateValue( payload.date, "^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)[0-9]{2}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('DATE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! validateValue( payload.detail, "^[\\w\\d\\s]{1,36}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if non-required fields are set, check that they're valid:
                // hours, notes
                if ( payload.hours && ! validateValue( payload.hours, "^[0-9]+(\.[0-9]+)?$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('HOURS'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                /* if notes is > 500 characters */
                if ( payload.notes && payload.notes.length > 500 ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('NOTES') +', '+ getLabel('MAX_CHARS').replace('%size%', '500'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.notes && ! validateValue( payload.notes, "^.{0,500}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('MAINTENANCE') +' '+ getLabel('NOTES'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if picker values are set, convert to the ID using getOptionsByLabelGroup to find the ID:
                // type_id, location_id
                if ( payload.type_id ) {
                    let found_type_id = getOptionsByLabelGroup('types.maintenance.types', true).find(obj => {return obj.label.toLowerCase() === payload.type_id.toLowerCase()})?.value || false;
                    if ( found_type_id ) { payload.type_id = found_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('MAINTENANCE') +' '+ getLabel('TYPE')).replace('%value%', payload.type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }
                if ( payload.location_id ) {
                    let found_location_id = getOptionsByLabelGroup('types.inventory.locations', true).find(obj => {return obj.label.toLowerCase() === payload.location_id.toLowerCase()})?.value || false;
                    if ( found_location_id ) { payload.location_id = found_location_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('MAINTENANCE') +' '+ getLabel('LOCATION')).replace('%value%', payload.location_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.location_id = payload.location_id || 0;
                payload.mechanical_id = 0;
                payload.equipment_id = 0;
                payload.date = MMDDYYYY_to_YYYYMMDD(payload.date);
                break;

            case 'notes':
                if ( ! payload.vessel_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('YACHT'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check required:
                // name, type_id
                if ( ! payload.name ) { toast.show(getLabel('REQUEST') +' '+ getLabel('NOTE') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( ! payload.type_id ) { toast.show(getLabel('REQUEST') +' '+ getLabel('NOTE') +' '+ getLabel('TYPE'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // check if required fields are valid:
                // name
                if ( ! validateValue( payload.name, "^.{2,}$", true ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('NOTE') +' '+ getLabel('NAME'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if non-required fields are set, check that they're valid:
                // content
                /* if content is > 500 characters */
                if ( payload.content && payload.content.length > 500 ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('NOTE') +' '+ getLabel('DETAILS') +', '+ getLabel('MAX_CHARS').replace('%size%', '500'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                if ( payload.content && ! validateValue( payload.content, "^.{0,500}$" ) ) { toast.show(getLabel('REQUEST_VALID') +' '+ getLabel('NOTE') +' '+ getLabel('DETAILS'), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }

                // if picker values are set, convert to the ID using getOptionsByLabelGroup to find the ID:
                // type_id
                if ( payload.type_id ) {
                    let found_type_id = getOptionsByLabelGroup('types.note.types', true).find(obj => {return obj.label.toLowerCase() === payload.type_id.toLowerCase()})?.value || false;
                    if ( found_type_id ) { payload.type_id = found_type_id; } else { toast.show(getLabel('IMPORT_FIELD_INVALID').replace(/%field%/g, getLabel('NOTE') +' '+ getLabel('TYPE')).replace('%value%', payload.type_id), {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); if ( 'function' === typeof userFunc ) { userFunc('error'); } failures++; return; }
                }

                // prepare format-specific fields
                payload.private = false;
                break;
        }

        return fetch(GLOBALS.apiUrl, {
            method: 'POST',
            cache: 'no-cache',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                endpoint: resourceType+'/'+action, v: GLOBALS.apiVersion,
                client_key: localStorage.getItem('clientKey'),
                device_id: localStorage.getItem('deviceID'),
                payload: payload
            }),
        })
        .then(response => response.text())
        .then(text => {
            const result = JSON.parse(text);
            if ( result.error )
            {
                failures++;
                if ( '401' === result.error ){ GLOBALS.setLoggedIn(false); result.error = 'Your session token has expired, please log in again.'; }
                toast.show(result.error, {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000});
                if ( 'function' === typeof userFunc ) { userFunc('error'); }
            }
            else {
                processed++;
            }
        })
        .catch(error => {
            console.warn(error);
            failures++;
            if ( ! window.navigator.onLine ) { toast.show('Internet connection failed. Please try again.', {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); }
            else { toast.show('An error ocurred. See console log.', {type: 'danger', duration:failures > 0 && processed > 0 ? 15000 : 10000}); }
            if ( 'function' === typeof userFunc ) { userFunc('error'); }
        });
    });

    Promise.all(promises).then(() => {
        if (failures < 1) {
            toast.show(toastSuccessMessage, { type: 'success', duration: 10000 });
            if (typeof userFunc === 'function') { userFunc('success'); }
        } else if (failures > 0 && processed > 0) {
            toast.show(getLabel('IMPORT_PARTIAL_SUCCESS'), { type: 'warning', duration: 10000 });
        }
    });
}


export function canIhazIcon ( icon )
{
    let existing_icons = [
        'account',
        'add',
        'add user',
        'Afghanistan',
        'agreement',
        'agreement_gray',
        'agreement_white',
        'Albania',
        'Algeria',
        'anchor',
        'anchor_gray',
        'anchor_white',
        'Andorra',
        'Angola',
        'anodes',
        'anodes_gray',
        'anodes_white',
        'antenna',
        'antenna_gray',
        'antenna_white',
        'appliance_blue',
        'appliance_gray',
        'appliance_white',
        'Argentina',
        'Armenia',
        'arrival',
        'arrival time',
        'arrow_forward_ios',
        'arrow_organic',
        'attachment',
        'attachment_gray',
        'attachment_lightgray',
        'attachment_white',
        'audio',
        'audio_gray',
        'audio_white',
        'Australia',
        'Austria',
        'aux_equipment_blue',
        'aux_equipment_gray',
        'aux_equipment_white',
        'available-on-official-black-android',
        'available-on-official-black-ios',
        'aviation',
        'aviation_gray',
        'aviation_white',
        'Azerbaijan',
        'back',
        'back_white',
        'Bahamas',
        'Bahrain',
        'Bangladesh',
        'Barbados',
        'beam',
        'beam_gray',
        'Belarus',
        'Belgium',
        'Belize',
        'Benin',
        'Bhutan',
        'big_add',
        'big_alert',
        'BlankFlag',
        'blizzard',
        'Bolivia',
        'Botswana',
        'brand',
        'brand_gray',
        'Brazil',
        'Brunei',
        'Bulgaria',
        'Burkina Faso',
        'Burundi',
        'calendar_blue',
        'calendar_gray',
        'Cambodia',
        'camera',
        'camera_gray',
        'camera_white',
        'Cameroon',
        'Canada',
        'Cape Verde',
        'Chad',
        'checkbox',
        'checkbox_active',
        'checklist',
        'checklist_gray',
        'checklist_lightGray',
        'checklist_white',
        'chevron_down_blue',
        'chevron_down_white',
        'chevron_right',
        'Chile',
        'China',
        'clear',
        'clearance_blue',
        'clearance_gray',
        'close',
        'cloudy',
        'code',
        'code_gray',
        'code_white',
        'Colombia',
        'Comoros',
        'compass',
        'compass_gray',
        'compass_white',
        'computer',
        'computer_gray',
        'computer_white',
        'construction_blue',
        'construction_gray',
        'construction_white',
        'contact',
        'contact_gray',
        'contact_white',
        'Costa Rica',
        'Croatia',
        'cruise log',
        'cruise_gray',
        'Cuba',
        'Cyprus',
        'Czech Republic',
        'danger',
        'dark mode',
        'date',
        'date acquired',
        'deck_equipment_blue',
        'deck_equipment_gray',
        'deck_equipment_white',
        'delete',
        'delete account',
        'delete account_red',
        'delete_gray',
        'delete_lightgray',
        'delete_red',
        'delete_white',
        'Denmark',
        'departure',
        'departure time',
        'departure_gray',
        'description',
        'description_gray',
        'description_white',
        'dimensions',
        'dimensions 2',
        'displacement',
        'displacement_gray',
        'Djibouti',
        'Dominica',
        'Dominican Republic',
        'draft',
        'draft_gray',
        'dragdots',
        'drivetrain',
        'drivetrain_gray',
        'drivetrain_white',
        'dropdown',
        'Ecuador',
        'edit',
        'edit_blue',
        'edit_green',
        'edit_white',
        'Egypt',
        'El Salvador',
        'electrical 2',
        'electrical_blue',
        'electrical_gray',
        'electronics',
        'electronics_gray',
        'electronics_white',
        'email',
        'email_blue',
        'email_gray',
        'engine',
        'engine_gray',
        'engine_white',
        'engineAlt',
        'enginehours_blue',
        'enginehours_gray',
        'enginePower_blue',
        'enginePower_gray',
        'enginePower_lightGray',
        'enginePower_white',
        'epirb',
        'epirb_gray',
        'epirb_white',
        'Equatorial Guinea',
        'equipment',
        'equipment_gray',
        'equipment_lightgray',
        'equipment_white',
        'Eritrea',
        'error',
        'Estonia',
        'Ethiopia',
        'export',
        'export_gray',
        'export_lightgray',
        'export_white',
        'eye',
        'Fiji',
        'fine',
        'fine_gray',
        'fine_white',
        'Finland',
        'fishing_blue',
        'fishing_gray',
        'fishing_white',
        'flag',
        'flag_gray',
        'flooring',
        'flooring_gray',
        'flooring_white',
        'fog',
        'food',
        'food_gray',
        'food_white',
        'France',
        'fuel',
        'fuel_gray',
        'furniture',
        'furniture_gray',
        'furniture_white',
        'Gabon',
        'Gambia',
        'general',
        'generalinfo',
        'generalinfo_gray',
        'generalinfo_white',
        'generator',
        'generator_gray',
        'generator_white',
        'Georgia',
        'Germany',
        'Ghana',
        'Greece',
        'Grenada',
        'Guatemala',
        'Guinea',
        'Guinea-Bissau',
        'Guyana',
        'Haiti',
        'hardware',
        'hardware_gray',
        'hardware_white',
        'heavy rain',
        'high-voltage',
        'high-voltage_gray',
        'high-voltage_white',
        'high_voltage',
        'high_voltage_gray',
        'high_voltage_white',
        'hin',
        'hin_gray',
        'hin_white',
        'home',
        'Honduras',
        'hull',
        'hull type',
        'hull type_gray',
        'hull_gray',
        'hull_white',
        'Hungary',
        'hurricane',
        'hvac',
        'hvac_gray',
        'hvac_white',
        'Iceland',
        'India',
        'Indonesia',
        'inflatable',
        'inflatable_gray',
        'inflatable_white',
        'initials',
        'insurance',
        'insurance_gray',
        'insurance_white',
        'inventory',
        'inventory type',
        'inventory_gray',
        'invtype_blue',
        'invtype_gray',
        'ios_share',
        'Iran',
        'Iraq',
        'Ireland',
        'Israel',
        'Italy',
        'Ivory Coast',
        'Jamaica',
        'Japan',
        'Jordan',
        'Kazakhstan',
        'Kenya',
        'key_blue',
        'key_gray',
        'key_white',
        'keyboard_arrow_down',
        'keyboard_arrow_up',
        'Kiribati',
        'Kuwait',
        'Kyrgyzstan',
        'language',
        'Laos',
        'Latvia',
        'Lebanon',
        'lenght',
        'lenght_gray',
        'Lesotho',
        'Liberia',
        'Libya',
        'license',
        'license_gray',
        'license_white',
        'Liechtenstein',
        'light rain',
        'lighting',
        'lighting_gray',
        'lighting_white',
        'Lithuania',
        'loader',
        'location',
        'location-1',
        'location_blue',
        'location_gray',
        'lock',
        'Logo',
        'LogoHorizontal',
        'logout',
        'LogoWhite',
        'LogoWhiteHorizontal',
        'logs',
        'low-voltage',
        'low-voltage_gray',
        'low-voltage_white',
        'low_voltage',
        'low_voltage_gray',
        'low_voltage_white',
        'Luxembourg',
        'Macedonia',
        'Madagascar',
        'maintenance log',
        'maintenance_blue',
        'maintenance_gray',
        'maintenanceType_blue',
        'maintenanceType_gray',
        'maintenanceType_white',
        'Malawi',
        'Malaysia',
        'Maldives',
        'Mali',
        'Malta',
        'manual',
        'manual_gray',
        'manual_white',
        'manufacturer',
        'manufacturer_gray',
        'mark_complete',
        'Marshall Islands',
        'material',
        'material_gray',
        'Mauritania',
        'Mauritius',
        'mechanical',
        'mechanical_gray',
        'mechanical_white',
        'Mexico',
        'Micronesia',
        'mime-type',
        'mime-type_gray',
        'mime-type_white',
        'misc',
        'misc_gray',
        'misc_white',
        'miscellaneous',
        'miscellaneous_gray',
        'miscellaneous_white',
        'misty',
        'model',
        'model_gray',
        'moderate rain',
        'Moldova',
        'Monaco',
        'Mongolia',
        'Montenegro',
        'more_vert',
        'more_vert_black',
        'Morocco',
        'mostly cloudy',
        'Mozambique',
        'Myanmar',
        'name',
        'name_blue',
        'name_gray',
        'Namibia',
        'Nauru',
        'Nepal',
        'Netherlands',
        'network',
        'network_gray',
        'network_white',
        'New Zealand',
        'Nicaragua',
        'Niger',
        'Nigeria',
        'North Korea',
        'Norway',
        'notes',
        'notes_blue',
        'notes_gray',
        'notes_lightgray',
        'notes_white',
        'notifications',
        'notifications_gray',
        'notify',
        'Oman',
        'onhand_blue',
        'onhand_gray',
        'other',
        'other_gray',
        'other_white',
        'overcast',
        'padlock',
        'Pakistan',
        'Palau',
        'Panama',
        'Papua New Guinea',
        'Paraguay',
        'part',
        'part_gray',
        'partly cloudy',
        'password',
        'password_gray',
        'password_white',
        'permissions',
        'Peru',
        'Philippines',
        'phone',
        'phone_gray',
        'phone_white',
        'photos',
        'photos_gray',
        'photos_lightgray',
        'photos_white',
        'pin_drop',
        'pin_drop_blue',
        'play_arrow',
        'plumbing',
        'plumbing_gray',
        'plumbing_white',
        'Poland',
        'Portugal',
        'private',
        'propulsion_blue',
        'propulsion_gray',
        'propulsion_white',
        'public',
        'pump',
        'pump_gray',
        'pump_white',
        'Qatar',
        'quantity needed',
        'quantity on hand',
        'quantity_blue',
        'quantity_gray',
        'question',
        'radio',
        'radio_gray',
        'radio_white',
        'radioButton',
        'radioButton_active',
        'receipt',
        'receipt_gray',
        'receipt_white',
        'recurring',
        'recurring_black',
        'recurring_gray',
        'recurring_white',
        'registration',
        'registration_gray',
        'registration_white',
        'reorder',
        'rigging',
        'rigging_gray',
        'rigging_white',
        'Romania',
        'rss',
        'Russia',
        'Rwanda',
        'safety-security_blue',
        'safety-security_gray',
        'safety-security_white',
        'safety_blue',
        'safety_gray',
        'safety_security_blue',
        'safety_security_gray',
        'safety_security_white',
        'safety_white',
        'sailing',
        'sailing_gray',
        'sailing_white',
        'sails',
        'sails_gray',
        'sails_white',
        'Saint Kitts and Nevis',
        'Saint Lucia',
        'Saint Vincent and the Grenadines',
        'Samoa',
        'San Marino',
        'Sao Tome and Principe',
        'Saudi Arabia',
        'search',
        'search_gray',
        'secure',
        'secure_gray',
        'secure_lightgray',
        'secure_mediumgray',
        'secure_white',
        'security_blue',
        'security_gray',
        'security_white',
        'Senegal',
        'Serbia',
        'serial',
        'serial_gray',
        'serial_white',
        'setting_blue',
        'setting_gray',
        'Seychelles',
        'share empty',
        'share_blue',
        'shared',
        'shared_gray',
        'shares',
        'shorepower',
        'shorepower_gray',
        'Sierra Leone',
        'Singapore',
        'size',
        'size_gray',
        'size_white',
        'Slovakia',
        'snow',
        'solar_blue',
        'solar_gray',
        'solar_white',
        'Solomon Islands',
        'Somalia',
        'sort',
        'South Africa',
        'South Korea',
        'South Sudan',
        'Spain',
        'specifications',
        'squall',
        'Sri Lanka',
        'stabilizer_blue',
        'stabilizer_gray',
        'stabilizer_white',
        'status',
        'status_default',
        'status_disabled',
        'status_gray',
        'status_hauled',
        'status_inservice',
        'status_null',
        'status_outofservice',
        'steering_blue',
        'steering_gray',
        'steering_white',
        'stop',
        'structural',
        'subscription',
        'subscription_gray',
        'subscription_white',
        'subtract',
        'subtract_lightgray',
        'Sudan',
        'sunny',
        'supplies_blue',
        'supplies_gray',
        'supplies_white',
        'Suriname',
        'survey',
        'survey_gray',
        'survey_white',
        'Swaziland',
        'Sweden',
        'swipe_gray',
        'Switzerland',
        'Syria',
        'Taiwan',
        'Tajikistan',
        'Tanzania',
        'task_blue',
        'task_gray',
        'tasks',
        'tasks_gray',
        'Thailand',
        'thruster_blue',
        'thruster_gray',
        'thruster_white',
        'Togo',
        'Tonga',
        'tools_blue',
        'tools_gray',
        'tools_white',
        'toys_blue',
        'toys_gray',
        'toys_white',
        'transmission',
        'transmission_gray',
        'transmission_white',
        'triangleExclamation',
        'triangleExclamation_gray',
        'triangleExclamation_white',
        'Trinidad and Tobago',
        'tsunami',
        'Tunisia',
        'Turkey',
        'Turkmenistan',
        'Tuvalu',
        'type',
        'type',
        'type_gray',
        'type_gray',
        'type_white',
        'Uganda',
        'Ukraine',
        'United Arab Emirates',
        'United Kingdom',
        'United States',
        'upload',
        'upload_gray',
        'upload_white',
        'url',
        'url_gray',
        'url_lightgray',
        'url_white',
        'Uruguay',
        'user',
        'user2',
        'user_gray',
        'user_white',
        'Uzbekistan',
        'Vanuatu',
        'Vatican City',
        'Venezuela',
        'verified',
        'video',
        'video_gray',
        'video_white',
        'Vietnam',
        'voltage',
        'voltage_gray',
        'water',
        'water_gray',
        'water_white',
        'weather',
        'xmark',
        'xmark_gray',
        'xmark_lightgray',
        'xmark_mediumgray',
        'yacht',
        'yacht_blue',
        'yacht_gray',
        'yacht_small',
        'year',
        'year_gray',
        'Yemen',
        'Zambia',
        'Zimbabwe',
    ];

    // if icon exists in existing_icons return true, else return false
    if ( existing_icons.includes(icon) ) { return true; }
    return false;
}
