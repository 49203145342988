import React from 'react';
import { View, Text, TextInput as NativeTextInput, Pressable, Picker, Image, StyleSheet, Animated, PanResponder, ScrollView, FlatList, useWindowDimensions } from 'react-native';
import SortableList from './SortableList';
import { mask } from "react-native-mask-text";
import { useSpring, animated } from 'react-spring';
import { useRoute } from '@react-navigation/native';
import { getInitials, getLabel, getSubscription, truncate, getUnreadNotificationsCount, date_DMJYYYY, date_DMJYYYY_TIME, now_datetime, makeCopyright, getUserDataByID } from './helpers';
import { GlobalContext } from './global';
import { create, useDeviceContext } from 'twrnc';

const tw = create(require(`../tailwind.config.js`));

export function Tag ( props )
{
    const styleProp = props.style ? props.style : {};
    const colorProp = props.color ? props.color : `blueLight`;

    const color = /^[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(colorProp) ? '#'+colorProp : tw.color(colorProp);

    return (
        <Span style={[tw`uppercase text-[${color}] bg-[${color}]/20 self-start py-1.5 px-2 rounded font-semibold tracking-0.4 text-xs`, styleProp]}>{props.children}</Span>
    );
}

export function AssetItem ( props )
{    
    useDeviceContext(tw);
    
    const styleProp = props.style ? props.style : {};
    const styleMenuProp = props.styleMenu ? props.styleMenu : {};
    const initialsProp = props.initials ? props.initials : '';
    const colorProp = props.color ? props.color : 'gray4';
    const titleProp = props.title ? props.title : '';
    const subtitleProp = props.subtitle ? props.subtitle : '';
    const descriptionProp = props.description ? props.description : false;
    const description2Prop = props.description2 ? props.description2 : false;
    const footerDescriptionProp = props.footerDescription ? props.footerDescription : false;
    const onPressProp = props.onPress ? props.onPress : false;
    const onPressBadgeProp = props.onPressBadge ? props.onPressBadge : false;
    const buttonsProp = props.buttons ? props.buttons : [];
    const viewMoreProp = props.viewMore ? props.viewMore : getLabel('VIEW_MORE');
    
    const titleTagProp = props.titleTag ? props.titleTag : false;
    const titleTagColorProp = props.titleTagColor ? props.titleTagColor : 'gray6';
    const bladeColorProp = props.bladeColor ? props.bladeColor : false;
    const footerTextProp = props.footerText ? props.footerText : false;

    const titleIconProp = props.titleIcon ? props.titleIcon : '';
    const titleIcon2Prop = props.titleIcon2 ? props.titleIcon2 : '';
    const iconProp = props.icon ? props.icon : false;
    const labelProp = props.label ? props.label : false;
    const labelColorProp = props.labelColor ? props.labelColor : 'white';
    const badgeProp = props.badge ? props.badge : false;
    const unreadProp = props.unread ? props.unread : false;
    const contentProp = props.content ? props.content : false;
    
    const locationProp = props.location ? props.location : false;
    const boatProp = props.boat ? props.boat : '';
    const ownerProp = props.owner ? props.owner : '';
    const dateProp = props.date ? props.date : '';
    const dueProp = props.due ? props.due : '';
    const emailProp = props.email ? props.email : '';
    const modelProp = props.model ? props.model : '';
    const serialProp = props.serial ? props.serial : '';
    const partProp = props.part ? props.part : '';
    const typeProp = props.type ? props.type : '';
    const qtyReqProp = props.qtyReq ? props.qtyReq : '';
    const onHandProp = props.onHand ? props.onHand : '';
    const whereProp = props.where ? props.where : '';
    const infoProp = props.info ? props.info : '';
    const hoursProp = props.hours ? props.hours : '';
    
    const tagProp = props.tag ? props.tag : '';
    const tagColorProp = props.tagColor ? props.tagColor : 'green';
    const menuProp = props.menu ? props.menu : [];
    const fullWidthProp = props.fullWidth ? true : false;

    let menuOptions = [];
    let buttons = [];

    const {height, width} = useWindowDimensions();
    const tileHeight = height * 0.15;
    const minHeightProp = props.minHeight ? ( true === props.minHeight ? tileHeight : ( tw.prefixMatch(`md`) && false !== onPressProp ? props.minHeight + 45 : props.minHeight ) ) : false;
    
    const pan = React.useRef(new Animated.ValueXY({x:0, y:0})).current;
    let openOffset = menuProp.length > 0 && ! tw.prefixMatch('md') ? -1 * ( menuProp.length * 64 ) : 0;
    let triggerOffset = -50;
    
    let lastOffset = React.useRef(parseInt(JSON.stringify(pan.x))).current;

    const color = /^[0-9A-F]{6}[0-9a-f]{0,2}$/i.test(colorProp) ? '#'+colorProp : tw.color(colorProp);
    
    const panOffset = pan.x.interpolate({
        inputRange:[openOffset, 0], 
        outputRange:[openOffset, 0],
        extrapolate: "clamp",
    });
    
    const [isOpen, setIsOpen] = React.useState(false);

    const panResponder = PanResponder.create({
        onMoveShouldSetPanResponder: () => {
            let offsetX = tw.prefixMatch('md') ? 0 : parseInt(JSON.stringify(pan.x));
            if ( offsetX > 0 ) { offsetX = 0; }
            else if ( offsetX < openOffset ) { offsetX = openOffset; }
            lastOffset = offsetX;
            pan.setOffset({x: lastOffset});
            return true;
        },
        onPanResponderMove: Animated.event(
            [ null,
                {
                    dx: pan.x,
                    dy: pan.y,
                },
            ],
            {useNativeDriver: false}
        ),
        onPanResponderRelease: () => {
            let offsetX = tw.prefixMatch('md') ? 0 : parseInt(JSON.stringify(pan.x));
            if ( offsetX > 0 ) { setIsOpen(false); return; }
            else if ( offsetX < openOffset ) { setIsOpen(true); return; }
            else if ( offsetX > lastOffset )
            {
                setIsOpen(false);
            }
            else if ( offsetX < openOffset 
                     || offsetX <= triggerOffset )
            {
                setIsOpen(true);
            }
            else
            {
                Animated.spring(
                    pan, 
                    {
                        useNativeDriver: false,
                        overshootClamping: true,
                        toValue: { x: Math.abs(openOffset), y: 0 } 
                    }
                ).start();
            }
        },
    });

    React.useEffect(() => 
    {
        if ( isOpen )
        {
            Animated.spring(
                pan, 
                {
                    useNativeDriver: false,
                    overshootClamping: true,
                    toValue: { x: openOffset, y: 0 }
                }
            ).start();
        }
        else
        {
            Animated.spring(
                pan, 
                {
                    useNativeDriver: false,
                    overshootClamping: true,
                    toValue: { x: Math.abs(openOffset), y: 0 }
                }
            ).start();
        }
    },[isOpen, pan, openOffset]);

    menuProp.forEach((value, index) => {
        let icon = {};
        switch (value.name)
        {
            case getLabel('EDIT'):
            case getLabel('MARK') +' '+ getLabel('READ'):
                icon = { uri: require('./svg/edit_green.svg') };
                break;
            case getLabel('SHARE'):
                icon = { uri: require('./svg/share_blue.svg') };
                break;
            case getLabel('TASK_MARK_COMPLETE'):
                icon = { uri: require('./svg/mark_complete.svg') };
                break;
            case '-1 ' + getLabel('INVENTORY_STOCK'):
                icon = { uri: require('./svg/subtract.svg') };
                break;
            case '+1 ' + getLabel('INVENTORY_STOCK'):
                icon = { uri: require('./svg/add.svg') };
                break;
            default:
                icon = { uri: require('./svg/delete_red.svg') };
                break;
        }

        menuOptions.push(
            <Pressable style={tw`bg-${value.color} md:bg-white shrink w-full justify-center items-center h-full md:flex-row md:justify-start md:p-2`}
                key={index}
                onPress={()=>{value.onPress(); setIsOpen(false);}}
                onHoverIn={()=>{setIsOpen(true);}}
                onHoverOut={()=>{setIsOpen(false);}}
            >
                <Image
                    accessibilityHidden={true}
                    source={ icon }
                    resizeMode="contain"
                    style={tw`hidden md:flex h-5 w-5 mr-2`}
                />
                <Span style={tw`text-white text-xs md:text-ink`}>{value.name}</Span>
            </Pressable>
        );
    });

    buttonsProp.forEach((value, index) => {
        buttons.push(
             <Button
                 key={index}
                 title={value.name}
                 style={[tw`px-5 py-2 mr-4`, ! value.primary ? tw`bg-transparent border border-blue` : {}]}
                 styleHover={! value.primary ? tw`bg-transparent border-blueLight` : {}}
                 styleText={! value.primary ? tw`text-blue text-sm tracking-widest` : tw`text-sm tracking-widest`}
                 styleHoverText={! value.primary ? tw`text-blueLight` : {}}
                 onPress={value.onPress}
             />
        );
    });

    return (
        <View style={[tw`my-1 md:mr-4 md:mb-4 md:rounded overflow-hidden flex-row shadow-black shadow-opacity-10 shadow-radius-2`, fullWidthProp ? tw`md:w-full` : tw`md:w-85.5`, false !== minHeightProp && tw`min-h-[${minHeightProp}px]`, styleProp]}>
            <Animated.View {...panResponder.panHandlers} style={[tw`flex-row bg-white w-full z-2 md:rounded-r will-change-transform`, { transform: [{ translateX: panOffset }] }]}>

                { '' !== initialsProp &&
                    <View style={tw`w-17 bg-[${color}] h-full justify-center items-center md:w-13 md:rounded-l`}>
                        <Span style={tw`font-bold text-white text-2xl md:text-xl`}>{initialsProp}</Span>
                    </View>
                }

                { '' === initialsProp && false !== iconProp &&
                    <View style={tw`w-17 bg-[${color}] h-full justify-center items-center md:w-13 md:rounded-l`}>
                        <Image
                            accessibilityHidden={true}
                            source={iconProp}
                            resizeMode="contain"
                            style={tw`h-8 w-8`}
                        />
                    </View>
                }

                { '' === initialsProp && false === iconProp && false !== labelProp &&
                    <View style={tw`w-12 bg-[${color}] h-full justify-center items-center md:w-13 md:rounded-l`}>
                        <View style={[ tw`flex-1 grow w-[${(minHeightProp || tileHeight) - 20}px] justify-center items-center`, {transform: [{ rotate: '270deg'}]} ]}>
                            <Span style={tw`w-full text-center leading-tight font-bold text-${labelColorProp} text-base uppercase`}>{labelProp}</Span>
                        </View>
                    </View>
                }

                { '' === initialsProp && false === iconProp && false === labelProp && false !== bladeColorProp &&
                    <View style={tw`w-3 bg-${bladeColorProp} md:rounded-l`}></View>
                }

                { false !== onPressBadgeProp && '' === initialsProp && false !== badgeProp && 
                    <Pressable onPress={()=>{onPressBadgeProp()}} style={[tw`w-17 pl-5 pt-5 h-full justify-start items-center md:w-13 md:rounded-l`, fullWidthProp ? tw`md:w-20 md:flex-row md:justify-center md:pt-0` : tw``]}>
                        <Span style={[tw`bg-blueGray w-7.5 h-7.5 shrink-0 rounded-full font-bold text-blue text-center text-xs leading-[30px]`, fullWidthProp ? tw`md:order-2` : tw``]}>{badgeProp}</Span>
                        { false !== unreadProp &&
                            <View style={[tw`mt-4.5 bg-red w-2 h-2 rounded-full`, fullWidthProp ? tw`md:mt-0 md:mr-4.5 md:order-1` : tw``]}></View>
                        }
                    </Pressable>
                }

                { false === onPressBadgeProp && '' === initialsProp && false !== badgeProp &&
                    <View style={[tw`w-17 pl-5 pt-5 h-full justify-start items-center md:w-13 md:rounded-l`, fullWidthProp ? tw`md:w-20 md:flex-row md:justify-center md:pt-0` : tw``]}>
                        <Span style={[tw`bg-blueGray w-7.5 h-7.5 shrink-0 rounded-full font-bold text-blue text-center text-xs leading-[30px]`, fullWidthProp ? tw`md:order-2` : tw``]}>{badgeProp}</Span>
                        { false !== unreadProp &&
                            <View style={[tw`mt-4.5 bg-red w-2 h-2 rounded-full`, fullWidthProp ? tw`md:mt-0 md:mr-4.5 md:order-1` : tw``]}></View>
                        }
                    </View>
                }
                
                <Pressable
                    style={tw`shrink-1 grow flex-row md:flex-col cursor-default`}
                    onPress={()=>{ ! tw.prefixMatch(`md`) && false !== onPressProp && onPressProp() }}
                >
                    <View style={tw`shrink-1 grow px-4 py-3 md:pr-5`}>
                        { false !== contentProp &&
                            <View><>{contentProp}</></View>
                        }
                        { false !== titleTagProp && '' !== titleProp && '' === subtitleProp && '' === titleIconProp && '' === titleIcon2Prop &&
                            <Heading hlevel={2} styleHlevel={4} style={tw`m-0 mb-1 leading-[29px]`} numberOfLines={1}><Tag color={titleTagColorProp} style={tw`mr-1`}>{titleTagProp}</Tag> {titleProp}</Heading>
                        }
                        { false !== titleTagProp && '' !== titleProp && '' !== subtitleProp && '' === titleIconProp && '' === titleIcon2Prop &&
                            <View style={tw`flex-row justify-between items-center mb-2`}>
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0 leading-[29px]`} numberOfLines={1}><Tag color={titleTagColorProp} style={tw`mr-1`}>{titleTagProp}</Tag> {titleProp}</Heading>
                                <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`} numberOfLines={1}>{subtitleProp}</Span>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' === subtitleProp && '' === titleIconProp && '' === titleIcon2Prop &&
                            <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                        }
                        { false === titleTagProp && '' !== titleProp && '' !== subtitleProp && '' !== titleIconProp && '' !== titleIcon2Prop &&
                            <View style={tw`flex-row justify-start items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIconProp }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIcon2Prop }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                                <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`} numberOfLines={1}>{subtitleProp}</Span>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' !== subtitleProp && '' === titleIconProp && '' !== titleIcon2Prop &&
                            <View style={tw`flex-row justify-start items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIcon2Prop }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                                <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`} numberOfLines={1}>{subtitleProp}</Span>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' !== subtitleProp && '' !== titleIconProp && '' === titleIcon2Prop &&
                            <View style={tw`flex-row justify-start items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIconProp }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                                <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`} numberOfLines={1}>{subtitleProp}</Span>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' === subtitleProp && '' !== titleIconProp && '' !== titleIcon2Prop &&
                            <View style={tw`flex-row justify-start items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIconProp }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIcon2Prop }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' === subtitleProp && '' === titleIconProp && '' !== titleIcon2Prop &&
                            <View style={tw`flex-row justify-start items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIcon2Prop }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' === subtitleProp && '' !== titleIconProp && '' === titleIcon2Prop &&
                            <View style={tw`flex-row justify-start items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={ titleIconProp }
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                            </View>
                        }
                        { false === titleTagProp && '' !== titleProp && '' !== subtitleProp && '' === titleIconProp && '' === titleIcon2Prop &&
                            <View style={tw`flex-row justify-between items-center`}>
                                <Heading hlevel={2} styleHlevel={4} style={tw`m-0`} numberOfLines={1}>{titleProp}</Heading>
                                <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`} numberOfLines={1}>{subtitleProp}</Span>
                            </View>
                        }
                        { ' ' === descriptionProp &&
                            <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>&nbsp;</Span>
                        }
                        { false !== descriptionProp && ' ' !== descriptionProp &&
                            <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{descriptionProp}</Span>
                        }
                        { false !== description2Prop && ' ' !== description2Prop &&
                            <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{description2Prop}</Span>
                        }
                        { ' ' === locationProp &&
                            <View style={tw`flex-row items-center`}> 
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>&nbsp;</Span>
                            </View>
                        }
                        { '' !== qtyReqProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/quantity_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ getLabel('QTY_REQUIRED') }: {qtyReqProp}</Span>
                            </View>
                        }
                        { '' !== onHandProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/onhand_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ getLabel('INVENTORY_INSTOCK') }: {onHandProp}</Span>
                            </View>
                        }
                        { false !== locationProp && ' ' !== locationProp &&
                            <View style={tw`flex-row items-center`}>                    
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/pin_drop.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{locationProp}</Span>
                            </View>
                        }
                        { '' !== boatProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/yacht_small.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{boatProp}</Span>
                            </View>
                        }
                        { '' !== ownerProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/user_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ownerProp}</Span>
                            </View>
                        }
                        { '' !== infoProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/generalinfo_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{infoProp}</Span>
                            </View>
                        }
                        { '' !== dateProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/date.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{dateProp}</Span>
                            </View>
                        }
                        { '' !== dueProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/date.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{getLabel('DUE')} {dueProp}</Span>
                            </View>
                        }
                        { '' !== emailProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/email_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{emailProp}</Span>
                            </View>
                        }
                        { '' !== modelProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/model_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ getLabel('MODEL') }: {modelProp}</Span>
                            </View>
                        }
                        { '' !== typeProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/invtype_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ getLabel('TYPE') }: {typeProp}</Span>
                            </View>
                        }
                        { '' !== serialProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/serial_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ getLabel('SERIAL') }: {serialProp}</Span>
                            </View>
                        }
                        { '' !== partProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/part_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{ getLabel('PART') }: {partProp}</Span>
                            </View>
                        }
                        { '' !== whereProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/location_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{whereProp}</Span>
                            </View>
                        }
                        { '' !== hoursProp &&
                            <View style={tw`flex-row items-center`}>
                                <Image
                                    accessibilityHidden={true}
                                    source={{ uri: require('./svg/enginehours_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 mr-2`}
                                />
                                <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{hoursProp} { getLabel('HOURS_SHORT') }</Span>
                            </View>
                        }
                        { '' !== tagProp &&
                            <Tag color={tagColorProp} style={tw`mt-1`}>{tagProp}</Tag>
                        }
                        { false !== footerTextProp &&
                            <View style={tw`flex-row justify-end`}>
                                <Span style={tw`text-gray6 text-sm md:mr-5 shrink-0`} numberOfLines={1}>{footerTextProp}</Span>
                            </View>
                        }
                        { false !== footerDescriptionProp && ' ' !== footerDescriptionProp &&
                            <Span style={tw`text-gray6 text-sm leading-6`} numberOfLines={1}>{footerDescriptionProp}</Span>
                        }
                        { buttons.length > 0 &&
                            <View style={tw`mt-2 flex-row justify-start`}>
                                <>{ buttons }</>
                            </View>
                        }
                    </View>

                    { tw.prefixMatch(`md`) && false !== onPressProp &&
                        <View style={tw`shrink-0 w-12 md:w-full md:h-auto`}>
                            <Pressable style={tw`justify-center md:justify-start items-center h-full border-l-2 md:border-l-0 md:border-t border-gray1 p-2 md:px-5 md:py-3.5 flex-row`}
                                onPress={()=>{ onPressProp() }}
                            >
                                <Span style={tw`hidden md:flex text-xs uppercase font-semibold tracking-widest mt-px text-blue`}>{viewMoreProp}</Span>
                                <Image
                                    accessibilityLabel={viewMoreProp}
                                    source={{ uri: require('./svg/arrow_forward_ios.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-4 w-4 md:h-3 md:w-3 md:ml-2`}
                                />
                            </Pressable>
                        </View>

                    }
                    { ! tw.prefixMatch(`md`) && menuOptions.length > 0 &&
                        <View style={tw`shrink-0 w-12`}>
                            <View style={tw`justify-center items-center h-full border-l-2 border-gray1 p-2 flex-row`}>
                                <Image
                                    accessibilityLabel={viewMoreProp}
                                    source={{ uri: require('./svg/swipe_gray.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-8 w-8`}
                                />
                            </View>
                        </View>
                    }
                </Pressable>

                { menuOptions.length > 0 &&
                    <Pressable
                        onHoverIn={()=>{ setIsOpen(true);}}
                        onHoverOut={()=>{ setIsOpen(false);}}
                        onPress={()=>{ isOpen ? setIsOpen(false) : setIsOpen(true) }}
                        style={tw`hidden md:flex p-2 absolute top-0 right-0`}
                    >
                        <Image
                            accessibilityLabel={ getLabel('MENU') }
                            source={{ uri: require('./svg/more_vert_black.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 w-5`}
                        />
                    </Pressable>
                }
            </Animated.View>
            
            <Pressable
                style={[
                    tw`flex-row bg-red z-1 overflow-hidden`,
                    {width: menuProp.length * 64, left: tw.prefixMatch('md') ? 'auto' : -1 * ( menuProp.length * 64 ), height: tw.prefixMatch('md') ? menuProp.length * 31 : 'auto'},
                    tw` md:rounded-sm md:w-28 md:bg-white md:z-10 md:flex-col md:shadow-black md:shadow-opacity-10 md:shadow-radius-2 md:right-30 md:top-8 md:pointer-events-none md:opacity-0`,
                    isOpen ? { opacity: 1, pointerEvents: 'auto' } : {},
                    styleMenuProp
                ]}
            >
                <>{menuOptions}</>
            </Pressable>
        </View>
    );
}

export function PrimaryNav ( props )
{
    const AnimatedView = animated(View);
    const [navOpen, setNavOpen] = React.useState(false);
    const [onTop, setOnTop] = React.useState(false);
    const [modalActive, setModalActive] = React.useState(false);
    const [notificationCount, setNotificationCount] = React.useState(0);
    const hideOnMobileProp = props.hideOnMobile ? props.hideOnMobile : false;
    
    const yachtID = props.yachtID ? props.yachtID : false;
    let selectedYacht = false;
    
    let {
        version,
        apiUrl,
        apiVersion,
        userYachts,
        userNotifications,
        enabledFeatures
    } = React.useContext(GlobalContext);
    let templates = [];

    if ( yachtID )
    {
        userYachts.every((value, index) => {
            if ( parseInt(yachtID) === parseInt(value.id) ) { selectedYacht = value; return false; }
            return true;
        });
    }

    const copyright = false !== selectedYacht ? version : makeCopyright(version);

    React.useEffect(()=>
    {
        setNotificationCount(getUnreadNotificationsCount())
    },[userNotifications]);
    
    React.useEffect(()=> 
    {        
        if ( navOpen ) { setOnTop(true); }
        else { setTimeout(()=>{ setOnTop(false); }, 425); }
    },[navOpen]);

    const route = useRoute();
    useDeviceContext(tw);
    const {height, width} = useWindowDimensions();
    
    let mobileNavDrawerAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 3,
            transform: tw.prefixMatch('md') ? 'translateX(0%)' : 'translateX(-110%)',
            position: tw.prefixMatch('md') ? 'relative' : 'fixed',
            width: '100%',
            top: tw.prefixMatch('md') ? 'auto' : '0',
            bottom: tw.prefixMatch('md') ? 'auto' : '0',
            order: tw.prefixMatch('md') ? '2' : 'auto',
        },
        to: {
            transform: navOpen || tw.prefixMatch('md') ? 'translateX(0%)' : 'translateX(-110%)',
            position: tw.prefixMatch('md') ? 'relative' : 'fixed',
            top: tw.prefixMatch('md') ? 'auto' : '0',
            bottom: tw.prefixMatch('md') ? 'auto' : '0',
            order: tw.prefixMatch('md') ? '2' : 'auto',
        },
        config: { mass: 1, tension: 190, friction: 30 } 
    });
    let mobileNavDrawerShadeAnimation = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 2,
            opacity: 0,
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
            width: '100vw',
            height: height,
            left: 0,
            top: 0,
            position: 'fixed',
        },
        to: {
            opacity: navOpen ? 1 : 0,
            pointerEvents: navOpen ? 'auto' : 'none',
        },
        config: { mass: 1, tension: 190, friction: 30 }
    });
    
    return(
        hideOnMobileProp && ! tw.prefixMatch('md') ? <View></View> :
        <View style={[tw`bg-blue h-20 flex-row md:flex-col md:w-54 lg:w-70 md:h-full z-20 md:z-0`, ( onTop || modalActive || tw.prefixMatch('md') ) && tw`z-30`]}>

            <View style={tw`w-18 justify-center md:hidden`}>
                <Pressable
                    onPress={()=>{setNavOpen(true)}}
                    style={tw`p-5 relative`}
                >
                    <Image
                        accessibilityLabel="Menu"
                        source={{ uri: require('./svg/more_vert.svg') }}
                        resizeMode="contain"
                        style={tw`h-5 w-full relative z-0`}
                    />
                </Pressable>
            </View>

            <View style={tw`w-full shrink justify-center md:order-1 md:px-4 md:py-8 md:pb-7 lg:px-8 lg:py-12 lg:pb-11`}>
                <Image
                    accessibilityLabel="YachtWave"
                    source={{ uri: require('./svg/LogoWhiteHorizontal.svg') }}
                    resizeMode="contain"
                    style={tw`h-9 md:h-10`}
                />
            </View>

            <HorizontalRule style={tw`hidden md:flex bg-blueLight order-2`} />

            <AnimatedView style={mobileNavDrawerAnimation}>

                <View accessibilityRole="navigation" style={tw`bg-blue h-full w-4/5 md:w-full shadow-black shadow-opacity-50 shadow-radius-8 md:shadow-radius-0 z-10`}>

                    <ScrollView
                        style={{width:'100%'}}
                        contentContainerStyle={[tw`w-full grow lg:max-h-[${height - 231}px] justify-start lg:pt-7 md:py-3`, false === selectedYacht ? tw`pt-8` : tw`pt-4`]}
                    >

                        <Pressable
                            onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('yachts')},tw.prefixMatch('md') ? 0 : 400)}}
                            style={[tw`px-5 py-1.5 md:py-3 mt-3 md:my-0 lg:my-1 relative flex-row pl-10 md:pl-8 lg:pl-15`,
                                    'yachts' === route.name ? tw`bg-blueLight/25` : {}
                            ]}
                        >
                            <Image
                                accessibilityLabel={ getLabel('ICON_YACHTS') }
                                source={{ uri: require('./svg/home.svg') }}
                                resizeMode="contain"
                                style={tw`h-8 w-8 relative z-0 -left-1`}
                            />

                            <Span style={tw`font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ getLabel('ICON_YACHTS') }</Span>

                        </Pressable>

                        { false !== selectedYacht &&
                            <View>
                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('yacht', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-10 md:pl-7.5 lg:pl-15`,
                                            'yacht' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`bg-${selectedYacht.color} text-white uppercase rounded-full text-xs font-semibold w-7.5 h-7.5 mr-1 mt-px text-center leading-[30px]`}>{selectedYacht.initials}</Span>

                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('DETAILS') }</Span>

                                </Pressable>

                                { true === enabledFeatures.sharing && true === selectedYacht.is_owned_by_me &&
                                    <Pressable
                                        onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('shared', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                        style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                                'shared' === route.name ? tw`bg-blueLight/25` : {}
                                        ]}
                                    >
                                        <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('SHARES') }</Span>

                                    </Pressable>
                                }

                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('engines', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                            'engines' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('ENGINES') }</Span>

                                </Pressable>

                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('inventory', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                            'inventory' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('INVENTORY') }</Span>

                                </Pressable>

                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('equipment', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                            'equipment' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('EQUIPMENT') }</Span>

                                </Pressable>

                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('logs', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                            'logs' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('MAINTENANCE') }</Span>

                                </Pressable>

                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('yachtTasks', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                            'yachtTasks' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('TASKS') }</Span>

                                </Pressable>

                                <Pressable
                                    onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('notes', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                    style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                            'notes' === route.name ? tw`bg-blueLight/25` : {}
                                    ]}
                                >
                                    <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('NOTES') }</Span>

                                </Pressable>

                                { true === enabledFeatures.checklists &&
                                    <Pressable
                                        onPress={()=>{
                                            setNavOpen(false);

                                            let yachtObj = false;
                                            userYachts.every((value, index) => {
                                                if ( yachtID == value.id ) { yachtObj = value; return false; }
                                                return true;
                                            });

                                            let templates = yachtObj.checklist.filter(function (el)
                                            {
                                                return 1 === el.is_template;
                                            });

                                            setTimeout(()=>{
                                                if ( ! templates.length )
                                                {
                                                    props.navigation.navigate('templates', {yachtID:yachtID});
                                                }
                                                else
                                                {
                                                    props.navigation.navigate('checklists', {yachtID:yachtID});
                                                }
                                            }, tw.prefixMatch('md') ? 0 : 400)}}
                                        style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                                'checklists' === route.name || 'templates' === route.name ? tw`bg-blueLight/25` : {}
                                        ]}
                                    >
                                        <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('CHECKLISTS') }</Span>

                                    </Pressable>
                                }

                                { true === enabledFeatures.photos &&
                                    <Pressable
                                        onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('photos', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                        style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                                'photos' === route.name ? tw`bg-blueLight/25` : {}
                                        ]}
                                    >
                                        <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('PHOTOS') }</Span>

                                    </Pressable>
                                }

                                { true === enabledFeatures.attachments &&
                                    <Pressable
                                        onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('attachments', {yachtID:yachtID})},tw.prefixMatch('md') ? 0 : 400)}}
                                        style={[tw`px-5 py-0 my-0.5 relative flex-row pl-18.5 md:pl-16 lg:pl-23.5`,
                                                'attachments' === route.name ? tw`bg-blueLight/25` : {}
                                        ]}
                                    >
                                        <Span style={tw`font-semibold uppercase text-xs text-white leading-8 ml-4 tracking-[2px]`}>{ getLabel('ATTACHMENTS') }</Span>

                                    </Pressable>
                                }
                            </View>
                        }

                        <Pressable
                            onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('tasks')},tw.prefixMatch('md') ? 0 : 400)}}
                            style={[tw`px-5 py-1.5 md:py-3 mt-3 md:mt-4 md:my-0 lg:my-1 relative flex-row pl-10 md:pl-8 lg:pl-15`,
                                    'tasks' === route.name ? tw`bg-blueLight/25` : {}
                            ]}
                        >
                            <Image
                                accessibilityLabel="My Tasks"
                                source={{ uri: require('./svg/tasks.svg') }}
                                resizeMode="contain"
                                style={tw`h-8 w-8 relative z-0 -left-1`}
                            />

                            <Span style={tw`font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ getLabel('ICON_TASKS') }</Span>

                        </Pressable>

                        <Pressable
                            onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('account')},tw.prefixMatch('md') ? 0 : 400)}}
                            style={[tw`px-5 py-1.5 md:py-3 mt-3 md:mt-4 md:my-0 lg:my-1 relative flex-row pl-10 md:pl-8 lg:pl-15`,
                                    'account' === route.name ? tw`bg-blueLight/25` : {}
                            ]}
                        >
                            <Image
                                accessibilityLabel="Account"
                                source={{ uri: require('./svg/account.svg') }}
                                resizeMode="contain"
                                style={tw`h-8 w-8 relative z-0 -left-1`}
                            />

                            <Span style={tw`font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ getLabel('ICON_ACCOUNT') }</Span>

                        </Pressable>

                        { /*getSubscription('feature_roadmap') &&

                            <Pressable
                                onPress={()=>{setNavOpen(false); setTimeout(()=>{props.navigation.navigate('roadmap')},tw.prefixMatch('md') ? 0 : 400)}}
                                style={[tw`px-5 py-1.5 md:py-3 mt-3 md:mt-4 md:my-0 lg:my-1 relative flex-row pl-10 md:pl-8 lg:pl-15`,
                                        'roadmap' === route.name ? tw`bg-blueLight/25` : {}
                                ]}
                            >
                                <Image
                                    accessibilityLabel="Account"
                                    source={{ uri: require('./svg/description_white.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-8 w-8 relative z-0 -left-1`}
                                />

                                <Span style={tw`font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ 'ROADMAP' }</Span>

                            </Pressable>
                        */ }

                        { tw.prefixMatch('md') &&

                            <View style={tw`pb-6`}>
                                <Pressable
                                    onPress={()=>{setNavOpen(false); props.navigation.navigate('notifications')}}
                                    style={[tw`px-5 py-1.5 md:py-3 mt-3 md:mt-4 md:my-0 lg:my-1 relative flex-row pl-10 md:pl-8 lg:pl-15`,
                                            'notifications' === route.name ? tw`md:bg-blueLight/25` : {}
                                    ]}
                                >
                                    { notificationCount > 0 &&
                                        <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-12 lg:left-18 z-10 pointer-events-none`}>
                                            { notificationCount }
                                        </Span>
                                    }

                                    <Image
                                        accessibilityLabel="Notifications"
                                        source={{ uri: require('./svg/notifications.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                                    />

                                    <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ getLabel('NOTIFICATIONS') }</Span>

                                </Pressable>
                            </View>
                        }

                        { ! tw.prefixMatch('md') &&
                            <Pressable
                                onPress={()=>{setModalActive(true)}}
                                style={tw`px-5 py-1.5 md:py-3 mt-3 md:mt-4 md:my-0 lg:my-1 relative flex-row pl-10 md:pl-8 lg:pl-15`}
                            >
                                <Image
                                    accessibilityLabel="Logout"
                                    source={{ uri: require('./svg/logout.svg') }}
                                    resizeMode="contain"
                                    style={tw`h-8 w-8 relative z-0 -left-1`}
                                />

                                <Span style={tw`font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ getLabel('ACCOUNT_LOGOUT') }</Span>

                            </Pressable>
                        }


                        { ! tw.prefixMatch('md') &&
                            <Span style={tw`absolute bottom-4 left-10 text-white/50 text-xs pointer-events-none`}>{copyright}</Span>
                        }

                    </ScrollView>

                </View>

                <Pressable onPress={()=>{setNavOpen(false)}} style={tw`md:hidden absolute w-full h-full`}></Pressable>

            </AnimatedView>

            <AnimatedView style={mobileNavDrawerShadeAnimation}>
                <View style={tw`md:hidden fixed bg-black/50 top-0 bottom-0 right-0 left-0 z-30`}></View>
            </AnimatedView>

            <View style={tw`w-18 justify-center md:hidden`}>
                <Pressable
                    onPress={()=>{setNavOpen(false); props.navigation.navigate('notifications')}}
                    style={[tw`p-5 relative md:flex-row md:pl-8 lg:pl-15 md:py-3 md:my-0 lg:my-2`,
                            'notifications' === route.name ? tw`md:bg-blueLight/25` : {}
                    ]}
                >
                    { notificationCount > 0 &&
                        <Span style={tw`bg-white text-blue rounded-full min-w-4 h-4 text-center text-xs font-bold absolute bottom-1/2 left-1/2 md:left-12 lg:left-18 z-10 pointer-events-none`}>
                            { notificationCount }
                        </Span>
                    }

                    <Image
                        accessibilityLabel="Notifications"
                        source={{ uri: require('./svg/notifications.svg') }}
                        resizeMode="contain"
                        style={tw`h-5 md:h-8 w-full md:w-8 relative z-0 -left-1`}
                    />

                    <Span style={tw`hidden md:flex font-bold text-base text-white leading-8 ml-4 tracking-wide`}>{ getLabel('NOTIFICATIONS') }</Span>

                </Pressable>
            </View>

            { tw.prefixMatch('md') &&
                <View style={tw`order-2 md:pb-4 lg:pb-6 absolute bottom-0 w-full`}>

                    <HorizontalRule style={tw`hidden md:flex bg-blueLight md:mt-4 lg:mt-7 md:mb-4`} />

                    <Pressable
                        onPress={()=>{setOnTop(true); setModalActive(true)}}
                        style={tw`px-5 py-3 my-3 relative flex-row pl-10 md:pl-8 lg:pl-15`}
                    >
                        <Image
                            accessibilityLabel={ getLabel('ACCOUNT_LOGOUT') }
                            source={{ uri: require('./svg/logout.svg') }}
                            resizeMode="contain"
                            style={tw`h-4 w-4 relative z-0 -left-1`}
                        />

                        <Span style={tw`font-medium text-sm uppercase text-white leading-4 ml-4 tracking-widest`}>{ getLabel('ACCOUNT_LOGOUT') }</Span>

                    </Pressable>

                    <Span style={tw`absolute bottom-4 lg:bottom-6 left-16 lg:left-23 text-white/50 text-xs pointer-events-none`}>{version}</Span>

                </View>
            }
            
            <Modal
                active={modalActive}
                setActive={setModalActive}
            >
                <Heading hlevel={2} styleHlevel={3}>{ getLabel('ACCOUNT_LOGOUT') }</Heading>
                <Span>{ getLabel('DELETE_CONFIRM_TITLE') }</Span>
                <View style={tw`flex-row mt-5`}>
                    <Button
                        title={ getLabel('CANCEL') }
                        style={tw`w-full shrink py-2 bg-white border border-blue`}
                        styleHover={tw`bg-white`}
                        styleText={tw`text-blue`}
                        styleHoverText={tw`text-blue`}
                        onPress={()=>{setModalActive(false)}}
                    />
                    <Button
                        title={ getLabel('ACCOUNT_LOGOUT') }
                        image={{ uri: require('./svg/logout.svg') }}
                        style={tw`ml-2 w-full shrink py-2`}
                        styleText={tw`text-white`}
                        styleHoverText={tw`text-white`}
                        onPress={()=>{
                            fetch(apiUrl, {
                                method: 'POST',
                                cache: 'no-cache',
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify({
                                    endpoint: 'user/logout', v: apiVersion,
                                    client_key: localStorage.getItem('clientKey'),
                                    device_id: localStorage.getItem('deviceID')
                                }),
                            })
                            .then((response) => {
                                return response.text().then(function(text) {
                                    const result = JSON.parse(text);
                                    if ( result.error ) 
                                    {
                                        console.warn(result.error);
                                    }
                                    else 
                                    {
                                    }
                                });
                            })
                            .catch(error => {
                                console.warn(error);
                            });
                            
                            setModalActive(false);
                            // preserve 'labels' & 'deviceID'
                            let last_ver = localStorage.getItem('last_ver');
                            let labels_ver = localStorage.getItem('labels_ver');
                            let labels = localStorage.getItem('labels');
                            let deviceID = localStorage.getItem('deviceID');
                            localStorage.clear();
                            if ( last_ver ) { localStorage.setItem( 'last_ver', last_ver ); }
                            if ( labels_ver ) { localStorage.setItem( 'labels_ver', labels_ver ); }
                            if ( labels ) { localStorage.setItem( 'labels', labels ); }
                            if ( deviceID ) { localStorage.setItem( 'deviceID', deviceID ); }
                            props.setLoggedIn(false);
                        }}
                    />
                </View>
            </Modal>
        </View>
    );
}
export const MemoizedPrimaryNav = React.memo(PrimaryNav);

export function Button ( props )
{
    const onPressProp = props.onPress ? props.onPress : ()=>{};
    const styleProp = props.style ? props.style : {};
    const styleHoverProp = props.styleHover ? props.styleHover : {};
    const styleLoadingProp = props.styleLoading ? props.styleLoading : {};
    const styleLoadingImageProp = props.styleLoadingImage ? props.styleLoadingImage : {};
    const styleTextProp = props.styleText ? props.styleText : {};
    const styleTextHoverProp = props.styleHoverText ? props.styleHoverText : {};
    const disabledProp = props.disabled ? true : false;
    const imageProp = props.image ? props.image : false;
    const a11yLabelProp = props.a11yLabel ? props.a11yLabel : false;
    
    const [isLoading, setIsLoading] = React.useState(false);    
    let doingValidation = props.validateGroup ? props.validateGroup : false;
    
    const styles = StyleSheet.create({
        button: {
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            paddingVertical: 19,
            paddingHorizontal: 19,
            borderRadius: 4,
            backgroundColor: tw.color('blue'),
            textTransform: 'uppercase',
            cursor: 'pointer',
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '150ms',
            hover: { 
                backgroundColor: tw.color('blueLight'),
            },
            loading: {
                pointerEvents: 'none',
                lineHeight: 14,
                paddingVertical: 8,
            },
            disabled: {
                backgroundColor: tw.color('gray2'),
                pointerEvents: 'none',
            },
        },
        text: {
            display: 'block',
            fontSize: 14,
            lineHeight: 14,
            fontWeight: 600,
            letterSpacing: 1.15,
            transitionProperty: 'all',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
            transitionDuration: '150ms',
            color: tw.color('white'),
            hover: {
                color: tw.color('white'),
            },
            disabled: {
                color: '#77879C'
            },
        },
    });

    // React.useEffect(() => 
    // {
    //     setIsLoading(true);
    //     
    // },[isLoading]);
    
    React.useEffect(() =>
    {
        if ( doingValidation ) { setIsLoading(true); return; }
        setIsLoading(false);
        
    }, [doingValidation]);

    return (
        isLoading ?
        <View
            style={[StyleSheet.compose(styles.button, styleProp), StyleSheet.compose(styles.button.loading, styleLoadingProp)]}
        >
            <Image
                accessibilityHidden={false}
                accessibilityLabel={'Loading...'}
                source={{ uri: props.loadingImage ? props.loadingImage : require('./svg/loader.svg') }}
                resizeMode="contain"
                style={[tw`h-9 w-full`, styleLoadingImageProp]}
            />
        </View>

        :

        <Pressable
            style={(state) => [
                StyleSheet.compose(styles.button, styleProp),
                state.hovered && StyleSheet.compose(styles.button.hover, styleHoverProp),
                disabledProp && StyleSheet.compose(styles.button.disabled),
            ]}
            disabled={disabledProp}
            accessibilityRole="button"
            onPress={() => {onPressProp()}}
        >
            {( state ) => (            
            
                <View style={tw`flex-row items-center`}>
                    { imageProp &&
                        <Image
                            accessibilityHidden={a11yLabelProp ? false : true}
                            accessibilityLabel={a11yLabelProp ? a11yLabelProp : ''}
                            source={ imageProp }
                            resizeMode="contain"
                            style={[tw`max-h-full max-w-full h-5 w-5`, props.title && tw`mr-3`]}
                        />
                    }
                    
                    <Text
                        style={ state.hovered && ! disabledProp
                            ? [StyleSheet.compose(styles.text, styleTextProp), StyleSheet.compose(styles.text.hover, styleTextHoverProp)]
                            : ( ! disabledProp ? StyleSheet.compose(styles.text, styleTextProp) : StyleSheet.compose(styles.text, styles.text.disabled) )
                        }
                    >{props.title}</Text>
                </View>
            )}
            
        </Pressable>
    );
}

export function Link (props)
{
    const accessibilityRoleProp = props.accessibilityRole; // allow "false" to remove default a11y role 'link'

    const styles = StyleSheet.create({
        link: {
            color: tw.color('blue'),
            fontWeight: 500,
        },
    });
    return (
        <Text
            {...props}
            onKeyPress={(e) => {if ( 32 === e.key ) { props.onPress(); }}} // not currently supported:
            style={StyleSheet.compose(styles.link, props.style)}
            accessibilityRole={ undefined !== accessibilityRoleProp ? accessibilityRoleProp : 'link'}
        />
    );
}

export function Span (props)
{
    const styleProp = props.style ? props.style : {};
    
    return (
        <Text
            {...props}
            style={[tw`font-sans text-base text-ink`, styleProp]}
        />
    );
}

export function Heading (props)
{
    const styleProp = props.style ? props.style : {};
    const hlevelProp = props.hlevel ? props.hlevel : 0;
    const styleHlevelProp = props.styleHlevel ? props.styleHlevel : hlevelProp;
    const isHeading = hlevelProp ? 'heading' : false;
    const {numberOfLines} = props;
    
    return (
        <Text 
            style={[
                tw`w-full my-3 font-sans text-ink font-extrabold`,
                1 === styleHlevelProp && tw`text-3xl`,
                2 === styleHlevelProp && tw`text-2xl`,
                3 === styleHlevelProp && tw`text-lg`,
                4 === styleHlevelProp && tw`text-base`,
                styleProp
            ]}
            accessibilityRole={isHeading}
            accessibilityLevel={hlevelProp}
            numberOfLines={numberOfLines}
        >
            {props.children}
        </Text>
    );  
}

export function TextDivider (props)
{
    const styleTextProp = props.styleText ? props.styleText : {};
    const styleLineProp = props.styleLine ? props.styleLine : {};
    const hlevelProp = props.hlevel ? props.hlevel : 0;
    const isHeading = hlevelProp ? 'heading' : false;
    
    return (
        <View 
            style={[tw`my-4 w-full flex-row`, props.style]}
        >
            <View
                style={[tw`bg-white h-[1px] w-full shrink mr-4`, styleLineProp]}
            ></View>

            <Text 
                style={[tw`-mt-4 w-auto grow shrink-0 bg-blue font-sans text-center text-white font-extrabold text-2xl`, styleTextProp]}
                accessibilityRole={isHeading}
                accessibilityLevel={hlevelProp}
            >
                {props.children}
            </Text>
            <View
                style={[tw`bg-white h-[1px] w-full shrink ml-4`, styleLineProp]}
            ></View>
        </View>
    );  
}

export function HorizontalRule (props)
{    
    const styleProp = props.style ? props.style : {};
    
    return (
        <View
            {...props}
            style={[tw`bg-gray2 h-[1px] w-full`, styleProp]}
        ></View>
    );  
}

export function Notification (props)
{        
    const styleProp = props.style ? props.style : {};
    const styleTextProp = props.styleText ? props.styleText : {};
    
    return (
        <View style={[tw`w-full max-w-full flex-row`, styleProp]}>
            <View style={tw.style(`w-14 text-center rounded-l`, 'success' === props.type ? `bg-green` : ( 'warning' === props.type ? `bg-red` : `bg-blueLight` ))}>
                <Image
                    accessibilityHidden={true}
                    source={{ uri: props.iconImage ? props.iconImage : require('./svg/notify.svg') }}
                    resizeMode="contain"
                    style={tw`h-8 w-full relative top-1/2 -translate-y-1/2`}
                />
            </View>
            <View style={tw.style(`shrink w-full py-[15px] px-5 rounded-r`, 'success' === props.type ? `bg-greenLight` : ( 'warning' === props.type ? `bg-redLight` : `bg-blueGray` ))}>
                <Text style={[tw`text-ink text-base font-sans`, styleTextProp]}>{props.children}</Text>
            </View>
            
        </View>
    );  
}

export function SimpleTextInput ({value = '', ...props})
{
    const styleProp = props.style ? props.style : {};
    const styleFocusProp = props.styleFocus ? props.styleFocus : {};
    const styleDisabledProp = props.styleDisabled ? props.styleDisabled : {};
    const styleInputProp = props.styleInput ? props.styleInput : {};
    const styleInputFocusProp = props.styleInputFocus ? props.styleInputFocus : {};
    const styleInputDisabledProp = props.styleInputDisabled ? props.styleInputDisabled : {};
    const styleImageProp = props.styleImage ? props.styleImage : {};
    const styleImage2Prop = props.styleImage2 ? props.styleImage2 : {};
    const styleSuffixProp = props.styleSuffix ? props.styleSuffix : {};
    const stylePrefixProp = props.stylePrefix ? props.stylePrefix : {};
    const imageProp = props.image ? props.image : false;
    const image2Prop = props.image2 ? props.image2 : false;
    const suffixProp = props.suffix ? props.suffix : '';
    const prefixProp = props.prefix ? props.prefix : '';
    const autoCompleteProp = props.autoComplete ? props.autoComplete : "one-time-code";
    const placeholderTextColorProp = props.placeholderTextColor ? props.placeholderTextColor : tw.color('gray6');
    const onFocusProp = props.onFocus ? props.onFocus : () => {};
    const onBlurProp = props.onBlur ? props.onBlur : () => {};
    const onChangeProp = props.onChange ? props.onChange : () => {};
    const onChangeTextProp = props.onChangeText ? props.onChangeText : () => {};
    const disabledProp = props.disabled ? props.disabled : false;

    const [isFocused, setIsFocused] = React.useState(false);

    const styles = StyleSheet.create({
        wrapper: {
            flexDirection: 'row',
            paddingVertical: 16,
            paddingHorizontal: 16,
            borderRadius: 4,
            backgroundColor: tw.color('white'),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: tw.color('gray4'),
            focus: {
                borderColor: tw.color('blue'),
            },
            invalid: {
                borderColor: tw.color('red'),
            },
            disabled: {
                borderColor: tw.color('gray3'),
                pointerEvents: 'none',
                cursor: 'not-allowed',
                filter: 'grayscale(100%)',
            }
        },
        textInput: {
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: tw.color('ink'),
            fontFamily: 'Inter, sans-serif',
            fontSize: 16,
            outlineWidth: '0px',
            borderWidth: 0,
            backgroundColor: 'transparent',
            focus: {
                outline: 'none',
            },
            invalid: {

            },
            disabled: {
                color: tw.color('gray6'),
            }
        },
        suffix: {
            color: '#67778E',
            fontSize: 16,
            marginLeft: 6
        },
        prefix: {
            flexShrink: 0,
            color: '#67778E',
            fontSize: 12,
            marginRight: 10
        }
    });

    let { styleFocus, passedRef, ...otherProps } = props;

    // console.log('rendering TextInput');

    // apply .replace(/[^\S\r\n ]+/g, "") to textinput value
    // to prevent non-ascii characters from being entered



    return (
        <View style={[
            StyleSheet.compose(styles.wrapper, styleProp),
            isFocused && StyleSheet.compose(styles.wrapper.focus, styleFocusProp),
            disabledProp && StyleSheet.compose(styles.wrapper.disabled, styleDisabledProp)
        ]}>
            { imageProp &&
                <Image
                    accessibilityHidden={true}
                    source={ imageProp }
                    resizeMode="contain"
                    style={[tw`max-h-full max-w-full h-6 w-6 mr-4`, styleImageProp]}
                />
            }

            { image2Prop &&
                <Image
                    accessibilityHidden={true}
                    source={ image2Prop }
                    resizeMode="contain"
                    style={[tw`max-h-full max-w-full h-6 w-6 ml-1 mr-4`, styleImage2Prop]}
                />
            }

            { prefixProp.length > 0 &&
                <Span style={StyleSheet.compose(styles.prefix, stylePrefixProp)}>{ prefixProp }</Span>
            }

            <NativeTextInput
                {...otherProps}
                style={[
                    StyleSheet.compose(styles.textInput, styleInputProp),
                    isFocused && StyleSheet.compose(styles.textInput.focus, styleInputFocusProp),
                    disabledProp && StyleSheet.compose(styles.textInput.disabled, styleInputDisabledProp)
                ]}
                onFocus={(e) => {setIsFocused(true); onFocusProp(e)}}
                onBlur={(e) => {setIsFocused(false); onBlurProp(e)}}
                onChange={(e) => {e.target.value = e.target.value?.replace(/[^\S\r\n ]+/g, ""); onChangeProp(e)}}
                onChangeText={(text) => {onChangeTextProp(text?.replace(/[^\S\r\n ]+/g, ""))}}
                autoComplete={autoCompleteProp}
                underlineColorAndroid={false}
                placeholderTextColor={placeholderTextColorProp}
                ref={passedRef}
            />

            { suffixProp.length > 0 &&
                <Span style={StyleSheet.compose(styles.suffix, styleSuffixProp)}>{ suffixProp }</Span>
            }

        </View>
    );
}
export const MemoizedSimpleTextInput = React.memo(SimpleTextInput);

export function TextInput (props)
{
    const styleProp = props.style ? props.style : {};
    const styleFocusProp = props.styleFocus ? props.styleFocus : {};
    const styleDisabledProp = props.styleDisabled ? props.styleDisabled : {};
    const styleInvalidProp = props.styleInvalid ? props.styleInvalid : {};
    const styleInputProp = props.styleInput ? props.styleInput : {};
    const styleInputFocusProp = props.styleInputFocus ? props.styleInputFocus : {};
    const styleInputDisabledProp = props.styleInputDisabled ? props.styleInputDisabled : {};
    const styleInputInvalidProp = props.styleInputInvalid ? props.styleInputInvalid : {};
    const styleImageProp = props.styleImage ? props.styleImage : {};
    const styleImage2Prop = props.styleImage2 ? props.styleImage2 : {};
    const styleSuffixProp = props.styleSuffix ? props.styleSuffix : {};
    const stylePrefixProp = props.stylePrefix ? props.stylePrefix : {};
    const imageProp = props.image ? props.image : false;
    const image2Prop = props.image2 ? props.image2 : false;
    const suffixProp = props.suffix ? props.suffix : '';
    const prefixProp = props.prefix ? props.prefix : '';
    const autoCompleteProp = props.autoComplete ? props.autoComplete : "one-time-code";
    const placeholderTextColorProp = props.placeholderTextColor ? props.placeholderTextColor : '#67778E';
    const onFocusProp = props.onFocus ? props.onFocus : () => {};
    const onBlurProp = props.onBlur ? props.onBlur : () => {};
    const onChangeProp = props.onChange ? props.onChange : () => {};
    const onChangeTextProp = props.onChangeText ? props.onChangeText : () => {};
    const validateOnChangeProp = props.validateOnChange ? true : false;
    const validatedValueProp = props.validatedValue ? props.validatedValue : false;
    const maskProp = props.mask ? props.mask : false;
    const stateValueProp = 'string' === typeof props.stateValue ? props.stateValue : false; // more performant than directly altering value; independant of validation
    const disabledProp = props.disabled ? props.disabled : false;

    const allowPastDatesProp = false === props.allowPastDates ? false : true;
    const allowFutureDatesProp = false === props.allowFutureDates ? false : true;
    const allowTodaysDateProp = false === props.allowTodaysDate ? false : true;  

    let doingValidation = props.validateGroup ? props.validateGroup : false;
    
    const [inputTimeout, setInputTimeout] = React.useState(setTimeout(() => {},0));
    const [inputTimeoutDuration] = React.useState(props.validateThrottle || 200);
    const [getValue, setValue] = React.useState(props.value || '');

    React.useEffect(() =>   
    {    
        if ( 'undefined' !== typeof props.value )
        {
            // if props.value?.replace is a function
            if ( 'function' === typeof props.value?.replace )
                { setValue( props.value?.replace(/[^\S\r\n ]+/g, "") ); }
            // console.log({ placeholder: props.placeholder, value: props.value, setBy:'value' });
        }        
        else if ( false !== stateValueProp )
        {
            setValue( stateValueProp?.replace(/[^\S\r\n ]+/g, "") );
            // console.log({ placeholder: props.placeholder, value: stateValueProp, setBy:'stateValue' });
        }
        else if ( ! getValue && props.defaultValue )
        {
            setValue( props.defaultValue );
            // console.log({ placeholder: props.placeholder, value: props.defaultValue, setBy:'defaultValue' });
        }
    }, [stateValueProp, props.value, props.defaultValue]);

    const [isFocused, setIsFocused] = React.useState(false);
    const [isInvalid, setIsInvalid] = React.useState(false);
    
    const styles = StyleSheet.create({
        wrapper: {
            flexDirection: 'row',
            paddingVertical: 16,
            paddingHorizontal: 16,
            borderRadius: 4,
            backgroundColor: tw.color('white'),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: tw.color('gray4'),
            focus: { 
                borderColor: tw.color('blue'),
            },
            invalid: {
                borderColor: tw.color('red'),
            },
            disabled: {
                borderColor: tw.color('gray3'),
                pointerEvents: 'none',
                cursor: 'not-allowed',
                filter: 'grayscale(100%)',
            }
        },
        textInput: {
            flexGrow: 1,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            color: tw.color('ink'),
            fontFamily: 'Inter, sans-serif',
            fontSize: 16,
            outlineWidth: '0px',
            borderWidth: 0,
            backgroundColor: 'transparent',
            focus: { 
                outline: 'none',
            },
            invalid: {
                
            },
            disabled: {
                color: tw.color('gray6'),
            }
        },
        suffix: {
            color: '#67778E',
            fontSize: 16,
            marginLeft: 6
        },
        prefix: {
            flexShrink: 0,
            color: '#67778E',
            fontSize: 12,
            marginRight: 10
        }
    });

    const validateInput = React.useCallback(() =>
    {
        if ( ! validatedValueProp ) { return; }
        setInputTimeout( clearTimeout(inputTimeout) );
        setInputTimeout( setTimeout(() => {

            if ( ! getValue && ! props.required ) { setIsInvalid(false); validatedValueProp(''); return true; }
            if ( ! props.pattern && ! getValue && ! props.required ) { setIsInvalid(false); validatedValueProp(''); return true; }

            // console.log({type: 'validateInput', input:props.placeholder ,value:getValue});

            let result = false;

            if ( ! props.pattern )
            {
                if ( ! getValue && props.required ) { result = false; }
                else { result = true; }
            }
            else
            {            
                if ( typeof props.pattern === 'string' ) 
                {
                    const condition = new RegExp( props.pattern, 'g' );
                    result = condition.test( getValue );
                }
                else if ( typeof props.pattern === 'object' && props.pattern instanceof RegExp ) 
                {               
                    const condition = props.pattern;
                    result = condition.test( getValue );
                }
                else if ( typeof props.pattern === 'object' ) 
                {
                    for ( let pattern of props.pattern ) 
                    {                
                        const condition = new RegExp( pattern, 'g' );
                        result = condition.test( getValue );

                        if ( ! result ) { break; }
                    }
                }
            }

            if ( result && (
                false === allowTodaysDateProp
                || false === allowFutureDatesProp
                || false === allowPastDatesProp ) )
            {
                if ( false === allowTodaysDateProp )
                {
                    let now = new Date();
                    let now_MMDDYYY = ('0' + (now.getMonth() + 1)).slice(-2) + '/' + ('0' + now.getDate()).slice(-2) + '/' +  now.getFullYear();
                    if ( now_MMDDYYY === getValue ) { result = false; }
                }

                if ( false === allowFutureDatesProp )
                {
                    let now = new Date();
                    let now_MMDDYYY = ('0' + (now.getMonth() + 1)).slice(-2) + '/' + ('0' + now.getDate()).slice(-2) + '/' +  now.getFullYear();
                    now = Date.parse(now_MMDDYYY);
                    now = new Date(now);
                    
                    let valueDate = Date.parse(getValue);
                    valueDate = new Date(valueDate);

                    if ( now.getTime() < valueDate.getTime() ) { result = false; }
                }

                if ( false === allowPastDatesProp )
                {
                    let now = new Date();
                    let now_MMDDYYY = ('0' + (now.getMonth() + 1)).slice(-2) + '/' + ('0' + now.getDate()).slice(-2) + '/' +  now.getFullYear();
                    now = Date.parse(now_MMDDYYY);
                    now = new Date(now);
                    
                    let valueDate = Date.parse(getValue);
                    valueDate = new Date(valueDate);

                    if ( now.getTime() > valueDate.getTime() ) { result = false; }
                }
            }

            // console.log({type: 'validateInput', input:props.placeholder ,value:getValue, verificationDelay: doingValidation || ! validateOnChangeProp ? 0 : inputTimeoutDuration, isInvalid: ! result});

            setIsInvalid(! result);
            validatedValueProp(result ? getValue : '');

        }, /*doingValidation || ! validateOnChangeProp ? 200 :*/ inputTimeoutDuration));
        
    },[inputTimeout, inputTimeoutDuration, doingValidation, validateOnChangeProp, getValue, props, validatedValueProp]);

    React.useEffect(() =>
    {
        if ( ! validatedValueProp ) { return; }
        // if ( ! isFocused ) { return; }

        // if ( ! validateOnChangeProp && isFocused ) { return; }
        if ( ! getValue ) { validatedValueProp(getValue); return; }
        validateInput();

    }, [getValue, isFocused, doingValidation, validateOnChangeProp]);
    
    let { styleFocus, styleInvalid, validateGroup, validatedValue, passedRef, ...otherProps } = props;

    // console.log('rendering TextInput');
    
    return (
        <View style={[
            StyleSheet.compose(styles.wrapper, styleProp),
            isInvalid && StyleSheet.compose(styles.wrapper.invalid, styleInvalidProp),
            isFocused && StyleSheet.compose(styles.wrapper.focus, styleFocusProp),
            disabledProp && StyleSheet.compose(styles.wrapper.disabled, styleDisabledProp)
        ]}>
            { imageProp &&
                <Image
                    accessibilityHidden={true}
                    source={ imageProp }
                    resizeMode="contain"
                    style={[tw`max-h-full max-w-full h-6 w-6 mr-4`, styleImageProp]}
                />
            }
            
            { image2Prop &&
                <Image
                    accessibilityHidden={true}
                    source={ image2Prop }
                    resizeMode="contain"
                    style={[tw`max-h-full max-w-full h-6 w-6 ml-1 mr-4`, styleImage2Prop]}
                />
            }

            { prefixProp.length > 0 &&
                <Span style={StyleSheet.compose(styles.prefix, stylePrefixProp)}>{ prefixProp }</Span>
            }
            
            <NativeTextInput
                {...otherProps}
                style={[
                    StyleSheet.compose(styles.textInput, styleInputProp),
                    isInvalid && StyleSheet.compose(styles.textInput.invalid, styleInputInvalidProp),
                    isFocused && StyleSheet.compose(styles.textInput.focus, styleInputFocusProp),
                    disabledProp && StyleSheet.compose(styles.textInput.disabled, styleInputDisabledProp)
                ]}
                onFocus={(e) => {setIsFocused(true); onFocusProp(e)}}
                onBlur={(e) => {setIsFocused(false); onBlurProp(e)}}
                onChange={(e) => {e.target.value = e.target.value.replace(/[^\S\r\n ]+/g, ""); setValue( maskProp ? mask(e.target.value, maskProp) : e.target.value); onChangeProp(e)}}
                onChangeText={(text) => {text = maskProp ? mask(text.replace(/[^\S\r\n ]+/g, ""), maskProp) : text.replace(/[^\S\r\n ]+/g, ""); onChangeTextProp(text)}}
                autoComplete={autoCompleteProp}
                underlineColorAndroid={false}
                placeholderTextColor={placeholderTextColorProp}
                ref={passedRef}
            />

            { suffixProp.length > 0 &&
                <Span style={StyleSheet.compose(styles.suffix, styleSuffixProp)}>{ suffixProp }</Span>
            }
            
        </View>
    );
}
export const MemoizedTextInput = React.memo(TextInput);

export function SelectInput (props)
{    
    const styleProp = props.style ? props.style : {};
    const styleFocusProp = props.styleFocus ? props.styleFocus : {};
    const styleInvalidProp = props.styleInvalid ? props.styleInvalid : {};
    const styleOptionProp = props.styleOption ? props.styleOption : {};    
    const selectedValueProp = props.selectedValue ? props.selectedValue : '';
    // const placeholderTextColorProp = props.placeholderTextColor ? props.placeholderTextColor : tw.color('gray6');
    const onFocusProp = props.onFocus ? props.onFocus : () => {};
    const onBlurProp = props.onBlur ? props.onBlur : () => {};
    const onValueChangeProp = props.onValueChange ? props.onValueChange : () => {};
    const validatedValueProp = props.validatedValue ? props.validatedValue : () => {};
    
    let  pickerItems = [];

    let doingValidation = props.validateGroup ? props.validateGroup : false;

    const [getValue, setValue] = React.useState(props.value ? props.value : '');

    const [isFocused, setIsFocused] = React.useState(false);
    const [isInvalid, setIsInvalid] = React.useState(false);
    
    const styles = StyleSheet.create({
        textInput: {
            color: tw.color('ink'),
            fontFamily: 'Inter, sans-serif',
            fontSize: 16,
            paddingVertical: 16,
            paddingHorizontal: 12,
            borderRadius: 4,
            backgroundColor: tw.color('white'),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: tw.color('gray1'),
            outlineWidth: '0px',
            focus: { 
                borderColor: tw.color('blue'),
            },
            invalid: {
                borderColor: tw.color('red'),
            }
        },
    });

    const validateInput = React.useCallback(() =>
    {          
        let value = getValue;
        if ( ! value && ! props.required ) { return true; }

        let result = false;

        Object.entries(props.options).forEach(([optValue, optLabel], index) => {
            if ( value === optValue ) { result = true; return; }
        });

        setIsInvalid(! result);
        validatedValueProp(result ? value : '');
        
    },[getValue, props, validatedValueProp]);

    React.useEffect(() =>
    {
        if ( ! doingValidation ) { return; }
        validateInput();
        
    }, [getValue, doingValidation, validateInput]);

    if ( 'undefined' !== typeof props.options )
    {
        Object.entries(props.options).forEach(([value, label], index) => {
            pickerItems.push(<Picker.Item style={styleOptionProp} label={label} value={value} key={index} />)
        });
    }

    let { styleFocus, styleInvalid, options, validateGroup, validatedValue, ...otherProps } = props;
    
    return (
        <Picker
            {...otherProps}
            style={[
                StyleSheet.compose(styles.textInput, styleProp),
                isInvalid && StyleSheet.compose(styles.textInput.invalid, styleInvalidProp),
                isFocused && StyleSheet.compose(styles.textInput.focus, styleFocusProp),
            ]}
            onFocus={() => {setIsFocused(true); onFocusProp()}}
            onBlur={(e) => {setIsFocused(false); validateInput(); onBlurProp()}}
            selectedValue={selectedValueProp}
            onValueChange={(itemValue, itemIndex) => {setValue(itemValue); onValueChangeProp(itemValue, itemIndex);}}
        >
            {pickerItems}
        </Picker>
    );
}

export function SelectMenu (props)
{
    useDeviceContext(tw);

    const AnimatedView = animated(View);
    
    const styleProp = props.style ? props.style : {};
    const selectedValueProp = props.selectedValue ? props.selectedValue : '';
    const onValueChangeProp = props.onValueChange ? props.onValueChange : () => {};
    const scrollableProp = props.scrollable ? true : false;
    const searchableProp = props.searchable ? true : false;
    const requireSearchProp = props.requireSearch ? true : false;
    const quickLinkProp = props.quickLink ? props.quickLink : () => {};
    const quickLinkTitleProp = props.quickLinkTitle ? props.quickLinkTitle : '';
    
    let options = [];
    let renderItem;

    const [getValue, setValue] = React.useState(selectedValueProp);

    React.useEffect(()=> 
    {
        if ( selectedValueProp ) { setValue(selectedValueProp); }
    },[selectedValueProp]);
    
    const [filterText, setFilterText] = React.useState('');

    const filterRegex = new RegExp(String(filterText), 'i');
    const filter = (item) => {
        if ( 'string' === typeof item ) { return filterRegex.test(item); }
        else if ( 'object' === typeof item ) { return filterRegex.test(item.label); }
    }
    const filteredData = ! requireSearchProp || filterText.length > 2 ? props.options.filter(filter) : [];

    let timeOut = setTimeout(()=>{},250);

    let drawerAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 101,
            width: '100%',
            position: 'absolute',
            bottom: 0,
            left: 0,
            pointerEvents: 'none',
            transform: 'translateY(110%)',
        },
        to: {
            transform: props.active ? 'translateY(0%)' : 'translateY(110%)',
            pointerEvents: props.active ? 'auto' : 'none',
        },
        delay: props.active ? 125 : 0,
        config: props.active ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });
    let drawerShadeAnimation = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 100,
            opacity: 0,
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
            width: '100vw',
            height: '100vh',
            left: 0,
            top: 0,
            position: 'fixed',
        },
        to: {
            opacity: props.active ? 1 : 0,
            pointerEvents: props.active ? 'auto' : 'none',
        },
        delay: props.active ? 0 : 125,
        config: props.active ? { mass: 1, tension: 190, friction: 15 } : { mass: 1, tension: 190, friction: 30 }
    });

    if ( 'object' === typeof props.options )
    {
        Object.entries(props.options).forEach(([index, item]) => 
        {        
            if ( 'object' === typeof item )
            {
                let badge = '';
                if ( true === item.badge && item.color ) {
                    if ( ! item.textColor ) { item.textColor = 'white'; }
                    badge = (<Span style={tw`bg-${item.color} text-${item.textColor} uppercase rounded-full text-xs font-bold w-7.5 h-7.5 shrink-0 mr-3 text-center leading-7.75`}>{getInitials(item.label)}</Span>);
                }
                else if ( true === item.badge && ! item.color ) {
                    item.color = 'blueGray';
                    if ( ! item.textColor ) { item.textColor = 'blue'; }
                    badge = (<Span style={tw`bg-${item.color} text-${item.textColor} uppercase rounded-full text-xs font-bold w-7.5 h-7.5 shrink-0 mr-3 text-center leading-7.75`}>{getInitials(item.label)}</Span>);
                }
                else if ( 'object' === typeof item.badge )
                {
                    badge = ( 
                        <Image
                            accessibilityHidden={true}
                            source={ item.badge }
                            resizeMode="contain"
                            style={tw`h-7.5 w-7.5 mr-3`}
                        />
                    );
                }

                options.push(
                    <Pressable style={tw`flex-row justify-between py-1.5 items-center`} key={index}
                        onPress={() => { setValue(item.value || item.label); }}
                    >
                        <View style={[tw`flex-row items-center`, '' !== badge ? {width:'calc(100% - 86px)'} : {width:'calc(100% - 36px)'}]}>
                            <>{badge}</>
                            <View style={tw`w-full`}>
                                <View style={tw`flex-row justify-between`}>
                                    <Span numberOfLines={1}>{item.label}</Span>
                                    { undefined !== item.sublabel &&
                                        <Span numberOfLines={1}>{ truncate( item.sublabel, 10 ) }</Span>
                                    }
                                </View>
                                { undefined !== item.description &&
                                    <Span numberOfLines={1} style={tw`text-gray6 text-xs`}>{item.description}</Span>
                                }
                            </View>
                        </View>
                        
                        <Image
                            accessibilityLabel={ String(item.value) === String(getValue) || ( ! item.value && String(item.label) === String(getValue) ) ? "Selected" : false }
                            source={ String(item.value) === String(getValue) || ( ! item.value && String(item.label) === String(getValue) ) ? {uri: require('./svg/radioButton_active.svg')} : {uri: require('./svg/radioButton.svg')} }
                            resizeMode="contain"
                            style={tw`h-7.5 w-7.5`}
                        />
                    </Pressable>
                );
            }
            else
            {
                options.push(
                    <Pressable style={tw`flex-row justify-between py-1.5 items-center`} key={index}
                        onPress={() => { setValue(item); }}
                    >
                        <Span numberOfLines={1}>{item}</Span>
                        
                        <Image
                            accessibilityLabel={ item === getValue ? "Selected" : false }
                            source={ item === getValue ? {uri: require('./svg/radioButton_active.svg')} : {uri: require('./svg/radioButton.svg')} }
                            resizeMode="contain"
                            style={tw`h-7.5 w-7.5`}
                        />
                    </Pressable>
                );
            }
        });
    }

    renderItem = ({ item }) => {
        if ( 'object' === typeof item )
        {
            let badge = '';
            if ( true === item.badge && item.color ) {
                if ( ! item.textColor ) { item.textColor = 'white'; }
                badge = (<Span style={tw`bg-${item.color} text-${item.textColor} uppercase rounded-full text-xs font-bold w-7.5 h-7.5 shrink-0 mr-3 text-center leading-7.75`}>{getInitials(item.label)}</Span>);
            }
            else if ( true === item.badge && ! item.color ) {
                item.color = 'blueGray';
                if ( ! item.textColor ) { item.textColor = 'blue'; }
                badge = (<Span style={tw`bg-${item.color} text-${item.textColor} uppercase rounded-full text-xs font-bold w-7.5 h-7.5 shrink-0 mr-3 text-center leading-7.75`}>{getInitials(item.label)}</Span>);
            }
            else if ( 'object' === typeof item.badge )
            {
                badge = ( 
                    <Image
                        accessibilityHidden={true}
                        source={ item.badge }
                        resizeMode="contain"
                        style={tw`h-7.5 w-7.5 mr-3`}
                    />
                );
            }
        
            return (
                <Pressable style={tw`flex-row justify-between py-1.5 items-center`}
                    onPress={() => { setValue(item.value || item.label); }}
                >
                    <View style={[tw`flex-row items-center`, {width:'calc(100% - 86px)'}]}>
                        <>{badge}</>
                        <View style={tw`w-full`}>
                            <View style={tw`flex-row justify-between`}>
                                <Span numberOfLines={1}>{item.label}</Span>
                                { undefined !== item.sublabel &&
                                    <Span numberOfLines={1}>{item.sublabel}</Span>
                                }
                            </View>
                            { undefined !== item.description &&
                                <Span numberOfLines={1} style={tw`text-gray6 text-xs`}>{item.description}</Span>
                            }
                        </View>
                    </View>
                    
                    <Image
                        accessibilityLabel={ String(item.value) === String(getValue) || ( ! item.value && String(item.label) === String(getValue) ) ? "Selected" : false }
                        source={ String(item.value) === String(getValue) || ( ! item.value && String(item.label) === String(getValue) ) ? {uri: require('./svg/radioButton_active.svg')} : {uri: require('./svg/radioButton.svg')} }
                        resizeMode="contain"
                        style={tw`h-7.5 w-7.5`}
                    />
                </Pressable>
            )
        }        
        else 
        {
            return (
                <Pressable style={tw`flex-row justify-between py-1.5 items-center`}
                    onPress={() => { setValue(item); }}
                >
                    <Span numberOfLines={1}>{item}</Span>
                    
                    <Image
                        accessibilityLabel={ item === getValue ? "Selected" : false }
                        source={ item === getValue ? {uri: require('./svg/radioButton_active.svg')} : {uri: require('./svg/radioButton.svg')} }
                        resizeMode="contain"
                        style={tw`h-7.5 w-7.5`}
                    />
                </Pressable>
            )
        }
    };
    
    return (
        <AnimatedView style={drawerShadeAnimation}>
            <View style={tw`bg-blue/75 w-screen h-screen z-50 left-0 top-0 fixed`}>
                <Pressable onPress={()=>{props.setActive(false);setTimeout(()=>{setValue(selectedValueProp)},250)}} style={tw`absolute w-full h-full`}></Pressable>
            
                <AnimatedView style={drawerAnimation}>
                    <View style={[tw`rounded-t-3xl bg-gray0 w-screen z-50 bottom-0`, styleProp, scrollableProp ? tw`h-screen` : {}]}>
                        <View style={tw``}>                    
                            <View style={tw`flex-row items-center`}>                        
                                <Pressable style={tw`px-5 py-4 z-10`}
                                     onPress={()=>{props.setActive(false);setTimeout(()=>{setValue(selectedValueProp)},250)}}
                                >
                                    <Image
                                        accessibilityLabel="Cancel"
                                        source={{ uri: require('./svg/back.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-6 w-6`}
                                    />
                                </Pressable>
        
                                <Heading style={tw`m-0 px-5 pl-0 -ml-5 pr-10 z-0 text-center`} styleHlevel={4}>{props.title}</Heading>

                                { '' !== quickLinkTitleProp &&
                                    <Pressable
                                        onPress={()=>{quickLinkProp()}}
                                    >
                                            <Span style={tw`text-blue text-xs font-semibold absolute w-max p-4 right-2 -top-5.5`}>{quickLinkTitleProp}</Span>
                                    </Pressable>
                                }
                            </View>
                            <HorizontalRule />
                        </View>
                        
                        <View style={scrollableProp ? {height: 'calc(100% - 56px)'} : {}}>

                            { scrollableProp && searchableProp &&
                                <View>
                                    <View style={tw`lg:w-110 px-5 lg:mx-auto lg:pl-0 lg:pr-5`}>
                                        <MemoizedTextInput
                                            style={tw`my-3 py-2`}
                                            image={{ uri: require('./svg/search_gray.svg') }}
                                            autoCapitalize="on"
                                            placeholder="Search"
                                            onChangeText={(value)=>{ clearTimeout(timeOut); timeOut = setTimeout(() => { setFilterText(value) }, 250 ); }}
                                        />
                                    </View>
                                
                                    <HorizontalRule />
                                </View>
                            }

                            { scrollableProp &&
                                <FlatList 
                                    style={{width:'100%', padding:24, paddingTop: searchableProp ? 0 : 24, pointerEvents: props.active ? 'auto' : 'none'}}
                                    contentContainerStyle={tw`w-full justify-start lg:w-100 lg:mx-auto`}
                                    data={filteredData}
                                    renderItem={renderItem}
                                    getItemLayout={(data, index) => (
                                        {length: 42, offset: 42 * index, index}
                                    )}
                                    removeClippedSubviews={true}
                                    windowSize={3}
                                    ListEmptyComponent={()=>{
                                        return (
                                            <View style={tw`flex-col w-full py-6 pr-3`}>
                                                <Span style={tw`text-ink text-center font-semibold`}>{ requireSearchProp && filterText.length <= 2 ? getLabel('SEARCH_REQUIRED_TITLE') : getLabel('SEARCH_NOTFOUND_TITLE') }</Span>
                                                <Span style={tw`text-sm text-center text-gray7`}>{ requireSearchProp && filterText.length <= 2 ? getLabel('SEARCH_REQUIRED_BODY') : getLabel('SEARCH_NOTFOUND_DATA') }</Span>
                                            </View>
                                        );
                                    }}
                                />
                            }

                            { ! scrollableProp &&
                                <View style={[tw`p-6 pb-0 lg:w-100 lg:mx-auto`, {pointerEvents: props.active ? 'auto' : 'none'}]}>
                                    <>
                                    {
                                    options.length > 0 ?
                                        options
                                    :
                                        <View style={tw`flex-col w-full py-6 pr-3`}>
                                            <Span style={tw`text-ink text-center font-semibold`}>{ getLabel('SEARCH_NOTFOUND_TITLE') }</Span>
                                        </View>
                                    }
                                    </>
                                </View>
                            }

                            { scrollableProp && <HorizontalRule /> }
                            
                            { options.length > 0 || filteredData.length > 0 ?
                                <Button
                                    style={tw`m-6 lg:w-100 lg:mx-auto`}
                                    title={props.buttonTitle}
                                    onPress={() => {onValueChangeProp(getValue); props.setActive(false)}}
                                />
                                :
                                <Button
                                    style={tw`m-6 lg:w-100 lg:mx-auto`}
                                    title={getLabel('CANCEL')}
                                    onPress={() => {props.setActive(false)}}
                                />
                            }
                        </View>
                    </View>
                </AnimatedView>
            </View>
        </AnimatedView>
    );            
}
export const MemoizedSelectMenu = React.memo(SelectMenu);

export function PinInput (props)
{    
    useDeviceContext(tw);
    
    const styleProp = props.style ? props.style : {};
    const styleInputProp = props.styleInput ? props.styleInput : {};
    const styleFocusProp = props.styleFocus ? props.styleFocus : {};
    const styleInvalidProp = props.styleInvalid ? props.styleInvalid : {};
    const placeholderTextColorProp = props.placeholderTextColor ? props.placeholderTextColor : tw.color('gray6');
    const onFocusProp = props.onFocus ? props.onFocus : () => {};
    const onBlurProp = props.onBlur ? props.onBlur : () => {};
    const onChangeProp = props.onChange ? props.onChange : () => {};
    const validateOnChangeProp = props.validateOnChange ? true : false;
    const validatedValueProp = props.validatedValue ? props.validatedValue : () => {};

    let doingValidation = props.validateGroup ? props.validateGroup : false;

    const [getValue, setValue] = React.useState(props.value ? props.value : props.defaultValue || '');

    const [isFocused, setIsFocused] = React.useState(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);
    const [isInvalid, setIsInvalid] = React.useState(false);
    
    const styles = StyleSheet.create({
        textInput: {
            color: tw.color('ink'),
            fontFamily: 'Inter, sans-serif',
            fontSize: 24,
            textAlign: 'center',
            paddingVertical: tw.prefixMatch('lg') ? 16 : 19,
            paddingHorizontal: 16,
            maxWidth: 'calc(100% / 4 - 16px)',
            borderRadius: 4,
            backgroundColor: tw.color('white'),
            borderWidth: 1,
            borderStyle: 'solid',
            borderColor: tw.color('gray1'),
            outlineWidth: '0px',
            pointerEvents: 'none',
            focus: { 
                borderColor: tw.color('blue'),
            },
            invalid: {
                borderColor: tw.color('red'),
            }
        },
    });

    const validateInput = React.useCallback(() =>
    {                  
        if ( ! props.pattern ) { return true; }
        if ( ! getValue && ! props.required ) { return true; }

        let result = false;

        if ( typeof props.pattern === 'string' ) 
        {
            const condition = new RegExp( props.pattern, 'g' );
            result = condition.test( getValue );
        }

        if ( typeof props.pattern === 'object' ) 
        {
            for ( let pattern of props.pattern ) 
            {                
                const condition = new RegExp( pattern, 'g' );
                result = condition.test( getValue );

                if ( ! result ) { break; }
            }
        }

        setIsInvalid(! result);
        validatedValueProp(result ? getValue : '');
        
    },[getValue, props, validatedValueProp]);

    const input0 = React.useRef(null);
    const input1 = React.useRef(null);
    const input2 = React.useRef(null);
    const input3 = React.useRef(null);

    React.useEffect(() =>
    {
        let pin = getValue;
        if ( 1 === currentIndex && 4 === pin.length ) 
        {            
            input0.current.value = pin[0];
            input1.current.value = pin[1];
            input2.current.value = pin[2];
            input3.current.value = pin[3];
            input0.current.blur();
            setCurrentIndex(4);
            setValue(pin);
        }
        else if ( 1 === currentIndex && pin.length > 1 )
        {
            input0.current.value = pin[0];
            setValue(pin[0]);
            input1.current.focus(); input2.current.value = ''; input3.current.value = '';
        }
        else
        {
            switch( currentIndex )
            {
                case 0: setTimeout(() => {input0.current.focus();},300); input1.current.value = ''; input2.current.value = ''; input3.current.value = ''; break;
                case 1: input1.current.focus(); input2.current.value = ''; input3.current.value = ''; break;
                case 2: input2.current.focus(); input3.current.value = ''; break;
                case 3: input3.current.focus(); break;
                case 4: input3.current.blur(); break;
                default: break;
            }
        }
                
        if ( 4 === currentIndex && ( doingValidation || ( 4 === getValue.length && validateOnChangeProp ) ) )
        { 
            validateInput();
        }
        
    }, [getValue, currentIndex, doingValidation, validateOnChangeProp, validateInput]);
    
    return (
        <View style={[tw`flex-row justify-between`, styleProp]}>
            <NativeTextInput
                style={[
                    StyleSheet.compose(styles.textInput, styleInputProp),
                    isInvalid && StyleSheet.compose(styles.textInput.invalid, styleInvalidProp),
                    isFocused && 0 === currentIndex && StyleSheet.compose(styles.textInput.focus, styleFocusProp),
                    currentIndex >= 0 && {pointerEvents:'auto'}
                ]}
                onFocus={() => {setIsFocused(true); setCurrentIndex(0); onFocusProp()}}
                onBlur={(e) => {setIsFocused(false); onBlurProp()}}
                onChange={(e) => {setValue(e.target.value); setCurrentIndex(1); onChangeProp(e);}}
                keyboardType="numeric"
                autoComplete="one-time-code"
                autoCorrect="off"
                // maxLength="1" // must allow longer to support paste
                underlineColorAndroid={false}
                placeholderTextColor={placeholderTextColorProp}
                clearTextOnFocus={true}
                ref={input0}
            />
            <NativeTextInput
                style={[
                    StyleSheet.compose(styles.textInput, styleInputProp),
                    isInvalid && StyleSheet.compose(styles.textInput.invalid, styleInvalidProp),
                    isFocused && 1 === currentIndex && StyleSheet.compose(styles.textInput.focus, styleFocusProp),
                    currentIndex >= 1 && {pointerEvents:'auto'}
                ]}
                onFocus={(e) => {
                    if (currentIndex > 1) { setCurrentIndex(0); setValue(''); e.target.value = ''; } 
                    else { setIsFocused(true); setCurrentIndex(1); onFocusProp() } } 
                }
                onBlur={(e) => {setIsFocused(false); onBlurProp()}}
                onChange={(e) => {setValue(getValue + '' + e.target.value); setCurrentIndex(2); onChangeProp(e);}}
                keyboardType="numeric"
                autoComplete="one-time-code"
                autoCorrect="off"
                maxLength="1"
                underlineColorAndroid={false}
                placeholderTextColor={placeholderTextColorProp}
                clearTextOnFocus={true}
                ref={input1}
            />
            <NativeTextInput
                style={[
                    StyleSheet.compose(styles.textInput, styleInputProp),
                    isInvalid && StyleSheet.compose(styles.textInput.invalid, styleInvalidProp),
                    isFocused && 2 === currentIndex && StyleSheet.compose(styles.textInput.focus, styleFocusProp),
                    currentIndex >= 2 && {pointerEvents:'auto'}
                ]}
                onFocus={(e) => { 
                    if (currentIndex > 2) { setCurrentIndex(0); setValue(''); e.target.value = ''; } 
                    else { setIsFocused(true); setCurrentIndex(2); onFocusProp() } } 
                }
                onBlur={(e) => {setIsFocused(false); onBlurProp()}}
                onChange={(e) => {setValue(getValue + '' + e.target.value); setCurrentIndex(3); onChangeProp(e);}}
                keyboardType="numeric"
                autoComplete="one-time-code"
                autoCorrect="off"
                maxLength="1"
                underlineColorAndroid={false}
                placeholderTextColor={placeholderTextColorProp}
                clearTextOnFocus={true}
                ref={input2}
            />
            <NativeTextInput
                style={[
                    StyleSheet.compose(styles.textInput, styleInputProp),
                    isInvalid && StyleSheet.compose(styles.textInput.invalid, styleInvalidProp),
                    isFocused && 3 === currentIndex && StyleSheet.compose(styles.textInput.focus, styleFocusProp),
                    currentIndex >= 3 && {pointerEvents:'auto'}
                ]}
                onFocus={(e) => { 
                    if (currentIndex > 3) { setCurrentIndex(0); setValue(''); e.target.value = ''; } 
                    else { setIsFocused(true); setCurrentIndex(3); onFocusProp() } } 
                }
                onBlur={(e) => {setIsFocused(false); validateInput(); onBlurProp()}}
                onChange={(e) => {setValue(getValue + '' + e.target.value); setCurrentIndex(4); onChangeProp(e);}}
                keyboardType="numeric"
                autoComplete="one-time-code"
                autoCorrect="off"
                maxLength="1"
                underlineColorAndroid={false}
                placeholderTextColor={placeholderTextColorProp}
                clearTextOnFocus={true}
                ref={input3}
            />
        </View>
    );
}

export function SectionPositionBar (props)
{    
    const styleProp = props.style ? props.style : {};
    const styleHRProp = props.styleHR ? props.styleHR : {};
    const scrollProp = props.scroll ? props.scroll : 0;
    const menuProp = props.menu ? props.menu : [];
    const activeItemProp = props.activeItem ? props.activeItem : 0;

    const [activeItem, setActiveItem] = React.useState('');

    let menuItems = [];

    Object.entries(menuProp).forEach(([index, value]) => {
        menuItems.push(
            <View style={tw`flex-row items-start w-1/${menuProp.length}`} key={index}>
                <Pressable 
                    style={tw`grow items-center`}
                    onPress={() => {value.onPress()}}
                >
                    <Span style={[
                            tw`transition-all rounded-full text-center text-sm w-2.5 h-2.5 bg-white border border-gray5`, 
                        value.name === activeItemProp && tw`bg-blue border-blue`
                    ]}></Span>
                    <Span style={[tw`text-center text-sm w-full`, value.name === activeItemProp && tw`text-ink`]}>{value.name}</Span>
                </Pressable>
            </View>
        )
    });
    
    return (
        <View style={[tw`w-full relative flex-row flex-wrap justify-between transition-all`, scrollProp > 0 && tw`shadow-xl`, styleProp]}>
            <HorizontalRule style={[tw`bg-gray4 absolute top-1.25`, styleHRProp]} />
            <>{menuItems}</>
        </View>
    );
}

export function PillMenu (props)
{
    const styleProp = props.style ? props.style : {};
    const stylePillProp = props.stylePill ? props.stylePill : {};
    const selectedProp = props.selected ? props.selected : '';
    const menuProp = props.menu ? props.menu : [];
    const menuSpacerProp = undefined !== props.menuSpacer ? props.menuSpacer : 0.33;

    let menuItems = [];

    Object.entries(menuProp).forEach(([index, value]) => {
        if ( ! value.name ) { return; }
        let icon = '';
        let inactiveIcon = '';
        if ( value.icon && tw.prefixMatch('md') ) { icon = (<Image accessibilityHidden={true} source={ value.icon } resizeMode="contain" style={tw`top-0.5 h-4 w-4 mr-2`} />); }
        if ( value.inactiveIcon && tw.prefixMatch('md') ) { inactiveIcon = (<Image accessibilityHidden={true} source={ value.inactiveIcon } resizeMode="contain" style={tw`top-0.5 h-4 w-4 mr-2`} />); }
        menuItems.push(
            <Pressable style={tw`w-1/${menuProp.length + menuSpacerProp} lg:max-w-36`} onPress={() => {value.onPress()}} key={index} >
                <Span style={[
                        tw`transition-all text-blue rounded-2xl py-1.5 text-center text-sm w-full leading-6`,
                    value.name === selectedProp && tw`bg-blue text-white`, stylePillProp
                ]}>{ value.name === selectedProp ? icon : inactiveIcon }{value.name}</Span>
            </Pressable>
        )
    });
    
    return (
        <View style={[tw`w-full flex-row flex-wrap justify-start`, styleProp]}>
            {menuItems}
        </View>
    );
}

export function SpecsCard (props)
{
    const styleProp = props.style ? props.style : {};
    const labelProp = props.label ? props.label : '';
    const labelImageProp = props.labelImage ? props.labelImage : false;
    const valueProp = props.value ? props.value : '';
    const valueImageProp = props.valueImage ? props.valueImage : false;
    const unitProp = props.unit ? props.unit : '';
    const onPressProp = props.onPress ? props.onPress : false;
    
    return (
        onPressProp ?      
            <Pressable 
                style={[tw`w-full flex-row items-start bg-white shadow-lg shadow-gray7 rounded-lg px-4 py-3 my-1`, styleProp]}
                onPress={()=>{onPressProp()}}
            >
                { labelImageProp && 
                    <Image
                        accessibilityHidden={true}
                        source={ labelImageProp }
                        resizeMode="contain"
                        style={tw`h-5 w-5 mr-4`}
                    />
                }
                <Span style={tw`w-5/12 text-sm font-semibold`}>{labelProp}</Span>
                <View style={tw`w-7/12 shrink flex-row grow justify-between`}>
                    <View style={[tw`flex-row flex-wrap w-full justify-start`, unitProp.length < 1 && tw`w-11/12`, unitProp.length > 0 && tw`w-8/12`]}>
                        { valueImageProp && 
                            <Image
                                accessibilityHidden={true}
                                source={ valueImageProp }
                                resizeMode="contain"
                                style={tw`h-5 w-5 mr-2`}
                            />
                        }
                        <Span style={tw`text-gray6 text-sm`} numberOfLines={1}>{valueProp}</Span>
                    </View>
                    { unitProp.length > 0 && 
                        <Span style={tw`text-gray6 text-sm`}>{unitProp}</Span>
                    }
                    <Image
                        accessibilityHidden={true}
                        source={{ uri: require('./svg/arrow_forward_ios.svg') }}
                        resizeMode="contain"
                        style={tw`h-4 w-4 self-center`}
                    />
                </View>
            </Pressable>
        :
            <View style={[tw`w-full flex-row items-start bg-white shadow-lg shadow-gray7 rounded-lg px-4 py-3 my-1`, styleProp]}>
                { labelImageProp && 
                    <Image
                        accessibilityHidden={true}
                        source={ labelImageProp }
                        resizeMode="contain"
                        style={tw`h-5 w-5 mr-4`}
                    />
                }
                <Span style={tw`w-5/12 text-sm font-semibold`}>{labelProp}</Span>
                <View style={tw`w-7/12 shrink flex-row justify-between`}>
                    <View style={tw`flex-row flex-wrap shrink w-full justify-start`}>
                        { valueImageProp && 
                            <Image
                                accessibilityHidden={true}
                                source={ valueImageProp }
                                resizeMode="contain"
                                style={tw`h-5 w-5 mr-2`}
                            />
                        }
                        <Span style={tw`text-gray6 grow text-sm`} numberOfLines={1}>{valueProp}</Span>
                    </View>
                    { unitProp.length > 0 && 
                        <Span style={tw`text-gray6 text-sm mx-1`}>{unitProp}</Span>
                    }
                </View>
            </View>
    );
}

export function CheckList (props)
{
    const [getData, setData] = React.useState([]);
    const editableProp = props.editable ? true : false;
    const userIDProp = props.userID ? props.userID : 0;
    const onValueChangeProp = props.onValueChange ? props.onValueChange : (value)=>{};
    const styleProp = props.style ? props.style : {};
    const styleListProp = props.styleList ? props.styleList : {};
    const styleContentProp = props.styleContent ? props.styleContent : {};
    const onActivateRowProp = props.onActivateRow ? props.onActivateRow : ()=>{};
    const onDeActivateRowProp = props.onDeActivateRow ? props.onDeActivateRow : ()=>{};
    const onEditRowProp = props.onEditRow ? props.onEditRow : (index)=>{};
    const onDeleteRowProp = props.onDeleteRow ? props.onDeleteRow : (index)=>{};

    const checklistItem = React.useCallback(({key, index, data, active}) => {
        // if data.name property doesnt exist, return false
        if ( ! data ) { return false; }
        return <ChecklistStep
            key={key}
            index={index}
            label={data.name}
            labels={props.labels}
            description={data.details}
            completed={data.is_complete}
            excluded={data.is_excluded}
            dateStamp={data.complete_date || null}
            userID={data.user_id}
            createUserID={data.create_user_id}
            currentUserID={userIDProp}
            lastModifiedUserID={data.modified_user_id || userIDProp}
            lastModifiedDate={data.modified_date || ''}
            editable={editableProp}
            active={active}
            onValueChange={(value)=>{ let newData = getData; newData[index] = value; setData(newData); onValueChangeProp(newData);}}
            onEditButton={(index)=>{ onEditRowProp(index); }}
            onDeleteButton={(index)=>{ onDeleteRowProp(index); }}
        />;
    }, [editableProp, getData, setData]);

    React.useEffect(()=>{
        let propData = props?.data || [];
        if ( ! propData.length && editableProp )
        {
            propData.push({
                name: '',
                is_complete: false,
                is_excluded: false,
                details: '',
                user_id: userIDProp,
                create_user_id: userIDProp,
                modified_user_id: 0,
                create_date: now_datetime(),
                complete_date: null,
                modified_date: null,
            });
        }
        setData(propData);
    }, [props]);

    return (
        <>
            <View style={[tw`mt-0 border-0 w-full overflow-visible`, editableProp && tw`mt-5`, styleProp]}>
                <SortableList
                    style={[tw`z-20 overflow-visible`, editableProp && tw`h-[${(getData.length * 65 + 4 )}px]`, ! editableProp && tw`min-h-max`, styleListProp]}
                    contentContainerStyle={[editableProp && tw`overflow-visible`, styleContentProp]}
                    innerContainerStyle={[tw`flex-none`, editableProp && tw`overflow-visible h-[${(getData.length * 65 + 4 )}px]`, ! editableProp && tw`overflow-hidden h-auto min-h-max`,]}
                    data={getData}
                    sortingEnabled={editableProp}
                    renderRow={checklistItem}
                    onReleaseRow={(rowKey, order)=>{let newData = order.map(i => getData[i]); setData(newData); onValueChangeProp(newData); onDeActivateRowProp();}}
                    onActivateRow={()=>{onActivateRowProp();}}
                />

                { 0 < getData.length && editableProp &&
                    <View style={tw`flex-row justify-end`}>
                        <Pressable
                            style={tw`h-12 w-12 mt-2 mb-2`}
                            onPress={()=>{
                                let newData = [...getData, {
                                    name: '',
                                    is_complete: false,
                                    is_excluded: false,
                                    details: '',
                                    user_id: userIDProp,
                                    create_user_id: userIDProp,
                                    modified_user_id: 0,
                                    create_date: now_datetime(),
                                    complete_date: null,
                                    modified_date: null,
                                }];
                                setData(newData);
                                onValueChangeProp(newData);
                                // onEditRowProp(null);
                            }}
                        >
                            <Image
                                accessibilityLabel={ props.labels.ADD +' '+ props.labels.STEP }
                                source={{ uri: require('./svg/big_add.svg') }}
                                resizeMode="contain"
                                style={tw`h-12 w-12`}
                            />
                        </Pressable>
                    </View>
                }
            </View>
        </>
    );
}
export const MemoizedChecklist = React.memo(CheckList);

export function ChecklistStep (props)
{
    const indexProp = props.index ? props.index : 0;
    const labelProp = props.label ? props.label : '';
    const descriptionProp = props.description ? props.description : '';
    const userIDProp = props.userID ? parseInt(props.userID) : 0;
    const currentUserIDProp = props.currentUserID ? props.currentUserID : 0;
    const createUserIDProp = props.createUserID ? props.createUserID : currentUserIDProp;
    const lastModifiedUserIDProp = props.lastModifiedUserID ? props.lastModifiedUserID : currentUserIDProp;
    const dateStampProp = props.dateStamp ? props.dateStamp : date_DMJYYYY();
    const lastModifiedDateProp = props.lastModifiedDate ? date_DMJYYYY_TIME(props.lastModifiedDate, true) : date_DMJYYYY_TIME();
    const completedProp = props.completed ? true : false;
    const excludedProp = props.excluded ? true : false;
    const editableProp = props.editable ? true : false;
    const onValueChangeProp = props.onValueChange ? props.onValueChange : (value)=>{};
    const onEditButtonProp = props.onEditButton ? props.onEditButton : (index)=>{};
    const onDeleteButtonProp = props.onDeleteButton ? props.onDeleteButton : (index)=>{};
    const activeProp = props.active ? true : false;

    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : false;

    const [completed, setCompleted] = React.useState(completedProp);
    const [excluded, setExcluded] = React.useState(excludedProp);
    const [dateStamp, setDateStamp] = React.useState(lastModifiedDateProp);
    const [lastUserID, setLastUserID] = React.useState(lastModifiedUserIDProp);
    const [labels, setLabels] = React.useState(false);

    React.useEffect(()=>
    {
        if ( ! labels )
        {
            setLabels(props.labels);
        }
    },[]);

    return (
        <View style={[editableProp && tw`m-1 shadow-md bg-white transition-transform`, editableProp && activeProp && tw`shadow-xl shadow-offset-[6px]/[10px]`]}>
            { ! editableProp ?
                <View>
                    <View style={tw`flex-col items-start m-0 p-1 pb-6 pt-6 border-b border-gray2`}>
                        <View style={tw`flex-col flex-1`}>
                            <View style={tw`flex-col justify-between`}>
                                <View style={tw`flex-col flex-initial`}>
                                    <Span style={tw`-mt-[3px] grow font-semibold text-ink`} numberOfLines={1}>{ labelProp || labels.STEP +' '+ labels.NAME }</Span>
                                    <Span style={tw`text-xs text-gray6 pb-2`}>
                                        { labels.ASSIGNED_TO+' '+(getUserDataByID(userIDProp)?.name)+(userIDProp === user.id ? ' ('+labels.ME+')' : '') }
                                    </Span>
                                    <Span style={tw`text-sm text-gray6`}>{ descriptionProp }</Span>
                                </View>
                            </View>
                        </View>
                        <View style={tw`flex-row`}>
                            <Pressable
                                style={tw`flex-row pt-3 mr-6`}
                                onPress={()=>{
                                    let newCompleted = ! completed;
                                    setDateStamp(date_DMJYYYY_TIME());
                                    setLastUserID(currentUserIDProp);
                                    setCompleted(newCompleted);
                                    setExcluded(false);
                                    onValueChangeProp({
                                        name: labelProp,
                                        is_complete: newCompleted,
                                        is_excluded: false,
                                        details: descriptionProp,
                                        user_id: userIDProp,
                                        create_user_id: createUserIDProp,
                                        modified_user_id: currentUserIDProp,
                                        create_date: dateStampProp,
                                        complete_date: newCompleted ? now_datetime() : null,
                                        modified_date: now_datetime(),
                                    });
                                }}
                            >
                                <View style={[tw`flex-row border-2 border-gray6 rounded-md`, completed && tw`bg-green border-green`]}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={ completed ? { uri: require('./svg/secure_lightgray.svg') } : { uri: require('./svg/secure_mediumgray.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-6.5 w-5 mx-1`}
                                    />
                                </View>
                                <Span style={[tw`uppercase text-sm font-bold text-gray6 leading-7 relative top-px ml-3`]}>{ labels.CHECK }</Span>
                            </Pressable>
                            <Pressable
                                style={tw`flex-row pt-3`}
                                onPress={()=>{
                                    let newExcluded = ! excluded;
                                    setDateStamp(date_DMJYYYY_TIME());
                                    setLastUserID(currentUserIDProp);
                                    setCompleted(false);
                                    setExcluded(newExcluded);
                                    onValueChangeProp({
                                        name: labelProp,
                                        is_complete: false,
                                        is_excluded: newExcluded,
                                        details: descriptionProp,
                                        user_id: userIDProp,
                                        create_user_id: createUserIDProp,
                                        modified_user_id: currentUserIDProp,
                                        create_date: dateStampProp,
                                        complete_date: null,
                                        modified_date: now_datetime(),
                                    });
                                }}
                            >
                                <View style={[tw`flex-row border-2 border-gray6 rounded-md`, excluded && tw`bg-orange border-orange`]}>
                                    <Image
                                        accessibilityHidden={true}
                                        source={ excluded ? { uri: require('./svg/xmark_lightgray.svg') } : { uri: require('./svg/xmark_mediumgray.svg') }}
                                        resizeMode="contain"
                                        style={tw`h-6.5 w-5 mx-1`}
                                    />
                                </View>
                                <Span style={[tw`uppercase text-sm font-bold text-gray6 leading-7 relative top-px ml-3`]}>{ labels.EXCLUDE }</Span>
                            </Pressable>
                        </View>
                        <View style={tw`flex-col flex-initial`}>
                            <Span style={[tw`text-xs text-gray6`, (completed || excluded) && tw`pt-3`]}>
                                { completed ? labels.COMPLETED+' '+labels.ON+' '+dateStamp+(! tw.prefixMatch('md') ? "\r\n" : ' ')+labels.BY+' '+(getUserDataByID(lastUserID)?.name)+(lastUserID === user.id ? ' ('+labels.ME+')' : '') : '' }
                                { excluded ? labels.EXCLUDED+' '+labels.ON+' '+dateStamp+(! tw.prefixMatch('md') ? "\r\n" : ' ')+labels.BY+' '+(getUserDataByID(lastUserID)?.name)+(lastUserID === user.id ? ' ('+labels.ME+')' : '') : '' }
                            </Span>
                        </View>
                    </View>
                    {/* add exlude pressable */}
                </View>
            :
                <View style={tw`flex-row items-stretch justify-between m-0 pl-2 overflow-x-hidden`}>
                    <View style={tw`flex-row flex-1 py-2`}>
                        <Image
                            accessibilityHidden={true}
                            source={{ uri: require('./svg/reorder.svg') }}
                            resizeMode="contain"
                            style={tw`h-10 w-5 ml-1 mr-3 opacity-40 pointer-events-none`}
                        />
                        <View style={tw`flex-col flex-1 pr-3`}>
                            <Span style={tw`-mt-[2px]`} numberOfLines={1}>{ labelProp || labels.STEP +' '+ labels.NAME }</Span>
                            <View style={tw`-mt-[1px] flex-row justify-between`}>
                                { /* <Span style={tw`text-xs text-gray6 h-5 leading-5 shrink-1`} numberOfLines={1}>{ descriptionProp }</Span> */ }
                                <Span style={tw`text-sm text-gray6 h-5 leading-5 shrink-0`} numberOfLines={1}>{ labels.ASSIGNED_TO +' '+ ( getUserDataByID(userIDProp)?.name || getUserDataByID(currentUserIDProp)?.name ) }</Span>
                            </View>
                        </View>
                    </View>
                    <Pressable
                        style={tw`border-l-2 border-gray2 pl-4 pr-2 justify-center`}
                        onPress={()=>{ onDeleteButtonProp(indexProp); }}
                    >
                        <Image
                            accessibilityHidden={true}
                            source={{ uri: require('./svg/delete_gray.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 w-5 ml-1 mr-3 opacity-40`}
                        />
                    </Pressable>
                    <Pressable
                        style={tw`border-l-2 border-gray2 pl-4 pr-2 justify-center`}
                        onPress={()=>{ onEditButtonProp(indexProp); }}
                    >
                        <Image
                            accessibilityHidden={true}
                            source={{ uri: require('./svg/edit.svg') }}
                            resizeMode="contain"
                            style={tw`h-5 w-5 ml-1 mr-3 opacity-40`}
                        />
                    </Pressable>
                </View>
            }
        </View>
    );
}
export const MemoizedChecklistStep = React.memo(ChecklistStep);

/* inline-editable checklist line item; currently deprecated in favor of ChecklistStep*/
export function ChecklistItem (props)
{
    const labelProp = props.label ? props.label : '';
    const descriptionProp = props.description ? props.description : '';
    const userIDProp = props.userID ? props.userID : 0;
    const dateStampProp = props.dateStamp ? props.dateStamp : date_DMJYYYY();
    const completedProp = props.completed ? true : false;
    const editableProp = props.editable ? true : false;
    const onValueChangeProp = props.onValueChange ? props.onValueChange : (value)=>{};
    const activeProp = props.active ? true : false;

    const [completed, setCompleted] = React.useState(completedProp);
    const [userID, setUserID] = React.useState(userIDProp);

    const labelInput = React.useRef(null);
    const descriptionInput = React.useRef(null);
    const dateStampInput = React.useRef(null);

    return (
        <View style={[tw`m-1`, editableProp && tw`shadow-md bg-white transition-transform`, editableProp && activeProp && tw`shadow-xl`]}>
            { ! editableProp ?
                <Pressable
                    style={tw`flex-row items-start m-0 p-1`}
                    onPress={()=>{ let newCompleted = ! completed; setCompleted(newCompleted); onValueChangeProp({label: labelProp, is_completed: newCompleted, description: descriptionProp, user_id: userIDProp, date: dateStampProp});}}
                >
                    <Image
                        accessibilityHidden={true}
                        source={ completed ? { uri: require('./svg/checkbox_active.svg') } : { uri: require('./svg/checkbox.svg') }}
                        resizeMode="contain"
                        style={tw`h-5 w-5 ml-1 mr-3`}
                    />
                    <Span style={tw`-mt-[3px]`}>{ labelProp }</Span>
                </Pressable>
            :
                <View style={tw`flex-row items-start m-0 p-2 overflow-x-hidden`}>
                    <Image
                        accessibilityHidden={true}
                        source={{ uri: require('./svg/dragdots.svg') }}
                        resizeMode="contain"
                        style={tw`h-5 w-5 ml-1 mr-3 opacity-40`}
                    />
                    <TextInput
                        passedRef={labelInput}
                        multiline={true}
                        dynamicHeight={true}
                        style={tw`border-0 bg-transparent w-full p-0 pr-4`}
                        autoCapitalize="on"
                        defaultValue={labelProp}
                        onChangeText={(newLabel)=>{onValueChangeProp({label: newLabel, is_completed: completed, description: descriptionProp, user_id: userIDProp, date: dateStampProp});}}
                        pattern="^.{2,}$"
                        placeholder={props.labels.STEP +' '+ props.labels.NAME}
                    />
                </View>
            }
        </View>
    );
}
export const MemoizedChecklistItem = React.memo(ChecklistItem);

export function LargeModal (props)
{
    const AnimatedView = animated(View);

    const styleProp = props.style ? props.style : {};
    const titleProp = props.title ? props.title : '';
    const alertProp = props.alert ? props.alert : '';
    const alertTypeProp = props.alertType ? props.alertType : 'warning';

    let addNewPaneAnimation = useSpring(
    {
        from: {
            willChange: 'transform',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: tw.color('gray0'),
            boxShadow: 'rgb(0 0 0 / 50%) 0px 0px 24px',
            transform: 'translateX(110%)',
        },
        to: {
            transform: props.active ? 'translateX(0%)' : 'translateX(110%)',
        },
        config: props.active ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    let addNewPaneAnimationDesktop = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 91,
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
        },
        to: {
            opacity: props.active ? 1 : 0,
            pointerEvents: props.active ? 'auto' : 'none',
        },
        config: props.active ? { mass: 1, tension: 190, friction: 30 } : { mass: 1, tension: 190, friction: 15 }
    });

    addNewPaneAnimation = tw.prefixMatch('lg') ? addNewPaneAnimationDesktop : addNewPaneAnimation;

    return (
        <AnimatedView style={addNewPaneAnimation}>

            <Pressable style={tw`hidden lg:flex bg-blue/75 w-screen h-screen left-0 top-0 fixed`}
                onPress={() => {props.setActive(false);}}
            ></Pressable>

            <View style={tw`lg:w-188 lg:rounded-md lg:shadow-black lg:shadow-opacity-25 lg:shadow-radius-8 max-h-screen`}>

                <View style={tw`bg-blue lg:bg-gray0 lg:border-b-2 lg:border-gray1 lg:items-center h-20 p-5 lg:px-9 pt-6.5 flex-row lg:rounded-t-md`}>
                    <Pressable
                        style={tw`p-2 -ml-2 -mt-2`}
                        onPress={() => {props.setActive(false);}}
                    >
                        <Image
                            accessibilityLabel={ getLabel('CANCEL') }
                            source={{ uri: tw.prefixMatch('lg') ? require('./svg/back.svg') : require('./svg/back_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-6 w-6 top-px lg:top-1 lg:mr-4`}
                        />
                    </Pressable>
                    <Heading style={tw`mt-1.5 lg:mt-0 leading-4 mb-0 text-white lg:text-ink`} hlevel={1} styleHlevel={tw.prefixMatch('lg') ? 2 : 4}>{ titleProp }</Heading>
                </View>

                <View style={tw`bg-blue h-8 z-0 -mb-3 lg:hidden`}>
                    <View style={tw`bg-gray0 h-full w-full rounded-t-3xl`}></View>
                </View>

                <View style={[tw`bg-gray0 grow lg:px-4 lg:pb-5 lg:pt-8 z-20 lg:rounded-b-md w-full`, { height: tw.prefixMatch('lg') ? 'max-content' : 'calc(100% - 100px)', maxHeight: tw.prefixMatch('lg') ? 'calc(100vh - 96px)' : 'auto'}]}>
                    <View style={tw`h-full lg:h-max`}>
                        <ScrollView
                            style={{width:'100%',maxHeight: tw.prefixMatch('lg') ? 'calc(100vh - 248px)' : 'calc(100vh - 92px)',overflowX:tw.prefixMatch('lg') ? 'visible' : 'hidden'}}
                            contentContainerStyle={tw`w-full justify-start px-0`}
                        >
                            { '' !== alertProp &&
                                <View style={tw`w-full lg:pr-2 mx-5`}>
                                    <Notification
                                        style={tw`my-2`}
                                        type={alertTypeProp}
                                    >{alertProp}</Notification>
                                </View>
                            }

                            <View style={tw`lg:flex-row lg:flex-wrap lg:justify-between`}>
                                <View style={tw`w-full px-5`}>

                                    {props.children}

                                </View>
                            </View>
                        </ScrollView>

                        {props.button}

                    </View>
                </View>
            </View>
        </AnimatedView>
    );
}

export function Modal (props)
{
    const AnimatedView = animated(View);
    
    const styleProp = props.style ? props.style : {};
    const doWhileExitProp = props.doWhileExit ? props.doWhileExit : ()=>{};

    let modalAnimation = useSpring(
    {
        from: {
            willChange: 'transform, opacity',
            zIndex: 101,
            position: 'absolute',
            width: '100vw',
            height: '100vh',
            top: 0,
            left: 0,
            pointerEvents: 'none',
            transform: 'scale(80%)',
            opacity: 0,
            justifyContent: 'center',
            alignItems: 'center',
        },
        to: {
            transform: props.active ? 'scale(100%)' : 'scale(80%)',
            opacity: props.active ? 1 : 0,
            pointerEvents: props.active ? 'auto' : 'none',
        },
        delay: props.active ? 125 : 0,
        config: props.active ? { mass: 1, tension: 250, friction: 30 } : { mass: 1, tension: 250, friction: 15 }
    });
    let shadeAnimation = useSpring(
    {
        from: {
            willChange: 'opacity',
            zIndex: 100,
            opacity: 0,
            pointerEvents: 'none',
            backdropFilter: 'blur(3px)',
            width: '100vw',
            height: '100vh',
            left: 0,
            top: 0,
            position: 'fixed',
        },
        to: {
            opacity: props.active ?     1 : 0,
            pointerEvents: props.active ? 'auto' : 'none',
        },
        delay: props.active ? 0 : 125,
        config: props.active ? { mass: 1, tension: 190, friction: 15 } : { mass: 1, tension: 190, friction: 30 }
    });
    
    return (
        <AnimatedView style={shadeAnimation}>
            <View style={tw`bg-blue/75 w-screen h-screen left-0 top-0 fixed`}>
                <AnimatedView style={modalAnimation}>
                    <Pressable onPress={()=>{props.setActive(false); doWhileExitProp();}} style={tw`absolute w-full h-full`}></Pressable>
                    <View style={[tw`w-82 bg-white rounded-xl p-5 shadow-black shadow-opacity-25 shadow-radius-8`, styleProp]}>
                        <Pressable 
                            style={tw`absolute top-5 right-5 z-90`}
                            onPress={()=>{props.setActive(false); doWhileExitProp();}}
                        >
                            <Image
                                accessibilityLabel="Cancel"
                                source={{ uri: require('./svg/close.svg') }}
                                resizeMode="contain"
                                style={tw`h-5 w-5`}
                            />
                        </Pressable>
                        {props.children}
                    </View>
                </AnimatedView>
            </View>
        </AnimatedView>
    );
}

export function ImagesUploadInput (props)
{
    return (
        <input ref={props.passedRef} hidden={true} id="image-picker" type="file" accept="image/png, image/jpeg" multiple={true} onChange={props.onChange} />
    );
}

export function AttachmentUploadInput (props)
{
    return (
        <input ref={props.passedRef} hidden={true} id="file-picker" type="file" multiple={false} onChange={props.onChange} />
    );
}


export function AddNewAssetButtons (props)
{
    const AnimatedView = animated(View);
    const [refreshToolTip, setRefreshToolTip] = React.useState(false);
    const [addToolTip, setAddToolTip] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    let hideAddNewProp = props.hideAddNew ? props.hideAddNew : false;
    let hideRefreshProp = props.hideRefresh ? props.hideRefresh : false;
    let hideTooltipsProp = props.hideTooltips ? props.hideTooltips : false;
    let styleProp = props.style ? props.style : {};
    let onAddNewProp = props.onAddNew ? props.onAddNew : ()=>{};
    let addNewLabelProp = props.addNewLabel ? props.addNewLabel : getLabel('ADD');
    let refreshLabelProp = props.refreshLabel ? props.refreshLabel : getLabel('REFRESH_PAGE');
    let isLoadingProp = props.isLoading ? props.isLoading : false;

    // if isLoadingProp is true, set isLoading to true
    React.useEffect(()=>{
        setIsLoading(isLoadingProp);
    }
    , [isLoadingProp]);

    let refreshToolTipAnimation = useSpring(
    {
        from: {
            willChange: 'transform, opacity',
            zIndex: 101,
            transformOrigin: 'right bottom',
            transform: 'scale(80%)',
            opacity: 0,
            pointerEvents: 'none',
            height: 0,
            position: 'absolute',
            minWidth: 'max-content',
        },
        to: {
            transform: refreshToolTip ? 'scale(100%)' : 'scale(80%)',
            opacity: refreshToolTip ? 1 : 0,
        },
        delay: refreshToolTip ? 125 : 0,
        config: { mass: 1, tension: 250, friction: 30 }
    });

    let addToolTipAnimation = useSpring(
    {
        from: {
            willChange: 'transform, opacity',
            zIndex: 101,
            transformOrigin: 'right bottom',
            transform: 'scale(80%)',
            opacity: 0,
            pointerEvents: 'none',
            height: 0,
            position: 'absolute',
            minWidth: 'max-content',
        },
        to: {
            transform: addToolTip ? 'scale(100%)' : 'scale(80%)',
            opacity: addToolTip ? 1 : 0,
        },
        delay: addToolTip ? 125 : 0,
        config: { mass: 1, tension: 250, friction: 30 }
    });

    return (
        <View style={[tw`flex-col fixed bottom-10 right-5 md:right-14 md:bottom-14 items-end`, styleProp]}>
            { ! hideTooltipsProp &&
                <AnimatedView style={refreshToolTipAnimation}><Span style={tw`text-white font-semibold uppercase text-xs tracking-widest px-4 py-2 bg-ink/70 rounded-md inline-block relative top-2 mr-14`}>{refreshLabelProp}</Span></AnimatedView>
            }

            { ! hideRefreshProp &&
                <Pressable
                    onHoverIn={()=>{setRefreshToolTip(true);}}
                    onHoverOut={()=>{setRefreshToolTip(false);}}
                    onPress={()=>{window.location.reload();}}
                >
                    <Image
                        accessibilityLabel={refreshLabelProp}
                        source={{ uri: require('./svg/big_refresh.svg') }}
                        resizeMode="contain"
                        style={tw`h-12 w-12`}
                    />
                </Pressable>
            }

            { ! hideAddNewProp &&

                <View style={tw`mt-3 flex-col items-end`}>
                    { ! hideTooltipsProp &&
                        <AnimatedView style={addToolTipAnimation}><Span style={tw`text-white font-semibold uppercase text-xs tracking-widest px-4 py-2 bg-ink/70 rounded-md inline-block relative top-2 mr-14`}>{addNewLabelProp}</Span></AnimatedView>
                    }
                    <Pressable
                        style={isLoading ? tw`pointer-events-none` : {}}
                        onHoverIn={()=>{setAddToolTip(true);}}
                        onHoverOut={()=>{setAddToolTip(false);}}
                        onPress={()=>{onAddNewProp();}}
                    >
                        { isLoading ?
                        <View style={tw`h-12 w-12 rounded-full bg-brand_primary justify-center items-center`}>
                            <Image
                                accessibilityLabel="Loading..."
                                source={{ uri: require('./svg/loader.svg') }}
                                resizeMode="contain"
                                style={tw`h-7 w-7`}
                            />
                        </View>

                        :

                        <Image
                            accessibilityLabel={addNewLabelProp}
                            source={{ uri: require('./svg/big_add.svg') }}
                            resizeMode="contain"
                            style={tw`h-12 w-12`}
                        />
                        }
                    </Pressable>
                </View>
            }
        </View>
    )
}

export function AddQuickImportExportButtons (props)
{
    const AnimatedView = animated(View);
    const [importToolTip, setImportToolTip] = React.useState(false);
    const [exportToolTip, setAddToolTip] = React.useState(false);
    const yachtID = props.yachtID ? props.yachtID : 0;
    const navigation = props.navigation;

    let hideExportProp = props.hideExport ? props.hideExport : false;
    let hideImportProp = props.hideImport ? props.hideImport : false;
    let hideTooltipsProp = props.hideTooltips ? props.hideTooltips : false;
    let styleProp = props.style ? props.style : {};
    let onExportProp = props.onExport ? props.onExport : ()=>{};
    let exportLabelProp = props.exportLabel ? props.exportLabel : getLabel('EXPORT');
    let importLabelProp = props.importLabel ? props.importLabel : getLabel('IMPORT');

    let importToolTipAnimation = useSpring(
    {
        from: {
            willChange: 'transform, opacity',
            zIndex: 101,
            transformOrigin: 'right bottom',
            transform: 'scale(80%)',
            opacity: 0,
            pointerEvents: 'none',
            height: 0,
            position: 'absolute',
            minWidth: 'max-content',
        },
        to: {
            transform: importToolTip ? 'scale(100%)' : 'scale(80%)',
            opacity: importToolTip ? 1 : 0,
        },
        delay: importToolTip ? 125 : 0,
        config: { mass: 1, tension: 250, friction: 30 }
    });

    let exportToolTipAnimation = useSpring(
    {
        from: {
            willChange: 'transform, opacity',
            zIndex: 101,
            transformOrigin: 'right bottom',
            transform: 'scale(80%)',
            opacity: 0,
            pointerEvents: 'none',
            height: 0,
            position: 'absolute',
            minWidth: 'max-content',
        },
        to: {
            transform: exportToolTip ? 'scale(100%)' : 'scale(80%)',
            opacity: exportToolTip ? 1 : 0,
        },
        delay: exportToolTip ? 125 : 0,
        config: { mass: 1, tension: 250, friction: 30 }
    });

    return (
        <View style={[tw`flex-col fixed bottom-10 right-5 md:right-14 md:bottom-14 items-end`, styleProp]}>
            { ! hideTooltipsProp &&
                <AnimatedView style={importToolTipAnimation}><Span style={tw`text-white font-semibold uppercase text-xs tracking-widest px-4 py-2 bg-ink/70 rounded-md inline-block relative top-2 mr-14`}>{importLabelProp}</Span></AnimatedView>
            }

            { ! hideImportProp &&
                <Pressable
                    style={tw`bg-blue rounded-full w-12 h-12 p-3`}
                    onHoverIn={()=>{setImportToolTip(true);}}
                    onHoverOut={()=>{setImportToolTip(false);}}
                    onPress={()=>{navigation.navigate('account', {import: true, id: yachtID});}}
                >
                    <Image
                        accessibilityLabel={importLabelProp}
                        source={{ uri: require('./svg/import_white.svg') }}
                        resizeMode="contain"
                        style={tw`h-full w-full -left-.5`}
                    />
                </Pressable>
            }

            { ! hideExportProp &&

                <View style={tw`mt-3 flex-col items-end`}>
                    { ! hideTooltipsProp &&
                        <AnimatedView style={exportToolTipAnimation}><Span style={tw`text-white font-semibold uppercase text-xs tracking-widest px-4 py-2 bg-ink/70 rounded-md inline-block relative top-2 mr-14`}>{exportLabelProp}</Span></AnimatedView>
                    }
                    <Pressable
                        style={tw`bg-blue rounded-full w-12 h-12 p-2.75`}
                        onHoverIn={()=>{setAddToolTip(true);}}
                        onHoverOut={()=>{setAddToolTip(false);}}
                        onPress={()=>{navigation.navigate('account', {export: true, id: yachtID});}}
                    >
                        <Image
                            accessibilityLabel={exportLabelProp}
                            source={{ uri: require('./svg/export_white.svg') }}
                            resizeMode="contain"
                            style={tw`h-full w-full left-.5`}
                        />
                    </Pressable>
                </View>
            }
        </View>
    )
}
